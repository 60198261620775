
import { defineAsyncComponent, defineComponent, PropType } from 'vue';

import { ContentBase } from '@/models/content_base';
import { Style } from '@/models/style';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentFooter',
  components: {
    BaseStyle,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Content: defineAsyncComponent((): any => import('./Content.vue')),
  },
  props: {

    contentStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

    links: {
      type: Array as PropType<ContentBase[]>,
      default: () => [],
    },

    textStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

    /**
     * フッターに表示するテキスト。
     */
    text: {
      type: String,
      default: '',
    },
  },
});
