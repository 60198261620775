import { HandlebarsRender } from '@/decorators/handlebars_render';
import { ActionTypeKbn } from '@/utils/kbn';

// class-transformerによるsubTypeの型変換を再帰的になるように定義したら実行時エラーとなった
// はじめにclassに変換することは難しそうなので、ActionBaseはインターフェースとして、利用時にclassに変換するような方針とする
export class ActionBase {
  typeKbn!: ActionTypeKbn;

  // option はまだ使えるようにしておくが、将来的にこれは子クラスに移譲して廃止する
  @HandlebarsRender()
  option?: string;

  use(): UseActionExecuteType {
    return () => Promise.resolve(false);
  }
}

type IsActionBreak = boolean; // trueをアクションを中断する値とする

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseActionExecuteType = (...Args: any[]) => Promise<IsActionBreak>;
