import { useTitle } from '@vueuse/core';
import { watch } from 'vue';
import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router';

import { useInjectLandingPage } from '@/hooks/use_landing_page';

export function useTitleController() {
  const title = useTitle();
  const landingPage = useInjectLandingPage();
  const router = useRouter();

  watch(router.currentRoute, (currentRoute) => {
    refreshTitle(currentRoute);
  },
  {
    deep: true,
  });

  function refreshTitle(currentRoute?: RouteLocationNormalizedLoaded) {
    if (!currentRoute) currentRoute = router.currentRoute.value;
    const page = landingPage.pages.find((page) => page.path === currentRoute?.name);
    if (typeof currentRoute.meta.title === 'string') { // routerのmeta title（カスタム用）
      title.value = currentRoute.meta.title;

    } else if (page?.title) { // landingPage(入稿シート)のページのtitle
      title.value = page.title;

    } else if (landingPage.defaultTitle) { // landingPage(入稿シート)の共通のtitle
      title.value = landingPage.defaultTitle;

    } else {
      title.value = 'タイトルを設定してください';
    }
  }

  return {
    refreshTitle,
  };
}
