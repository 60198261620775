
import { defineComponent, PropType } from 'vue';

import { Style } from '@/models/style';

export default defineComponent({
  name: 'BaseButton',
  props: {

    /**
     * スタイルバリアント
     */
    variant: {
      type: String,
      default: '',
    },

    /**
     * ボタンのスタイル
     */
    buttonStyle: {
      type: Object as PropType<Style>,
      required: false,
      default: () => ({}),
    },
  },
});
