import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonBuildDialog = _resolveComponent("JsonBuildDialog")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogs, (dialog) => {
    return (_openBlock(), _createBlock(_component_BaseDialog, {
      key: dialog.uniqueKey,
      open: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_JsonBuildDialog, { dialog: dialog }, null, 8, ["dialog"])
      ]),
      _: 2
    }, 1024))
  }), 128))
}