import { plainToClass } from 'class-transformer';
import { ref } from 'vue-demi';

import { Mileage } from '@/models/mileage';

import { useApi } from './use_api';

const mileage = ref<Mileage>();

/**
 * マイレージ取得
 * point: マイレージ 合計ポイント
 * pointHistory: ポイント増減イベント
 */
export function useMileage() {
  const api = useApi();

  async function getMileage() {
    const response = await api.getUsers();
    mileage.value = plainToClass(Mileage, response.dataJson.mileage);

    // 応募日時の昇順にソート
    mileage.value.pointHistories?.sort((first, second) => first.entriedAt.unix() - second.entriedAt.unix());
  }

  // テスト用。APIを叩かずに、指定したポイント履歴を出すため。
  function setMileage(testValue: Mileage) {
    mileage.value = testValue;
  }

  function isMileageContent(_kbn?: string) {
    return false;
  }

  return {
    mileage,
    getMileage,
    setMileage,
    isMileageContent,
  };
}
