import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentImage = _resolveComponent("ContentImage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ContentImage, {
      "content-style": _ctx.imageStyle,
      medium: _ctx.titleMedium
    }, null, 8, ["content-style", "medium"]),
    (_ctx.campaignActivity?.sukkiriIsInStock)
      ? (_openBlock(), _createBlock(_component_ContentImage, {
          key: 0,
          "content-style": _ctx.imageStyle,
          medium: _ctx.sukkiriNormalMedium
        }, null, 8, ["content-style", "medium"]))
      : (_openBlock(), _createBlock(_component_ContentImage, {
          key: 1,
          "content-style": _ctx.imageStyle,
          medium: _ctx.sukkiriGrayMedium
        }, null, 8, ["content-style", "medium"])),
    (_ctx.campaignActivity?.shittoriIsInStock)
      ? (_openBlock(), _createBlock(_component_ContentImage, {
          key: 2,
          "content-style": _ctx.imageStyle,
          medium: _ctx.shittoriNormalMedium
        }, null, 8, ["content-style", "medium"]))
      : (_openBlock(), _createBlock(_component_ContentImage, {
          key: 3,
          "content-style": _ctx.imageStyle,
          medium: _ctx.shittoriGrayMedium
        }, null, 8, ["content-style", "medium"])),
    (_ctx.campaignActivity?.nemotoIsInStock)
      ? (_openBlock(), _createBlock(_component_ContentImage, {
          key: 4,
          "content-style": _ctx.imageStyle,
          medium: _ctx.nemotoNormalMedium
        }, null, 8, ["content-style", "medium"]))
      : (_openBlock(), _createBlock(_component_ContentImage, {
          key: 5,
          "content-style": _ctx.imageStyle,
          medium: _ctx.nemotoGrayMedium
        }, null, 8, ["content-style", "medium"]))
  ], 64))
}