import Handlebars from 'handlebars/lib/handlebars';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { computed, Ref } from 'vue';

import { handlebarsRenderStorage } from '@/decorators/handlebars_render';
import { useState } from '@/hooks/use_state';

import { useQueryStringStore } from './use_querystring_store';
import { useSerialCode } from './use_serial_code';
import { useTrackingId } from './use_tracking_id';

export function useHandlebarsRender<T>(refValue: Ref<T>) {
  // うまく渡せないデータを一部後回し
  // const insertDataStore = reactive(useInsertDataStore());
  // const survey = useSurvey();
  const trackingId = useTrackingId();
  const serialCode = useSerialCode();
  const querystringStore = useQueryStringStore();
  const state = useState();

  return computed(() => {

    // 参照データ取り出し
    const renderingValues = {
      trackingId: trackingId.value,
      serialCode: serialCode.value,
      querystringStore: querystringStore.values.value,
      state: state.value,
    };

    // レンダリング
    return reObjectRendering(refValue.value, renderingValues);
  });
}

// オブジェクト再起処理。HandlebarsRenderデコレーターが付与されているフィールドにのみhandlebarsをかける
function reObjectRendering<T>(value: T, renderingValues: unknown): T {
  if (isArray(value)) {
    return value.map((v) => reObjectRendering(v, renderingValues)) as unknown as T;
  } else if (isObject(value)) {
    const obj = clone(value) as Record<string, unknown>;
    Object.keys(obj).forEach((key) => {
      if (handlebarsRenderStorage.hasHandlebarsRenderMetadata(obj.constructor, key)) {
        obj[key] = rendering(obj[key], renderingValues);
      } else {
        obj[key] = reObjectRendering(obj[key], renderingValues);
      }

    });
    return obj as T;
  } else {
    return value;
  }
}

function rendering(value: unknown, renderingValues: unknown) {
  if (!value || !isString(value)) return value;
  const template = Handlebars.compile(value);
  return template(renderingValues);
}
