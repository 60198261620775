
import { defineComponent } from 'vue';

import { usePopupDialog } from '@/hooks/use_popup_dialog';

import BaseDialog from './BaseDialog.vue';
import LinePayDialogAgree from './LinePayDialogAgree.vue';
import LinePayDialogResult from './LinePayDialogResult.vue';
import MessageCard from './MessageCard.vue';

export default defineComponent({
  name: 'Dialog',
  components: {
    MessageCard,
    BaseDialog,
    LinePayDialogResult,
    LinePayDialogAgree,
  },
  setup() {
    return usePopupDialog();
  },
});
