import { Type } from 'class-transformer';

import { ContentBase } from '@/models/content_base';
import { ContentTypeKbn } from '@/utils/kbn';

type TypeContentMap = {
  value: typeof ContentBase;
  name: ContentTypeKbn;
};

// 循環参照の問題が発生するのでここでデータを保持する
const typeContentMaps: TypeContentMap[] = [];

export default function TypeContent() {
  return function(target: object, propertyKey: string) {
    Type(() => ContentBase, {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: 'typeKbn',
        subTypes: typeContentMaps,
      },
    })(target, propertyKey);
  };
}

/**
 * クラス変換用のマッピングを設定する
 */
export function setupTypeContent(from: TypeContentMap[]) {
  // 配列の全データを追加しなおす
  typeContentMaps.splice(0);
  typeContentMaps.push(...from);
}
