import { SurveyPatternItem } from '@/models/survey_pattern_item';

import { CrmKbnConst } from './crm_kbn';

export function surveyPatternItemTypeRemarks(item: SurveyPatternItem) {
  let remarks = '';
  switch (item.uiKbn) {
  case CrmKbnConst.UI_KBN_SINGLE_LINE_TEXT:
  case CrmKbnConst.UI_KBN_MULTI_LINES_TEXT:
    remarks += '入力してください';
    break;
  case CrmKbnConst.UI_KBN_LIST:
  case CrmKbnConst.UI_KBN_SINGLE_LIST:
  case CrmKbnConst.UI_KBN_RADIO_BUTTON:
  case CrmKbnConst.UI_KBN_CHECKBOX:
    remarks += '選択してください';
    break;
  case CrmKbnConst.UI_KBN_BIRTHDAY:
    remarks += '日付を入力してください';
    break;
  }

  if (item.uiKbn === CrmKbnConst.UI_KBN_LIST || item.uiKbn === CrmKbnConst.UI_KBN_CHECKBOX) {
    const maxSelectableCount = item.contentJson.validations.maxSelectableCount;
    remarks += maxSelectableCount ? '複数選択できます' : '';
  }
  return remarks;
}
