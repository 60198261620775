
import liff from '@line/liff';
import { defineComponent, onMounted, ref } from 'vue';

import { ActionCheckOffHour } from './hooks/use_action_check_off_hour';
import { useApi, useMockApi } from './hooks/use_api';
import { useInsertDataStore } from './hooks/use_insert_data_store';
import { useInjectLandingPage } from './hooks/use_landing_page';
import { useMedia } from './hooks/use_media';
import { useQueryStringStore } from './hooks/use_querystring_store';
import { useProvideStyleTemplate } from './hooks/use_style_template';
import { useTitleController } from './hooks/use_title_controller';
import { useTrackingId } from './hooks/use_tracking_id';
import { campaign } from './json/json_loader';
import { isLocal } from './utils/debug';

export default defineComponent({
  name: 'App',
  setup() {
    if (isLocal()) {
      useMockApi();
    }

    // router/index.ts に定義されたタイトルを反映する
    const { refreshTitle } = useTitleController();
    refreshTitle();

    const querystringStore = useQueryStringStore();
    querystringStore.setValues();

    const insertDataStore = useInsertDataStore();
    insertDataStore.queryString.value = querystringStore.values.value;

    const show = ref(false);
    const api = useApi();
    const checkOffHour = new ActionCheckOffHour().use();

    const trackingId = useTrackingId();

    const landingPage = useInjectLandingPage();
    useProvideStyleTemplate(landingPage.styleTemplates);

    useMedia({
      getAssetsUrl: async (dir, medium) => {
        if (!medium.name) {
          return '';
        }
        return require(`@/assets/${dir}/${medium.name}`);
      },
    });
    onMounted(async () => {
      try {
        // liffがターゲットならログイン
        if (landingPage.isTargetLiff()) {
          if (await checkOffHour()) return; // ログイン処理で期間外の前後チェックができないので先にチェックする

          // BC4L ログイン
          let response;
          if (campaign.isOnlyOpenid) {
            const idToken = liff.getIDToken();
            if (!idToken) throw Error('Not Found Token'); // 仮エラー
            response = await api.postLiffLoginOpenid(idToken);
          } else {
            const accessToken = liff.getAccessToken();
            if (!accessToken) throw Error('Not Found Token'); // 仮エラー
            response = await api.postLiffLogin(accessToken);
          }
          trackingId.value = response.trackingId;
        }

      } finally {
        show.value = true;
      }
    });

    return {
      show,
    };
  },
});
