import * as convertKeys from 'convert-keys';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const store = ref();

export function useQueryStringStore() {
  const router = useRouter();

  async function deleteLineCallbackQuery() {
    const updateQuery = {
      ...router.currentRoute.value.query,
      code: undefined, // LineCallbackQueryのキーを削除
      error: undefined,
      friendship_status_changed: undefined,
      state: undefined,
    };
    await router.replace({ query: updateQuery });
    store.value = updateQuery;
  }

  async function deleteTwitterCallbackQuery() {
    const updateQuery = {
      ...router.currentRoute.value.query,
      oauth_token: undefined, // TwitterCallbackQueryのキーを削除
      oauth_verifier: undefined,
      state: undefined,
    };
    await router.replace({ query: updateQuery });
    store.value = updateQuery;
  }

  async function deleteGoogleCallbackQuery() {
    const updateQuery = {
      ...router.currentRoute.value.query,
      state: undefined, // GoogleCallbackQueryのキーを削除
      code: undefined,
      scope: undefined,
      authuser: undefined,
      hd: undefined,
    };
    await router.replace({ query: updateQuery });
    store.value = updateQuery;
  }

  async function deleteLinePayCallbackQuery() {
    const updateQuery = {
      ...router.currentRoute.value.query,
      state: undefined, // LinePayCallbackQueryのキーを削除
    };
    await router.replace({ query: updateQuery });
    store.value = updateQuery;
  }
  return {
    values: computed(() => store.value),
    getValues() {
      return store.value;
    },
    setValues() {

      // Linter は変数が CamelCase でないと Error や Warning を出すので CamalCase にして処理
      // リクエスト送信時には snake_case に直される（これは API 側が要求するケーシングに合わせるため）
      // 【注意】snake_case と CamelCase が混在してクエリ文字列に組み込まれていたりすると予期せぬ挙動をする可能性がある
      store.value = convertKeys.toCamel(router.currentRoute.value.query);
    },
    deleteLineCallbackQuery,
    deleteTwitterCallbackQuery,
    deleteGoogleCallbackQuery,
    deleteLinePayCallbackQuery,
  };
}
