import liff from '@line/liff';

import { useApi } from '@/hooks/use_api';
import { useInjectCampaign } from '@/hooks/use_campaign';
import { useInjectLandingPage } from '@/hooks/use_landing_page';
import { useLoader } from '@/hooks/use_loader';
import { usePopupDialog } from '@/hooks/use_popup_dialog';
import { useRouting } from '@/hooks/use_routing';
import { useRum } from '@/hooks/use_rum';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';
import { redirectToLine } from '@/utils/redirect_to_line';

/**
 * ともだちであった場合、何もしない。
 * ともだちでなかった場合トップまたはオプションで指定したアカウントのトークルームに飛ばす。
 * 後続のアクションは実行しない。
 *
 * Line アカウントの BasicIDはlanding_page.jsonから取得する
 */
export class ActionCheckFriend extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_CHECK_FRIEND;

  use() {
    const campaign = useInjectCampaign();
    const landingPage = useInjectLandingPage();
    const api = useApi();
    const loader = useLoader();
    const routing = useRouting();
    const popup = usePopupDialog();
    const rum = useRum();

    return async () => await execute(campaign, landingPage, api, loader, routing, popup, rum);
  }

  constructor(init: Partial<ActionCheckFriend> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  campaign: ReturnType<typeof useInjectCampaign>,
  landingPage: ReturnType<typeof useInjectLandingPage>,
  api: ReturnType<typeof useApi>,
  loader: ReturnType<typeof useLoader>,
  routing: ReturnType<typeof useRouting>,
  popup: ReturnType<typeof usePopupDialog>,
  rum: ReturnType<typeof useRum>,
) {

  async function getIsFriend() {
    if (campaign.isOnlyOpenid) {
      await api.postInsertOpenid({});
      const response = await api.getUsers();
      return response.dataJson.isFriend || false;

    } else if (landingPage.isTargetLiff()) {
      const friendship = await liff.getFriendship();
      return friendship.friendFlag;

    } else {
      await api.postInsert({});
      const response = await api.getUsers();
      return response.dataJson.isFriend || false;
    }
  }

  let isFriend = false;

  try {
    loader.start();
    isFriend = await getIsFriend();

  } catch (e) {
    if (e instanceof Error && e.message === 'The permission is not in LIFF app scope.') {
      // LIFF のスコープエラーの調査のためにログを仕込む
      // https://uv-jp.slack.com/archives/C06HKDUHZPC/p1710465471322539
      const context = liff.getContext();
      rum.recordEvent('liff_scope_error', { context });
    }

    throw e;

  } finally {
    loader.stop();
  }

  if (!isFriend) {
    await popup.alert('キャンペーンアカウントと友だちになる必要があります。');
    if (landingPage.isTargetLiff()) {
      redirectToLine(campaign.clientBasicId, campaign.escapeUrl);

    } else {
      await routing.redirectTop();
    }
    return true;
  }

  return false;
}

export function generateActionCheckFriend() {
  return new ActionCheckFriend();
}
