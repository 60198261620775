
import { ActionBase } from '@/models/action_base';
import { debugLog } from '@/utils/debug';

// アクション実行関数を得る
// Vue上でのアクション実行を想定しているため、Composition APIとして提供する
export function useActions(actions: ActionBase[]) {
  const executes = actions.map(a => a.use());

  return async () => {
    for (let i = 0; i < executes.length; ++i) {
      const kbn = actions[i].typeKbn;
      debugLog(`execute ${kbn} start`);
      const isActionBreak = await executes[i]();
      debugLog(`execute ${kbn} end`);
      if (isActionBreak) break;
    }
  };
}
