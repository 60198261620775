import { computed, ref } from 'vue-demi';

const loadingCount = ref(0);

export function useLoader()  {
  return {
    loading: computed(() => loadingCount.value > 0),
    start: () => loadingCount.value++,
    stop: () => loadingCount.value > 0 ? loadingCount.value-- : 0,
  };
}
