import { App } from 'vue';

import { CrmKbnConst } from './crm_kbn';
import { KbnConst } from './kbn';

export const KbnInstall = {
  install: (app: App) => {
    app.config.globalProperties.$kbnConst = KbnConst;
    app.config.globalProperties.$crmKbnConst = CrmKbnConst;
  },
};

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $kbnConst: typeof KbnConst // グローバル変数の型定義
    $crmKbnConst: typeof CrmKbnConst // CRMグローバル変数の型定義
  }
}
