import { usePopupDialog } from '@/hooks/use_popup_dialog';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useSurvey } from './use_survey';

/**
 * アンケートのバリデーションを行う
 */

export class ActionSurveyCheck extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_SURVEY_CHECK;

  use() {
    const survey = useSurvey();
    const popupDialog = usePopupDialog();
    return async () => await execute(survey, popupDialog);
  }

  constructor(init: Partial<ActionSurveyCheck> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(survey: ReturnType<typeof useSurvey>, popupDialog: ReturnType<typeof usePopupDialog>) {
  if (survey.invalid()) {
    await popupDialog.alert('未入力の項目があります。');
    return true;
  }

  return false;
}

export function generateActionSurveyCheck() {
  return new ActionSurveyCheck();
}
