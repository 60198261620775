import { get, MaybeRef } from '@vueuse/shared';
import { computed } from 'vue';

const UV_ICON_MAP: Record<string, string> = {
};

function registerDefaultIcon(key: string) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  UV_ICON_MAP[key] = require(`!!html-loader!../assets/default/icons/${key}.svg`) as string;
}

export const icons = [
  'arrow_down',
  'arrow_next',
  'arrow_previous',
  'attention',
  'batsu',
  'calendar',
  'cart',
  'check',
  'checkbox-inner',
  'checkbox-outer',
  'compare',
  'description',
  'explore',
  'help_outline',
  'history',
  'home',
  'loading',
  'lock',
  'login',
  'logout',
  'mail',
  'menu',
  'minus',
  'notification',
  'plus',
  'star',
  'transfer',
  'trash',
  'upload',
] as const;

icons.forEach(registerDefaultIcon);

export function useBaseIconMap() {
  return UV_ICON_MAP;
}

export function useBaseIcons() {
  return Object.keys(useBaseIconMap());
}

export function useBaseIcon(key: MaybeRef<string>) {
  const path = computed(() => UV_ICON_MAP[get(key)]);
  if (!path.value) {
    throw new Error(`このカギを持ったアイコンが登録されていない：${get(key)}`);
  }

  return path;
}

export type Icon = typeof icons[number];
