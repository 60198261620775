
import { computed, defineComponent, nextTick, PropType, ref } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { ConnectAction, ConnectActionControl } from '@/models/content_text_editor_model';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentTextEditor',
  components: { BaseStyle },
  props: {
    html: {
      type: String,
      default: undefined,
    },
    connectActions: {
      type: Object as PropType<ConnectAction[]>,
      default: undefined,
    },
  },
  setup(props) {
    const editor = ref<HTMLElement>();
    const executeConnectActions = (props.connectActions ?? []).map(item => {
      return {
        ...item,
        execute: useActions(item.actions),
      };
    });

    const fixedHtml = computed(() => {
      let html = props.html ?? '';

      const findContentKeys = [...html.matchAll(ConnectActionControl.linkRegex)];
      findContentKeys.forEach(item => {
        const origin = item[0];
        const key = item[1];
        html = html.replace(origin, ConnectActionControl.linkClass(key));
      });

      nextTick(() => {
        executeConnectActions.forEach((item) => {
          if (editor.value) {
            const anchors = editor.value.querySelectorAll(ConnectActionControl.classQuerySelector(item.key));
            for (let i = 0; i < anchors.length; i++) {
              const anchor = anchors[i] as HTMLElement;
              anchor.style.cursor = 'pointer';
              (anchor as HTMLElement).onclick = async function() {
                await item.execute();
              };
            }
          }
        });
      });

      return html;
    });

    return {
      fixedHtml,
      editor,
    };
  },
});
