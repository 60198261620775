import { Transform } from 'class-transformer';

import TypeContent from '@/decorators/type_content';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';
import { ContentTextEditorModel } from './content_text_editor_model';

export class ContentAccordinationModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_ACCORDION;

  @TypeContent()
  @Transform((params) => params.value || [])
  openedHeaders: ContentBase[] = [];

  @TypeContent()
  @Transform((params) => params.value || [])
  closedHeaders: ContentBase[] = [];

  @TypeContent()
  @Transform((params) => params.value || [])
  contents: ContentBase[] = [];

  constructor(init?: Partial<ContentAccordinationModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentAccordinationModel,
  ({ style }) => new ContentAccordinationModel({
    style,
    openedHeaders: [
      new ContentTextEditorModel({
        text: 'openedHeaders',
      }),
    ],
    closedHeaders: [
      new ContentTextEditorModel({
        text: 'closedHeaders',
      }),
    ],
    contents: [
      new ContentTextEditorModel({
        text: 'contents 1',
      }),
      new ContentTextEditorModel({
        text: 'contents 2',
      }),
    ],
  }),
);
