
import isArray from 'lodash/isArray';
import { computed, defineComponent } from 'vue';

import { useSurvey } from '@/hooks/use_survey';
import { SurveyPatternItem } from '@/models/survey_pattern_item';
import { isBirthday, toDateString } from '@/types/Birthday';
import { surveyPatternItemTypeRemarks } from '@/utils/survey_pattern_item_type_remarks';
import { surveyText } from '@/utils/survey_text';

import SurveyErrorText from './SurveyErrorText.vue';
import SurveyHeader from './SurveyHeader.vue';

export default defineComponent({
  name: 'ContentSurveyConfirm',
  components: { SurveyErrorText,
    SurveyHeader },
  setup() {
    const survey = useSurvey();

    const getAnswer = (item: SurveyPatternItem) => {
      const answer = survey.answers.value[item.uuid];

      if (isArray(answer)) {
        return surveyText(answer);

      } else if (typeof answer === 'string') {
        return surveyText(answer) || answer;

      } else if (isBirthday(answer)) {
        return toDateString(answer);
      }
    };

    return {
      getAnswer,
      items: computed(() => survey.survey.value?.items || []),
      answers: survey.answers,
      errors: survey.errors,
      surveyPatternItemTypeRemarks,
    };
  },
});
