
import { defineComponent, PropType } from 'vue';

import BaseIcon from '@/components/BaseIcon.vue';
import BaseStyle from '@/components/BaseStyle.vue';
import { useActions } from '@/hooks/use_actions';
import { ActionBase } from '@/models/action_base';
import { Style } from '@/models/style';

export default defineComponent({
  name: 'ContentLink',
  components: {
    BaseStyle,
    BaseIcon,
  },
  props: {

    contentStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

    iconSrc: {
      type: String,
      default: undefined,
    },

    underline: {
      type: Boolean,
      default: undefined,
    },

    text: {
      type: String,
      default: undefined,
    },

    actions: {
      type: Array as PropType<Array<ActionBase>>,
      default: () => [],
    },

    /**
     * クリックの直接指定（actionsより優先）
     */
    click: {
      type: Function as PropType<() => Promise<void>>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const execute = useActions(props.actions);
    async function onClick() {
      if (props.click) {
        await props.click();
      } else {
        await execute();
      }
    }

    return {
      onClick,
    };
  },
});
