import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { RumConfig } from '@/models/rum_config';
import { isLocal } from '@/utils/debug';

function createRumInstance(rumConfig: RumConfig) {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: rumConfig.identityPoolId,
    endpoint: 'https://dataplane.rum.ap-northeast-1.amazonaws.com',
    telemetries: ['performance', 'errors', ['http', { recordAllRequests: true }]],
    allowCookies: true,
    enableXRay: true,
  };

  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'ap-northeast-1';

  try {
    return new AwsRum(
      rumConfig.applicationId,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );

  } catch {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    return null;
  }

}

let rum: AwsRum | null = null;

/**
 * RUM を準備する
 * undefined を指定した場合にはダミーの RUM になる
 */
export function setupRum(config?: RumConfig) {
  if (!isLocal() && config) {
    rum = createRumInstance(config);
  }

  return useRum();
}

type ButtonClickEvent = { buttonLabel?: string, imageUrl?: string };

export function useRum() {
  function recordEvent(eventName: string, event: Record<string, unknown>) {
    rum?.recordEvent(eventName, { ...event });
  }

  function recordButtonClickEvent(event: ButtonClickEvent) {
    recordEvent('button_click_event', { ...event });
  }

  function recordError(error: unknown) {
    rum?.recordError(error);
  }

  return {
    recordEvent,
    recordButtonClickEvent,
    recordError,
  };
}
