
import liff from '@line/liff';
import { LiffMockPlugin } from '@line/liff-mock';

liff.use(new LiffMockPlugin());

liff.$mock.set((p) => {
  return {
    ...p,
    isLoggedIn: true,
    getFriendship: { friendFlag: true },
    'permission.query': {
      state: 'granted',
    },
  };
});
