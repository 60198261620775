
import { toRefs } from '@vueuse/core';
import { defineComponent, onMounted, PropType, ref } from 'vue';

import { useCurrentMedia } from '@/hooks/use_media';
import { Medium } from '@/models/medium';
import { Style } from '@/models/style';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentVideo',
  components: { BaseStyle },
  props: {

    /**
     * スタイル
     */
    contentStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

    /**
     * 動画
     */
    medium: {
      type: Object as PropType<Medium>,
      default: undefined,
    },

    /**
     * 自動再生フラグ
     */
    autoFlag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { medium } = toRefs(props);
    const { getAssetsUrl } = useCurrentMedia();
    const url = ref<string | undefined>(undefined);
    onMounted(async () => {
      url.value = medium.value ? `${ await getAssetsUrl('videos', medium.value) }` : undefined;
    });
    return {
      url,
    };
  },
});
