import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    ref: "inputElement",
    class: "hidden",
    type: "file",
    accept: "image/jpg,image/jpeg,image/png",
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, null, 544))
}