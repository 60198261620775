import dayjs from 'dayjs';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';
import { z } from 'zod';

import { SurveyAnswersType, SurveyErrorsType } from '@/hooks/use_survey';
import { SurveyPattern } from '@/models/survey_pattern';
import { SurveyPatternItem } from '@/models/survey_pattern_item';

import { CrmKbnConst } from './crm_kbn';

export function surveyValidation(survey: SurveyPattern | undefined, answers: SurveyAnswersType) {
  if (!survey) return {}; // surveyがない場合は判定しない

  const surveySchema = generateSurveyZod(survey);
  const result = surveySchema.safeParse(answers);
  if (result.success) return {};

  return parseZodError(result.error);
}

function generateSurveyZod(survey: SurveyPattern) {
  const zobj: Record<string, z.ZodTypeAny> = {};
  for (const item of survey.items) {
    zobj[`${item.uuid}`] = generateSurveyItemZod(item);
  }
  return z.object(zobj);
}

function generateSurveyItemZod(item: SurveyPatternItem) {
  switch (item.uiKbn) {
  case CrmKbnConst.UI_KBN_BIRTHDAY:
    return zodBirthday(item);
  case CrmKbnConst.UI_KBN_LIST:
  case CrmKbnConst.UI_KBN_CHECKBOX:
    return zodList(item);
  case CrmKbnConst.UI_KBN_MULTI_LINES_TEXT:
  case CrmKbnConst.UI_KBN_SINGLE_LINE_TEXT:
  case CrmKbnConst.UI_KBN_SINGLE_LIST:
  case CrmKbnConst.UI_KBN_RADIO_BUTTON:
    return zodText(item);
  default:

    // case 文に漏れが合った場合 neverCheck がエラーになる
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const neverCheck: never | undefined = item.uiKbn;

    throw new Error(`validation unsupported type ${item.uiKbn}`);
  }
}

function zodBirthday(item: SurveyPatternItem) {
  let scheme: z.ZodEffects<z.ZodTypeAny> = z.object({
    year: z.number().optional(),
    month: z.number().optional(),
    day: z.number().optional(),
  }).optional().refine(
    (value) => validDate(value?.year, value?.month, value?.day),
    () => ({ message: '日付が間違っています' }),
  );

  const maxDate = item.contentJson.validations.maxDate;
  if (maxDate) scheme = scheme.refine(
    (value) => value?.year && value?.month && value?.day && !birthdayToDayjs(value.year, value.month, value.day).isAfter(stringToDayjs(maxDate)),
    () => ({ message: `${maxDate}より前の日付を入力してください` }),
  );
  const minDate = item.contentJson.validations.minDate;
  if (minDate) scheme = scheme.refine(
    (value) => value?.year && value?.month && value?.day && !birthdayToDayjs(value?.year, value?.month, value?.day).isBefore(stringToDayjs(minDate)),
    () => ({ message: `${minDate}より後の日付を入力してください` }),
  );
  const today = dayjs().startOf('day');
  const canSelectPast = item.contentJson.validations.canSelectPast;
  if (!isUndefined(canSelectPast) && !canSelectPast) scheme = scheme.refine(
    (value) => value?.year && value?.month && value?.day && !birthdayToDayjs(value.year, value.month, value.day).isBefore(today),
    () => ({ message: '今日より後の日付を入力してください' }),
  );
  const canSelectToday = item.contentJson.validations.canSelectToday;
  if (!isUndefined(canSelectToday) && !canSelectToday) scheme = scheme.refine(
    (value) => value?.year && value?.month && value?.day && !birthdayToDayjs(value.year, value.month, value.day).isSame(today),
    () => ({ message: '今日以外の日付を入力してください' }),
  );
  const canSelectFuture = item.contentJson.validations.canSelectFuture;
  if (!isUndefined(canSelectFuture) && !canSelectFuture) scheme = scheme.refine(
    (value) => value?.year && value?.month && value?.day && !birthdayToDayjs(value.year, value.month, value.day).isAfter(today),
    () => ({ message: '今日より前の日付を入力してください' }),
  );

  if (!isUndefined(item.required) && item.required) {
    scheme = scheme.refine(
      (value) => value?.year && value?.month && value?.day, // 0は来ない想定
      () => ({ message: '必須項目です' }),
    );
  }

  return scheme;
}

function validDate(year: number | undefined, month: number | undefined, day: number | undefined) {
  try {
    if (!year && !month && !day) return true; // すべて未入力なら日付チェックをせず成功で通す。0は来ない想定
    const date = dayjs(`${year}-${month}-${day}`);
    return date.year() === year
      && (date.month() + 1) === month
      && date.date() === day;
  } catch {
    return false;
  }
}

function stringToDayjs(dateString: string) {
  return dayjs(dateString);
}

function birthdayToDayjs(year: number, month: number, day: number) {
  return dayjs().year(year).month(month - 1).date(day).startOf('day');
}

function zodList(item: SurveyPatternItem) {
  let scheme: z.ZodTypeAny = z.array(z.string()).optional();
  const maxSelectableCount = item.contentJson.validations.maxSelectableCount;
  if (maxSelectableCount) scheme = scheme.refine(
    (value) => (value?.length || 0) <= maxSelectableCount,
    () => ({ message: `${maxSelectableCount}件以下をチェックしてください` }),
  );
  if (item.required) scheme = scheme.refine(
    (value) => (value?.length || 0) > 0,
    () => ({ message: '必須項目です' }),
  );
  return scheme;
}

function zodText(item: SurveyPatternItem) {
  let scheme: z.ZodTypeAny = z.string().optional();
  const maxLength = item.contentJson.validations.maxLength;
  if (maxLength) scheme = scheme.refine(
    (value) => (value?.length || 0) <= maxLength,
    () => ({ message: `${maxLength}文字数以下で入力してください` }),
  );
  if (item.required) scheme = scheme.refine(
    (value) => !!value,
    () => ({ message: '必須項目です' }),
  );
  return scheme;
}

function parseZodError(error: z.ZodError) {
  const result: SurveyErrorsType = {};
  for (const issue of error.issues) {
    set(result, issue.path, issue.message);
  }
  return result;
}

export const __test__ = {
  zodBirthday,
  zodList,
  zodText,
};
