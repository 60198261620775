import { Transform, Type } from 'class-transformer';
import range from 'lodash/range';
import repeat from 'lodash/repeat';

import { SurveyPatternItemContentJson, SurveyPatternItemValidations } from '@/models/survey_pattern_item';
import { CrmKbnValues, ItemTypeKbn, UiKbn } from '@/utils/crm_kbn';
import { generate, registerGenerator } from '@/utils/generate';

import { CrmKbnConst } from './../utils/crm_kbn';
import { Model } from './model';
import { SurveyOptionItem } from './survey_option_item';
import { SurveyPatternItem } from './survey_pattern_item';

export class SurveyPattern extends Model<SurveyPattern> {
  @Transform((params) => params.value || '')
  surveyUuid: string = '';

  @Transform((params) => params.value || '')
  patternUuid: string = '';

  patternName?: string;

  userPublishedAt?: string;

  @Type(() => SurveyPatternItem)
  @Transform((params) => params.value || [])
  items: SurveyPatternItem[] = [];

  constructor(init?: Partial<SurveyPattern>) {
    super();
    Object.assign(this, init);
  }
}

function getItemTypeKbn(i: number): ItemTypeKbn {
  const itemTypeKbns = CrmKbnValues[CrmKbnConst.ITEM_TYPE_KBN];
  return itemTypeKbns[i % itemTypeKbns.length] as ItemTypeKbn;
}

function getUiKbn(i: number): UiKbn {
  const uiKbns = CrmKbnValues[CrmKbnConst.UI_KBN];
  return uiKbns[i % uiKbns.length] as UiKbn;
}

registerGenerator(
  SurveyPattern,
  (props) => new SurveyPattern({
    surveyUuid: 'survey1',
    patternUuid: 'pattern1',
    userPublishedAt: '12345',
    patternName: repeat('パターン名', props.size),
    items: range(0, 10).map(
      (i) => generate(SurveyPatternItem, props).with({
        uuid: `pattern_item${i}`,
        questionContent: `質問${i}`,
        typeKbn: getItemTypeKbn(i),
        uiKbn: getUiKbn(i),
        required: [true, false][i % 2],
        contentJson: new SurveyPatternItemContentJson({
          options: range(props.size).map((i) => new SurveyOptionItem(`選択肢${i}`, `v${i}`)),
          validations: new SurveyPatternItemValidations({
            maxLength: 30,
            maxSelectableCount: 2,
          }),
        }),
      })),
  }),
);
