import { UseActionExecuteType } from '../models/action_base';
import { useInsertDataStore } from './use_insert_data_store';
import { useSurvey } from './use_survey';

/**
 * 一時保存しているデータを削除する
 * 対象：postInsertで送信するデータ
 */
export function useActionDataClear(): UseActionExecuteType {
  const insertDataStore = useInsertDataStore();
  const survey = useSurvey();

  return async () => {

    insertDataStore.clear();
    survey.clear();

    return false;
  };
}
