import { registerGenerator } from '@/utils/generate';

import { Model } from './model';

export class RumConfig extends Model<RumConfig> {
  /**
   * アプリケーションモニター ID
   */
  applicationId!: string;

  /**
   * Cognito ユーザー ID
   */
  identityPoolId!: string;

  constructor(init: Pick<RumConfig, 'applicationId' | 'identityPoolId'>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  RumConfig,
  () => new RumConfig({
    applicationId: 'aaa',
    identityPoolId: 'bbb',
  }),
);

