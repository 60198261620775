import differenceBy from 'lodash/differenceBy';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { LandingPage } from '@/models/landing_page';
import { Page } from '@/models/page';
import ErrorPage from  '@/pages/ErrorPage.vue';
import JsonBuildPage from '@/pages/JsonBuildPage.vue';
import OffHourPage from  '@/pages/OffHourPage.vue';

import RouterConst from './router_const';

function generateRoute(pages: Page[]): Array<RouteRecordRaw> {
  pages = differenceBy(pages, [
    { name: RouterConst.Error }, // Errorはデフォルトページが存在するので生成しない
    { name: RouterConst.OffHour }, // OffHourはデフォルトページが存在するので生成しない
  ], 'name');
  return pages.map<RouteRecordRaw>((page) => {
    return {
      path: `/${page.path}`,
      name: page.path,
      component: JsonBuildPage,
      props: {
        page: page,
      },
    };
  });
}

export function createLandingPageRouter(landingPage: LandingPage) {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      redirect: landingPage.rootPage?.path || RouterConst.Error, // 未設定の場合はエラー画面へ遷移させる
    },

    // エラーページはデフォルトで用意。pagesに設定があれば読み込む
    {
      path: `/${RouterConst.Error}`, //カスタムページと同じようにnameに一致させる
      name: RouterConst.Error,
      component: ErrorPage,
      props: {
        page: landingPage.pages.find((page) => page.path === RouterConst.Error),
      },
    },

    // 期間外ページはデフォルトで用意。pagesに設定があれば読み込む
    {
      path: `/${RouterConst.OffHour}`, //カスタムページと同じようにnameに一致させる
      name: RouterConst.OffHour,
      component: OffHourPage,
      props: {
        page: landingPage.pages.find((page) => page.path === RouterConst.OffHour),
      },
    },

    // 期間外（期間前）ページはデフォルトで共通期間外ページヘ遷移させる
    {
      path: `/${RouterConst.OffHourBefore}`, //カスタムページと同じようにnameに一致させる
      name: RouterConst.OffHourBefore,
      redirect: RouterConst.OffHour,
    },
    ...generateRoute(landingPage.pages),

    // 未定義ページアクセスはエラー画面へ遷移させる
    {
      path: '/:catchAll(.*)',
      redirect: RouterConst.Error,
    },
  ];

  const router = createRouter({

    // サブディレクトリでホストするときはハッシュ付きにしないとドメイン直下がルートになってしまうので注意。
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition; // 戻る、進むのスクロール位置記憶
      } else {
        return { // 新規ページでは毎回画面トップへ遷移
          left: 0,
          top: 0,
        };
      }
    },
  });

  return router;
}
