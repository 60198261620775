
import { defineComponent, onMounted, PropType } from 'vue';

import CommonPage from '@/components/CommonPage.vue';
import { useActions } from '@/hooks/use_actions';
import { useDisabledContents } from '@/hooks/use_disabled_contents';
import { useMileage } from '@/hooks/use_mileage';
import { ContentTextEditorModel } from '@/models/content_text_editor_model';
import { Page } from '@/models/page';
import { Style } from '@/models/style';
import RouterConst from '@/router/router_const';

const defaultPage = new Page({
  path: RouterConst.OffHour,
  contents: [
    new ContentTextEditorModel({
      text: 'キャンペーンは開催されていません',
      style: new Style({ textAlign: 'center' }),
    }),
  ],
});

export default defineComponent({
  name: 'OffHourPage',
  components: { CommonPage },
  props: {
    page: {
      type: Object as PropType<Page>,
      default: () => undefined,
    },
  },
  setup: (props) => {
    const offHourPage = props.page || defaultPage;
    const actions = useActions(offHourPage.actions);
    const { getMileage, isMileageContent } = useMileage();
    const { shouldVerifyDisablement, getDisabledContents } = useDisabledContents();
    const hasMileage = offHourPage.contents.find(content => isMileageContent(content.typeKbn));
    const hasDisablement = offHourPage.contents.find(content => shouldVerifyDisablement(content.id));
    onMounted(async () => {
      await actions();

      // マイレージポイント表示、マイレージポイント履歴表示のため
      if (hasMileage) {
        await getMileage();
      }

      // 非活性コンテンツを取得する
      if (hasDisablement) {
        await getDisabledContents();
      }
    });
    return {
      offHourPage,
    };
  },
});
