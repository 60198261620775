import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentSurveyInputModel extends ContentBase {
  constructor(init?: Partial<ContentSurveyInputModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_SURVEY_INPUT; // クラスに対して区分値固定なので、コンストラクタで代入
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentSurveyInputModel,
  () => new ContentSurveyInputModel({
    typeKbn: KbnConst.CONTENT_TYPE_KBN_SURVEY_INPUT,
  }),
);
