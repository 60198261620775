
import QRCode from 'qrcode';
import { defineComponent, onMounted, PropType, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';

import { ContentQrCodeShowModel } from '@/models/content_qr_code_show_model';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentQrCodeShow',
  components: {
    BaseStyle,
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentQrCodeShowModel>,
      required: true,
    },
  },
  setup: (props) => {
    const { model } = toRefs(props);

    const qrCanvas = ref<HTMLCanvasElement>();

    const qrCode = ref('');

    const router = useRouter();

    const updateQrCode = () => {
      const query = router.currentRoute.value.query;

      // クエリパラメータからQRコードを取り出す
      qrCode.value = query['qr_code'] as string | null ?? '';

      if (qrCode.value) {
        QRCode.toCanvas(qrCanvas.value, qrCode.value, {
          width: parseInt(model.value.size),
        }, (err: unknown) => {
          if (err) {
            // eslint-disable-next-line no-console
            console.log(err);

            // QRコードはエラーが発生したため、リセットします。
            qrCode.value = '';
          }
        });
      }
    };

    watch(model, () => {
      updateQrCode();
    }, {
      deep: true,
    });

    onMounted(() => {
      updateQrCode();
    });

    return {
      qrCanvas,
      qrCode,
    };
  },
});
