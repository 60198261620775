/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Style } from '@/models/style';

export type Class<T> = Function & { prototype: T };

interface GenerationContext {
  now: Date
  size: number
  admin: boolean
  style: Style
}

type Generator<T> = (context: GenerationContext) => T;

// eslint-disable-next-line object-curly-newline
const generators: Record<string, Generator<any>> = {};

export function registerGenerator<T extends object>(
  type: Class<T>,
  generator: Generator<T>,
) {
  const key = type.name;
  generators[key] = generator;
}

export function generate<T>(
  type: Class<T>,
  context: GenerationContext,
): T {
  const key = type.name;
  const generator = generators[key];

  if (!generator) {
    throw new Error(`型 ${key} はまだgenerateと一緒に使えません。まずregisterGeneratorしてください。`);
  }

  return generator(context);
}
