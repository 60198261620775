import { Type } from 'class-transformer';

import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';
import { Medium } from './medium';

export class ContentVideoModel extends ContentBase {
  @Type(() => Medium)
  medium?: Medium;

  autoFlag?: boolean;

  constructor(init?: Partial<ContentVideoModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentVideoModel,
  ({ style }) => new ContentVideoModel({
    medium: new Medium({ name: 'sample.mp4' }),
    autoFlag: true,
    typeKbn: KbnConst.CONTENT_TYPE_KBN_VIDEO,
    style,
  }),
);
