import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    model: _ctx.model,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.listItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item,
          class: "w-full flex space-x-1"
        }, [
          _createElementVNode("div", {
            class: "flex-shrink-0",
            innerHTML: _ctx.model.markerText
          }, null, 8, _hoisted_1),
          _createElementVNode("div", {
            class: "flex-grow",
            innerHTML: item
          }, null, 8, _hoisted_2)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["model"]))
}