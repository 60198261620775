import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useState } from './use_state';

export class ActionStateSave extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_STATE_SAVE;

  stateKey?: string;

  stateValue?: string;

  use() {
    const stateData = useState();

    return async () => await execute(this.stateKey, this.stateValue, stateData);
  }

  constructor(init: Partial<ActionStateSave> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  stateKey: string | undefined,
  stateValue: string | undefined,
  stateData: ReturnType<typeof useState>,
) {
  if (stateKey) {
    stateData.value[stateKey] = stateValue ?? '';
  }
  return false;
}

export function generateActionStateSave() {
  return new ActionStateSave({
    stateKey: 'state',
    stateValue: 'value',
  });
}
