import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  class: "w-full break-words whitespace-pre-wrap",
  style: {"line-height":"115%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseArrayCheckbox = _resolveComponent("BaseArrayCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createBlock(_component_BaseArrayCheckbox, {
        key: index,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        item: option.value,
        disabled: _ctx.isDisabled(option.value),
        class: _normalizeClass([
        'px-2 py-1',
        !_ctx.isDisabled(option.value) && 'hover:bg-gray-100'
      ])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(option.label || ' '), 1)
        ]),
        _: 2
      }, 1032, ["modelValue", "item", "disabled", "class"]))
    }), 128))
  ]))
}