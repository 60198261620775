import { sleep } from './sleep';

export async function redirectToLine(clientBasicId: string, escapeUrl: string) {
  // スマホでこのURLを開くと友だち追加画面を開く。
  // PCでこのURLを開くと友だち追加へ促すQRコードを表示する。
  window.location.href = `https://line.me/R/ti/p/${clientBasicId}`;

  // アプリが立ち上がった後にLPが残ってしまうのを防ぐ。
  // PCの場合前のURLへ遷移が完了していればそれでよし。
  if (escapeUrl) {
    await sleep(1000); // アプリ立ち上げ待ち時間
    window.location.href = escapeUrl;
  }
}
