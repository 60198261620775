import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-around w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    model: _ctx.model,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseStyle, {
        "content-style": _ctx.group?.style
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group?.contents, (content, index) => {
              return (_openBlock(), _createBlock(_component_Content, {
                key: index,
                content: content
              }, null, 8, ["content"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["content-style"])
    ]),
    _: 1
  }, 8, ["model"]))
}