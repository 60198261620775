
import { computed, defineComponent } from 'vue';

import BaseCheckbox from './BaseCheckbox.vue';

export default defineComponent({
  name: 'BaseArrayCheckbox',
  components: {
    BaseCheckbox,
  },
  props: {

    /**
     * 値を追加する配列
     */
    modelValue: {
      type: Array,
      required: true,
    },

    /**
     * チェックしている場合、配列に追加する値
     */
    item: {
      type: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const checked = computed({
      get() {
        return props.modelValue.indexOf(props.item) !== -1;
      },
      set(state: boolean) {
        // eslint-disable-next-line vue/require-explicit-emits
        emit('update:modelValue', state
          ? [...props.modelValue, props.item]
          : props.modelValue.filter((item) => item !== props.item));
      },
    });

    return {
      checked,
    };
  },
});
