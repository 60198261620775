import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!
  const _component_MessageDialog = _resolveComponent("MessageDialog")!

  return (_openBlock(), _createBlock(_component_MessageDialog, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseStyle, {
        "content-style": _ctx.dialog.style
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialog.contents, (content, index) => {
            return (_openBlock(), _createBlock(_component_Content, {
              key: index,
              content: content
            }, null, 8, ["content"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["content-style"])
    ]),
    _: 1
  }))
}