import { Transform, Type } from 'class-transformer';

import TypeAction from '@/decorators/type_action';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';
import { Medium } from './medium';

export class ContentImageModel extends ContentBase {
  @Type(() => Medium)
  medium?: Medium;

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];

  constructor(init?: Partial<ContentImageModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentImageModel,
  ({ style }) => new ContentImageModel({
    medium: new Medium({ name: 'unique_drink.png' }),
    typeKbn: KbnConst.CONTENT_TYPE_KBN_IMAGE,
    style,
  }),
);
