
import { defineComponent, onMounted, PropType, ref, toRefs } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { useCurrentMedia } from '@/hooks/use_media';
import { useRum } from '@/hooks/use_rum';
import { ActionBase } from '@/models/action_base';
import { Medium } from '@/models/medium';
import { Style } from '@/models/style';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentImage',
  components: { BaseStyle },
  props: {

    /**
     * スタイル
     */
    contentStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

    /**
     * 画像
     */
    medium: {
      type: Object as PropType<Medium>,
      default: undefined,
    },

    /**
     * アクション
     */
    actions: {
      type: Array as PropType<Array<ActionBase>>,
      default: () => [],
    },
  },
  setup(props) {
    const { medium } = toRefs(props);
    const { getAssetsUrl } = useCurrentMedia();
    const execute = useActions(props.actions);
    const { recordButtonClickEvent } = useRum();

    const executing = ref(false);
    async function onClick() {
      // 二度押し防止
      if (executing.value) {
        return;
      }

      recordButtonClickEvent({ imageUrl: url.value });

      executing.value = true;
      setTimeout(() => {
        executing.value = false;
      }, 200);

      await execute();
    }

    const url = ref<string | undefined>(undefined);
    onMounted(async () => {
      url.value = medium.value ? `${ await getAssetsUrl('images', medium.value) }` : undefined;
    });
    return {
      url,
      clickable: props.actions.length > 0,
      onClick,
    };
  },
});
