import { Transform } from 'class-transformer';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TransformUtsToDayjs(target: any, propertyKey: string) {
  Transform(params => params.value && dayjs.unix(params.value), {
    toClassOnly: true,
  })(target, propertyKey);

  Transform(params => params.value && params.value.unix(), {
    toPlainOnly: true,
  })(target, propertyKey);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TransformUtsmsToDayjs(target: any, propertyKey: string) {
  Transform(params => params.value && dayjs(params.value), {
    toClassOnly: true,
  })(target, propertyKey);

  Transform(params => params.value && params.value(), {
    toPlainOnly: true,
  })(target, propertyKey);
}
