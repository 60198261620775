import { OptionItem } from '@/models/option_item';

/**
 * value を options と同じ順序にソートする。
 */
export function sortOptionsValue(value: string[], options: OptionItem[]) {
  const indexByValue: Record<string, number> = {};
  options.forEach((v, i) => indexByValue[v.value] = i);
  return value.sort((a, b) => indexByValue[a] - indexByValue[b]);
}
