import { Transform } from 'class-transformer';
import repeat from 'lodash/repeat';

import TypeAction from '@/decorators/type_action';
import { generateActionRedirect } from '@/hooks/use_action_redirect';
import { Icon } from '@/hooks/use_base_icon';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';
import { withClone } from './model';

export class ContentLinkModel extends ContentBase {
  text?: string;

  underline: boolean = true;

  iconSrc?: Icon;

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];

  constructor(init?: Partial<ContentLinkModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_LINK;
    Object.assign(this, init);
  }

  static generate(patch: Partial<ContentLinkModel> = {}) {
    const instance = new ContentLinkModel({
      text: 'リンク',
      actions: [generateActionRedirect('top')],
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  ContentLinkModel,
  ({ size, style }) => withClone(ContentLinkModel.generate(), {
    text: repeat('リンクの文字列', size),
    actions: [],
    style,
  }),
);
