import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-600 text-white rounded-xl p-6" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-center mt-7 space-x-2"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row justify-center mt-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.message), 1),
    (_ctx.type === 'confirm')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseButton, {
            class: "dialog-button bg-gray-500 w-28 py-1",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(" キャンセル ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_BaseButton, {
            class: "dialog-button bg-pink-600 w-28 py-1",
            onClick: _ctx.ok
          }, {
            default: _withCtx(() => [
              _createTextVNode(" OK ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type === 'alert')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_BaseButton, {
            class: "dialog-button bg-gray-500 min-w-20 py-1 px-16",
            onClick: _ctx.ok
          }, {
            default: _withCtx(() => [
              _createTextVNode(" OK ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}