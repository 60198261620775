import { registerGenerator } from '@/utils/generate';

import { Model } from './model';

export class ResponseCandidate extends Model<ResponseCandidate> {
  uuid!: string;

  name!: string;

  pageName!: string;

  constructor(init?: Pick<ResponseCandidate, 'uuid' | 'name' | 'pageName'>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ResponseCandidate,
  () => new ResponseCandidate({
    uuid: 'ResponseCandidateUuid1',
    name: 'rc1',
    pageName: 'rc1',
  }),
);

