import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentTextEditor = _resolveComponent("ContentTextEditor")!
  const _component_ContentImage = _resolveComponent("ContentImage")!
  const _component_ContentButton = _resolveComponent("ContentButton")!
  const _component_ContentLink = _resolveComponent("ContentLink")!
  const _component_ContentVideo = _resolveComponent("ContentVideo")!
  const _component_ContentFooter = _resolveComponent("ContentFooter")!
  const _component_ContentTrackingId = _resolveComponent("ContentTrackingId")!
  const _component_ContentHorizontalLayout = _resolveComponent("ContentHorizontalLayout")!
  const _component_ContentCheckbox = _resolveComponent("ContentCheckbox")!
  const _component_ContentItemizedList = _resolveComponent("ContentItemizedList")!
  const _component_ContentAccordination = _resolveComponent("ContentAccordination")!
  const _component_ContentQrCodeReading = _resolveComponent("ContentQrCodeReading")!
  const _component_ContentQrCodeShow = _resolveComponent("ContentQrCodeShow")!
  const _component_ContentSurveyInput = _resolveComponent("ContentSurveyInput")!
  const _component_ContentSurveyConfirm = _resolveComponent("ContentSurveyConfirm")!
  const _component_ContentGroup = _resolveComponent("ContentGroup")!
  const _component_ContentReceiptPreview = _resolveComponent("ContentReceiptPreview")!
  const _component_ContentCustomizing = _resolveComponent("ContentCustomizing")!

  return (_ctx.content.withinDisplayPeriod(_ctx.now))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_TEXT_EDITOR)
          ? (_openBlock(), _createBlock(_component_ContentTextEditor, {
              key: 0,
              "content-style": _ctx.contentTextEditor.style,
              "connect-actions": _ctx.contentTextEditor.connectActions,
              html: _ctx.contentTextEditor.text
            }, null, 8, ["content-style", "connect-actions", "html"]))
          : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_IMAGE)
            ? (_openBlock(), _createBlock(_component_ContentImage, {
                key: 1,
                "content-style": _ctx.contentImage.style,
                medium: _ctx.contentImage.medium,
                actions: _ctx.contentImage.actions
              }, null, 8, ["content-style", "medium", "actions"]))
            : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_BUTTON)
              ? (_openBlock(), _createBlock(_component_ContentButton, {
                  key: 2,
                  "content-style": _ctx.content.style,
                  "button-style": _ctx.contentButton.buttonStyle,
                  "disabled-button-style": _ctx.contentButton.disabledButtonStyle,
                  "fixed-on": _ctx.contentButton.fixedOn,
                  actions: _ctx.contentButton.actions,
                  disabled: _ctx.contentButton.disabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.contentButton.text), 1)
                  ]),
                  _: 1
                }, 8, ["content-style", "button-style", "disabled-button-style", "fixed-on", "actions", "disabled"]))
              : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_LINK)
                ? (_openBlock(), _createBlock(_component_ContentLink, {
                    key: 3,
                    "content-style": _ctx.contentLink.style,
                    "icon-src": _ctx.contentLink.iconSrc,
                    underline: _ctx.contentLink.underline,
                    actions: _ctx.contentLink.actions
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.contentLink.text), 1)
                    ]),
                    _: 1
                  }, 8, ["content-style", "icon-src", "underline", "actions"]))
                : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_VIDEO)
                  ? (_openBlock(), _createBlock(_component_ContentVideo, {
                      key: 4,
                      "content-style": _ctx.contentVideo.style,
                      "auto-flag": _ctx.contentVideo.autoFlag,
                      medium: _ctx.contentVideo.medium
                    }, null, 8, ["content-style", "auto-flag", "medium"]))
                  : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_FOOTER)
                    ? (_openBlock(), _createBlock(_component_ContentFooter, {
                        key: 5,
                        "content-style": _ctx.contentFooter.style,
                        links: _ctx.contentFooter.links,
                        "text-style": _ctx.contentFooter.textStyle,
                        text: _ctx.contentFooter.text
                      }, null, 8, ["content-style", "links", "text-style", "text"]))
                    : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_TRACKING_ID)
                      ? (_openBlock(), _createBlock(_component_ContentTrackingId, {
                          key: 6,
                          "content-style": _ctx.contentTrackingId.style
                        }, null, 8, ["content-style"]))
                      : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_HORIZONTAL_LAYOUT)
                        ? (_openBlock(), _createBlock(_component_ContentHorizontalLayout, {
                            key: 7,
                            model: _ctx.contentHorizontalLayoutModel
                          }, null, 8, ["model"]))
                        : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_CHECKBOX)
                          ? (_openBlock(), _createBlock(_component_ContentCheckbox, {
                              key: 8,
                              model: _ctx.contenCheckboxModel
                            }, null, 8, ["model"]))
                          : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_ITEMIZED_LIST)
                            ? (_openBlock(), _createBlock(_component_ContentItemizedList, {
                                key: 9,
                                model: _ctx.contentItemizedList
                              }, null, 8, ["model"]))
                            : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_ACCORDION)
                              ? (_openBlock(), _createBlock(_component_ContentAccordination, {
                                  key: 10,
                                  model: _ctx.contentAccordinationModel
                                }, null, 8, ["model"]))
                              : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_QR_CODE_READING)
                                ? (_openBlock(), _createBlock(_component_ContentQrCodeReading, {
                                    key: 11,
                                    model: _ctx.contentQrCodeReadingModel
                                  }, null, 8, ["model"]))
                                : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_QR_CODE_SHOW)
                                  ? (_openBlock(), _createBlock(_component_ContentQrCodeShow, {
                                      key: 12,
                                      model: _ctx.contentQrCodeShowModel
                                    }, null, 8, ["model"]))
                                  : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_SURVEY_INPUT)
                                    ? (_openBlock(), _createBlock(_component_ContentSurveyInput, {
                                        key: 13,
                                        model: _ctx.contentSurveyInput
                                      }, null, 8, ["model"]))
                                    : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_SURVEY_CONFIRM)
                                      ? (_openBlock(), _createBlock(_component_ContentSurveyConfirm, {
                                          key: 14,
                                          model: _ctx.contentSurveyConfirm
                                        }, null, 8, ["model"]))
                                      : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_GROUP)
                                        ? (_openBlock(), _createBlock(_component_ContentGroup, {
                                            key: 15,
                                            model: _ctx.contentGroup
                                          }, null, 8, ["model"]))
                                        : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_RECEIPT_PREVIEW)
                                          ? (_openBlock(), _createBlock(_component_ContentReceiptPreview, {
                                              key: 16,
                                              model: _ctx.contentReceiptPreview
                                            }, null, 8, ["model"]))
                                          : (_ctx.content.typeKbn === _ctx.$kbnConst.CONTENT_TYPE_KBN_CUSTOMIZING)
                                            ? (_openBlock(), _createBlock(_component_ContentCustomizing, {
                                                key: 17,
                                                "key-name": _ctx.contentCustomizing.keyName
                                              }, null, 8, ["key-name"]))
                                            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}