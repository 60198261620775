import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxOption = _resolveComponent("ListboxOption")!

  return (_openBlock(), _createBlock(_component_ListboxOption, {
    class: "hover:bg-gray-200 cursor-pointer p-2 w-full break-words whitespace-pre-wrap",
    style: {"line-height":"115%"},
    value: _ctx.value
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["value"]))
}