import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    model: _ctx.model,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      (_ctx.opened)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.opened = false))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.openedHeaders, (content, contentIndex) => {
                return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                  _createVNode(_component_Content, { content: content }, null, 8, ["content"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.contents, (content, contentIndex) => {
                return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                  _createVNode(_component_Content, { content: content }, null, 8, ["content"])
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.opened = true))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.closedHeaders, (content, contentIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                _createVNode(_component_Content, { content: content }, null, 8, ["content"])
              ]))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["model"]))
}