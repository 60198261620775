import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-block py-1 px-10 border-current border rounded-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_ctx.trackingId)
    ? (_openBlock(), _createBlock(_component_BaseStyle, {
        key: 0,
        class: "text-center content-base space-y-3"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, " ID：" + _toDisplayString(_ctx.trackingId), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}