import { useRouting } from '@/hooks/use_routing';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

/**
 * 内部遷移する
 *
 */
export class ActionRedirect extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_REDIRECT;

  pageKey?: string;

  use() {
    const routing = useRouting();

    return async () => await execute(this.pageKey, routing);
  }

  constructor(init: Partial<ActionRedirect> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  pageKey: string | undefined,
  routing: ReturnType<typeof useRouting>,
) {
  if (pageKey) {
    await routing.redirectByPageKey(pageKey);
  }
  return false;
}

export function generateActionRedirect(pageKey?: string) {
  return new ActionRedirect({ pageKey });
}
