import { Transform } from 'class-transformer';
import repeat from 'lodash/repeat';

import { HandlebarsRender } from '@/decorators/handlebars_render';
import TypeAction from '@/decorators/type_action';
import TypeStyle from '@/decorators/type_style';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';
import { withClone } from './model';
import { Style, StyleField } from './style';

const defaultStyle: Partial<StyleField> = {
  marginTop: '0',
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0',
};

const buttonDefaultStyle: Partial<StyleField> = {
  color: 'white',
  border: 'solid 4px rgba(255, 255, 255, 0.75)',
  backgroundColor: 'deepPink',
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  marginTop: '0',
  marginLeft: '0',
  marginBottom: '0',
  marginRight: '0',
};

const disabledButtonDefaultStyle: Partial<StyleField> = {
  ...buttonDefaultStyle,
  color: 'white',
  border: 'solid 4px rgba(128, 128, 128, 0.75)',
  backgroundColor: '#808080',
};

export class ContentButtonModel extends ContentBase {
  @HandlebarsRender()
  text?: string;

  @HandlebarsRender()
  fixedOn?: 'top' | 'bottom';

  @HandlebarsRender()
  src?: string;

  @HandlebarsRender()
  disabledSrc?: string;

  @HandlebarsRender()
  width?: string;

  @TypeStyle({ defaultStyle: buttonDefaultStyle })
  buttonStyle: Style = new Style({}, buttonDefaultStyle);

  @TypeStyle({ defaultStyle: disabledButtonDefaultStyle })
  disabledButtonStyle: Style = new Style({}, disabledButtonDefaultStyle);

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];

  constructor(init?: Partial<ContentButtonModel>) {
    super();
    Object.assign(this, init);
  }

  static generate(patch: Partial<ContentButtonModel> = {}) {
    const instance = new ContentButtonModel({
      text: 'ボタンのラベル',
      actions: [],
      buttonStyle: new Style({}, buttonDefaultStyle),
      disabledButtonStyle: new Style({}, disabledButtonDefaultStyle),
      style: new Style({}, defaultStyle),
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  ContentButtonModel,
  ({ size, style }) => new ContentButtonModel({
    text: repeat('ボタンのラベル', size),
    actions: [],
    typeKbn: KbnConst.CONTENT_TYPE_KBN_BUTTON,
    buttonStyle: new Style({
      backgroundColor: 'deepPink',
      color: 'white',
    }),
    disabledButtonStyle: new Style({
      backgroundColor: 'deepPink',
      color: 'white',
    }),
    style,
    src: undefined,
  }),
);

