import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full break-words whitespace-pre-wrap",
  style: {"line-height":"115%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!
  const _component_BaseRadioGroup = _resolveComponent("BaseRadioGroup")!

  return (_openBlock(), _createBlock(_component_BaseRadioGroup, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    class: "flex flex-col"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_BaseRadio, {
          key: item.value,
          class: "px-2 py-1 hover:bg-gray-100",
          value: item.value
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}