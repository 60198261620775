import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["controls", "autoplay", "muted"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "content-base"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, { "content-style": _ctx.contentStyle }, {
    default: _withCtx(() => [
      (_ctx.url)
        ? (_openBlock(), _createElementBlock("video", {
            key: 0,
            class: "w-full",
            controls: !_ctx.autoFlag,
            playsinline: "",
            autoplay: _ctx.autoFlag,
            muted: _ctx.autoFlag
          }, [
            _createElementVNode("source", {
              src: _ctx.url,
              type: "video/mp4"
            }, null, 8, _hoisted_2)
          ], 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, " 動画を再生できません "))
    ]),
    _: 1
  }, 8, ["content-style"]))
}