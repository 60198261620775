import repeat from 'lodash/repeat';

import { registerGenerator } from '@/utils/generate';

import { KbnConst } from './../utils/kbn';
import { ContentBase } from './content_base';

export class ContentGroupModel extends ContentBase {
  groupKey: string = '';

  constructor(init?: Partial<ContentGroupModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_GROUP;
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentGroupModel,
  ({ size, style }) => new ContentGroupModel({
    groupKey: repeat('groupKey', size),
    style,
  }),
);
