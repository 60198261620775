
import { defineComponent } from 'vue';

import { useCommon } from '@/hooks/use_common';
import { Medium } from '@/models/medium';
import { Style } from '@/models/style';

import ContentImage from './ContentImage.vue';

export default defineComponent({
  name: 'ContentPrizes',
  components: {
    ContentImage,
  },
  setup() {
    const { campaignActivity } = useCommon();
    const imageStyle = new Style({
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
    });
    const titleMedium = new Medium({ name: '051006-3type-title.jpg' });
    const sukkiriNormalMedium = new Medium({ name: '051006-3type-sukkiri.jpg' });
    const shittoriNormalMedium = new Medium({ name: '051006-3type-shittori.jpg' });
    const nemotoNormalMedium = new Medium({ name: '051006-3type-premium.jpg' });
    const sukkiriGrayMedium = new Medium({ name: '06-3type-sukkiri-gray.jpg' });
    const shittoriGrayMedium = new Medium({ name: '06-3type-shittori-gray.jpg' });
    const nemotoGrayMedium = new Medium({ name: '06-3type-premium-gray.jpg' });
    return {
      campaignActivity,
      imageStyle,
      titleMedium,
      sukkiriNormalMedium,
      shittoriNormalMedium,
      nemotoNormalMedium,
      sukkiriGrayMedium,
      shittoriGrayMedium,
      nemotoGrayMedium,
    };
  },
});

