import { useRouting } from '@/hooks/use_routing';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

/**
 * 直接アクセスしてきた場合、別のページに遷移させる。後続のアクションは実行しない。
 * vue 上の遷移である場合、何もしない。
 */
export class ActionCheckDirectAccess extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_CHECK_DIRECT_ACCESS;

  pageKey?: string;

  use() {
    const routing = useRouting();

    return async () => await execute(this.pageKey, routing);
  }

  constructor(init: Partial<ActionCheckDirectAccess> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(pageKey: string | undefined, routing: ReturnType<typeof useRouting>) {
  if (routing.isExternal.value) {
    if (pageKey) {
      await routing.redirectByPageKey(pageKey);

    } else {
      await routing.redirectTop();
    }
    return true;
  }
  return false;
}

export function generateActionCheckDirectAccess(pageKey?: string) {
  return new ActionCheckDirectAccess({ pageKey });
}
