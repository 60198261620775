
import { defineComponent, onMounted } from 'vue';

import { useFileUpload } from '@/hooks/use_file_upload';
import { useLoader } from '@/hooks/use_loader';

export default defineComponent({
  name: 'TheGlobalFileInput',
  setup() {
    const { inputElement, fileCache, done, cancel, delayCancel } = useFileUpload();

    onMounted(() => {
      document.body.onfocus = async () => {
        // HACK: 画像選択がキャンセルされたこと伝えてくれる input のイベントがないので、
        // 画面復帰後 3 秒経っても onChange イベントが呼ばれなかったとき、キャンセルさせる
        await delayCancel(3000);
      };
    });

    const loader = useLoader();
    async function onChange(e: Event) {
      const target = e.target as HTMLInputElement;
      const file = (target.files as FileList)[0];
      if (!file) {
        cancel();
        return;
      }

      loader.start();
      try {
        const image = new File([await file.arrayBuffer()],
          file.name,
          {
            type: file.type,
          },
        );
        if (!image) {
          cancel();
          return;
        }

        fileCache.value = image;
        done();

      } finally {
        loader.stop();
      }
    }

    return {
      inputElement,
      onChange,
    };
  },
});
