import { useLoader } from '@/hooks/use_loader';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useApi } from './use_api';
import { useFileUpload } from './use_file_upload';

export class ActionReceiptUpload extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_RECEIPT_UPLOAD;

  use() {

    return async () => await execute();
  }

  constructor(init: Partial<ActionReceiptUpload> = {}) {
    super();
    Object.assign(this, init);
  }
}

export async function execute() {
  const loader = useLoader();
  const api = useApi();
  const { fileCache } = useFileUpload();
  if (!fileCache.value) return true; // ファイルが無い場合はアクション中断

  loader.start();
  try {
    const { presignedUrl, receiptUuid } = (await api.getReceiptUploadUrl()).dataJson;
    await api.uploadFile(presignedUrl, fileCache.value, { 'x-amz-acl': 'public-read' });
    await api.postReceipt(receiptUuid);
    fileCache.value = undefined;
  } finally {
    loader.stop();
  }

  return false;
}
