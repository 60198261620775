import '@/plugin';
import '@/styles/index.scss';

import liff from '@line/liff';

import { campaign } from '@/json/json_loader';
import { LandingPage } from '@/models/landing_page';
import { isMobile } from '@/utils/is_mobile';
import { liffInit } from '@/utils/liff_init';
import { redirectToLine } from '@/utils/redirect_to_line';

type IsFailed = boolean;
const FAILED: IsFailed = true;
const SUCCESS: IsFailed = false;

export async function handleLpTarget(landingPage:LandingPage): Promise<IsFailed> {
  if ((landingPage.isTargetMobile() && !isMobile())
  || (landingPage.isTargetLiffBrowser() && !liff.isInClient())) {
    await redirectToLine(campaign.clientBasicId, campaign.escapeUrl);
    return FAILED;
  }
  if (landingPage.isTargetLiff() && await liffInit()) {
    return FAILED;
  }
  return SUCCESS;
}
