import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentReceiptPreviewModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_RECEIPT_PREVIEW;

  constructor(init?: Partial<ContentReceiptPreviewModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentReceiptPreviewModel,
  () => new ContentReceiptPreviewModel({
    typeKbn: KbnConst.CONTENT_TYPE_KBN_RECEIPT_PREVIEW,
  }),
);
