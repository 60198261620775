
import { defineComponent, PropType, toRefs } from 'vue';

import { useBaseIcon } from '@/hooks/use_base_icon';

export default defineComponent({
  name: 'BaseIcon',
  props: {

    /**
   * 表示するアイコン名
   */
    icon: {
      type: String,
      required: true,
    },

    variant: {
      type: String as PropType<'default' | 'white'>,
      default: 'default',
    },

    /**
   * 大きさ（px）
   */
    size: {
      type: Number,
      default: 16,
    },

    /**
   * 角度（deg）
   */
    rotate: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { icon } = toRefs(props);
    const data = useBaseIcon(icon);

    return {
      data,
    };
  },
});
