import { useCommon } from '@/hooks/use_common';
import { campaign } from '@/json/json_loader';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { generateActionExternalRedirect } from './use_action_external_redirect';

/**
 * カスタマイズアクション
 */
export class ActionCustomizing extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_CUSTOMIZING;

  keyName?: string;

  use() {
    const { getCommon } = useCommon();
    const externalRedirect = generateActionExternalRedirect().use();
    return async () => await execute(this.keyName, getCommon, externalRedirect);
  }

  constructor(init: Partial<ActionCustomizing> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  keyName: string | undefined,
  getCommon: ReturnType<typeof useCommon>['getCommon'],
  externalRedirect: (url?: string) => Promise<boolean>,
) {
  if (keyName === 'get_common') {
    await getCommon();
    return false;
  }
  if (keyName === 'redirect_liff') {
    return await externalRedirect(campaign.liffUrl);
  }

  // キー名を使って好きな処理を実行できる
  alert(`このアクションは未実装（キー名: ${ keyName }）`);
  return false;
}

export function generateActionCustomizing(keyName?: string): ActionCustomizing {
  return new ActionCustomizing({
    keyName,
  });
}
