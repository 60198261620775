import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d815892"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-radio-icon flex-shrink-0 relative m-px" }
const _hoisted_2 = {
  key: 0,
  class: "label flex-grow-0 min-w-0 break-words"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["basic-radio flex items-center space-x-1", [
      _ctx.disabled && 'basic-radio-state-disabled',
      _ctx.isSelected && 'basic-radio-state-selected',
    ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BaseIcon, {
        icon: "checkbox-outer",
        class: "basic-radio-icon-outer",
        size: 16
      }),
      _createVNode(_component_BaseIcon, {
        icon: "checkbox-inner",
        class: "basic-radio-icon-inner absolute inset-0",
        size: 16
      })
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}