
import { defineComponent, onMounted, PropType } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { Dialog } from '@/models/dialog';

import BaseStyle from './BaseStyle.vue';
import Content from './Content.vue';
import MessageDialog from './MessageDialog.vue';

export default defineComponent({
  name: 'JsonBuildDialog',
  components: {
    BaseStyle,
    Content,
    MessageDialog,
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
      required: true,
    },
  },
  setup: (props) => {
    const actions = useActions(props.dialog.actions);

    onMounted(async () => {
      await actions();
    });
  },
});
