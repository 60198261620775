
import { Dialog } from '@headlessui/vue';
import { defineComponent } from 'vue';

import { useLoader } from '@/hooks/use_loader';

import MobileView from './MobileView.vue';

export default defineComponent({
  name: 'BaseDialog',
  components: {
    Dialog,
    MobileView,
  },
  setup() {
    const { loading } = useLoader();
    return {
      loading,
    };
  },
});
