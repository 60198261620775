
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SurveyInputText',
  props: {

    /**
     * 入力した文字
     */
    modelValue: {
      type: String,
      default: undefined,
    },

    /**
     * 最大文字数
     */
    max: {
      type: Number,
      default: 0,
    },

    /**
     * プレースホルダ
     */
    placeholder: {
      type: String,
      default: '入力してください',
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(prop) {
    const text = useVModel(prop, 'modelValue');

    return {
      text,
    };
  },
});
