
import { computed, defineComponent, PropType } from 'vue';

import { useFileUpload } from '@/hooks/use_file_upload';
import { Style } from '@/models/style';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentReceiptPreview',
  components: { BaseStyle },
  props: {

    /**
     * 画像
     */
    src: {
      type: String,
      required: false,
      default: undefined,
    },

    /**
     * スタイル
     */
    contentStyle: {
      type: Object as PropType<Style>,
      default: undefined,
    },

  },
  setup() {
    const { fileCache } = useFileUpload();
    const url = computed(() => fileCache.value ? URL.createObjectURL(fileCache.value) : '');

    return {
      url,
    };
  },
});
