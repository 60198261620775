import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    "content-style": _ctx.contentStyle,
    class: "content-base flex items-center"
  }, {
    default: _withCtx(() => [
      (_ctx.iconSrc)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            class: "mr-2",
            icon: _ctx.iconSrc,
            size: 24
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: _normalizeClass([
        'whitespace-pre-wrap break-all cursor-pointer flex-grow',
        _ctx.underline ? 'underline' : 'no-underline'
      ]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["content-style"]))
}