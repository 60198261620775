import { Transform } from 'class-transformer';

export class SurveyAnswerItemContentMultiSelect {

  @Transform((params) => params.value || [])
  values: string[] = [];

  @Transform((params) => params.value || [])
  uuids: (string | null)[] = [];

  constructor(init?: Partial<SurveyAnswerItemContentMultiSelect>) {
    Object.assign(this, init);
  }
}
