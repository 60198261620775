export const RESULT_CODE = Object.freeze({
  SUCCESS: '00101', //成功
  NOT_FRIEND: '00102', //友だちではない
  NOT_EXIST: '00104', //存在しない
  OTHER: '00105', //その他のエラー
  NOT_LOGGED_IN: '00106', //ログインしていない
  DATABASE_ERROR: '00107', //データベースエラー
  OFF_HOUR: '00108', //キャンペーンが実施期間外
  ALREADY_ENTRIED: '00109', //既に参加済み
  BLOCKED: '00110', //ブロック状態
  TWITTER_ACCOUNT_DUPULICATE: '00111', //twitter アカウントが既に使われている
  INVALID_CALLBACK_PARAMETER: '00112', //不正なコールバックパラメータ
});

export const RECEIPT_RESULT_CODE = Object.freeze({
  SUCCESS: '00101', //成功
  SYSTEM_ERROR: '00102', //システムエラー
  INVALID_PARAMETERS: '00103', //パラメータが不正
  NOT_EXIST: '00104', //リソースが存在しない
  RUNNING: '00105', //解析中
});
