import { v4 as uuidv4 } from 'uuid';

import TypeStyle from '@/decorators/type_style';
import { withClone } from '@/models/model';
import { Style } from '@/models/style';
import { registerGenerator } from '@/utils/generate';

export class StyleTemplate {
  uniqueKey: string = uuidv4();

  @TypeStyle()
  style?: Style;

  constructor(init?: Partial<StyleTemplate>) {
    Object.assign(this, init);
  }

  static generate(patch: Partial<StyleTemplate> = {}) {
    const instance = new StyleTemplate({
      style: new Style({
        backgroundColor: '#5F5F5F',
      }),
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  StyleTemplate,
  (_) => StyleTemplate.generate(),
);
