import { Transform } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

import { registerGenerator } from '@/utils/generate';

import { Model } from './model';

export class PointHistory extends Model<PointHistory> {
  entryUuid!: string;

  label!: string;

  responseCandidateUuid!: string;

  @Transform((params) => params.value && dayjs(params.value))
  entriedAt!: Dayjs;

  point!: number;

  constructor(init?: Partial<PointHistory>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  PointHistory,
  (_props) => new PointHistory({
    entryUuid: 'entryUuid000-000-0',
    label: 'ラベル',
    responseCandidateUuid: 'responseCandidateUuid111-111-1',
    point: 10,
    entriedAt: dayjs(1661904000), // 2022-08-31
  }),
);
