import repeat from 'lodash/repeat';

import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentItemizedListModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_ITEMIZED_LIST;

  markerText: string = '';

  listItems: string[] = [];

  constructor(init?: Partial<ContentItemizedListModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentItemizedListModel,
  ({ size, style }) => new ContentItemizedListModel({
    markerText: repeat('＊', size),
    style,
    listItems: [
      'ITEM 1',
      'ITEM 2',
      'ITEM 3',
      'ITEM 4',
    ],
  }),
);
