import { inject, InjectionKey, Ref } from 'vue';

interface BasicRadioContext<T> {
  selection: Ref<T>
}

export const BaseRadioContextInjectionKey: InjectionKey<BasicRadioContext<unknown>> = Symbol('base-radio-context');

/**
 * `basic-radio`の状態を使う。
 *
 * @returns 今入っている文脈のradioの状態
 */
export function useBaseRadioContext<T>(): BasicRadioContext<T> {
  const context = inject<BasicRadioContext<T>>(BaseRadioContextInjectionKey);

  if (!context) {
    throw new Error('`useBaseRadioContext`があるが、親はBaseRadioではない。');
  }

  return context;
}
