import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-2 bg-white text-black survey-style" }
const _hoisted_2 = { class: "px-2 pb-2" }
const _hoisted_3 = { class: "whitespace-pre-wrap break-all mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyHeader = _resolveComponent("SurveyHeader")!
  const _component_SurveyErrorText = _resolveComponent("SurveyErrorText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.uuid,
        class: "mt-6"
      }, [
        _createVNode(_component_SurveyHeader, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.questionContent), 1)
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getAnswer(item) || ' '), 1),
          _createVNode(_component_SurveyErrorText, {
            text: _ctx.errors[item.uuid]
          }, null, 8, ["text"])
        ])
      ]))
    }), 128))
  ]))
}