
import { defineComponent } from 'vue';

import ContentPrizes from './ContentPrizes.vue';

export default defineComponent({
  name: 'ContentCustomizing',
  components: {
    ContentPrizes,
  },
  props: {

    /**
     * キー名
     */
    keyName: {
      type: String,
      required: true,
    },
  },
});
