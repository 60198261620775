
import { defineComponent, PropType } from 'vue';

import { ContentItemizedListModel } from '@/models/content_itemized_list_model';

import BaseStyle from './BaseStyle.vue';
export default defineComponent({
  name: 'ContentItemizedList',
  components: {
    BaseStyle,
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentItemizedListModel>,
      required: true,
    },
  },
});
