import { SendMessagesParams } from '@liff/send-messages';
import { Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

import { TransformUtsToDayjs  } from '@/decorators/transform_number_to_dayjs';
import { generate, registerGenerator } from '@/utils/generate';

import { LinePayDialogPropaty } from './line_pay_dialog_propaty';
import { Model } from './model';
import { ResponseCandidate } from './response_candidate';
import { RumConfig } from './rum_config';

export const campaignSchema = z.object({
  campaignUuid: z.string(),
  campaignStartUts: z.number(),
  campaignEndUts: z.number(),
  googleTagManagerIds: z.string().array(),
  responseCandidates: z.object({
    uuid: z.string(),
    name: z.string(),
    pageName: z.string(),
  }).array(),
  liffId: z.string(),
  clientBasicId: z.string(),
  isOnlyOpenid: z.boolean(),
  receiptAiEndpointUri: z.string(),
  receiptAiCampaignUuid: z.string(),
  linePayEndpointUri: z.string(),
  escapeUrl: z.string(),
  liffSendMessages: z.object({}).array(),
  requestPermissions: z.string().array(),
  rumConfig: z.object({
    applicationId: z.string(),
    guestRoleArn: z.string(),
    identityPoolId: z.string(),
  }).optional(),
});

export type CampaignType = z.infer<typeof campaignSchema>;
export type PermissionType =
  | 'friend' // 友だちチェック
  | 'chat_message_write' // LINE message write権限チェック
  | 'user_program_content' // ユーザープログラムコンテントチェック
;
const defaultRequestPermissions: PermissionType[] = [
  'friend',
];

export class Campaign extends Model<CampaignType> {
  campaignUuid!: string;

  @TransformUtsToDayjs
  campaignStartUts!: Dayjs;

  @TransformUtsToDayjs
  campaignEndUts!: Dayjs;

  @Transform((params) => params.value || [])
  googleTagManagerIds: string[] = [];

  @Type(() => ResponseCandidate)
  @Transform((params) => params.value || [])
  responseCandidates: ResponseCandidate[] = [];

  // LIFF設定
  liffId = '';

  // LINEのボットのベーシックID
  clientBasicId = '';

  // LINEログインチャネルのスコープをopenidだけに設定したい場合に利用する
  isOnlyOpenid = false;

  // レシートAI設定
  receiptAiEndpointUri = ''; // example: https://impala.receipt.beluga.tokyo/api/v1

  receiptAiCampaignUuid = ''; // example: cc43adfa-6e9c-4145-9d8e-a97a9ffd2ffc

  // LINE Pay設定
  linePayEndpointUri = ''; // example: https://yesksvgabf.execute-api.ap-northeast-1.amazonaws.com/api/

  // 退避用URL。アプリ立ち上げのような操作を行って、元のブラウザに画面が残るのが困る時に使う
  escapeUrl = '';

  // LP からアクセスできる LIFF の URL
  liffUrl = '';

  // LiffSendMessages
  @Transform((params) => params.value || [])
  liffSendMessages: SendMessagesParams = []; // SendMessagesParamsはクラスじゃないので@Typeは不要

  @Transform((params) => params.value || defaultRequestPermissions)
  requestPermissions: PermissionType[] = defaultRequestPermissions;

  // line pay文言設定追加
  linePayDialogPropaty = new LinePayDialogPropaty();

  // RUM 設定
  rumConfig?: RumConfig;

  constructor(init?: Partial<Campaign>) {
    super();
    Object.assign(this, init);
  }

  hasRequestPermission(permission: PermissionType) {
    return this.requestPermissions.includes(permission);
  }
}

registerGenerator(
  Campaign,
  (props) => new Campaign({
    campaignUuid: '0',
    campaignStartUts: dayjs(props.now),
    campaignEndUts: dayjs(props.now),
    responseCandidates: [
      generate(ResponseCandidate, props),
    ],
  }),
);
