import { ref } from 'vue-demi';

import { useApi } from '@/hooks/use_api';
import { useLoader } from '@/hooks/use_loader';
import { CampaignActivity } from '@/models/campaign_activity';

const campaignActivity = ref<CampaignActivity>();

/**
 * キャンペーン情報取得
 * @returns
 */
export function useCommon() {
  const loader = useLoader();
  const api = useApi();
  async function getCommon() {
    loader.start();
    try {
      const response = await api.getCommon();
      campaignActivity.value = response.dataJson;
    } finally {
      loader.stop();
    }
  }

  return {
    campaignActivity,
    getCommon,
  };
}
