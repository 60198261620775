import { Ref, ref } from 'vue';

import { useInjectLandingPage } from '@/hooks/use_landing_page';
import { Dialog } from '@/models/dialog';
import { LandingPage } from '@/models/landing_page';

const dialogs: Ref<Dialog[]> = ref([]);

export function useDialog(
  mockLp?: LandingPage, // テスト用に差し替えられるようにする
) {
  const lp = useInjectLandingPage();
  const landingPage = mockLp ? mockLp : lp;

  const addDialog = (dialogKey: string) => {
    const dialog = landingPage.dialogs.find(item => item.uniqueKey === dialogKey);
    if (!dialog) {
      throw new Error(`Dialog not found: ${dialogKey}`);
    }
    dialogs.value.push(dialog);
  };

  const removeDialog = (dialogKey: string) => {
    const index = dialogs.value.findIndex(item => item.uniqueKey === dialogKey);
    if (index >= 0) {
      dialogs.value.splice(index, 1);
    }
  };

  const removeAllDialog = () => {
    dialogs.value = [];
  };

  const context = {
    dialogs,
    addDialog,
    removeDialog,
    removeAllDialog,
  };

  return context;
}
