import { useDialog } from '@/hooks/use_dialog';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

/**
 * ダイアログ表示
 */
export class ActionDialogShow extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_DIALOG_CLOSE;

  dialogKey?: string;

  use() {
    const { addDialog } = useDialog();

    return async () => await execute(this.dialogKey, addDialog);
  }

  constructor(init: Partial<ActionDialogShow> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  dialogKey: string | undefined,
  addDialog: ReturnType<typeof useDialog>['addDialog'],
) {
  if (dialogKey) {
    addDialog(dialogKey);
  }
  return false;
}

export function generateActionDialogShow(dialogKey?: string): ActionDialogShow {
  return new ActionDialogShow({
    dialogKey,
  });
}
