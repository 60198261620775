import { Transform } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';

import TypeAction from '@/decorators/type_action';
import TypeContent from '@/decorators/type_content';
import TypeStyle from '@/decorators/type_style';
import { ActionBase } from '@/models/action_base';
import { ContentFooterModel } from '@/models/content_footer_model';
import { ContentTrackingIdModel } from '@/models/content_tracking_id_model';
import { generate, registerGenerator } from '@/utils/generate';

import { ContentBase } from './content_base';
import { ContentButtonModel } from './content_button_model';
import { ContentImageModel } from './content_image_model';
import { ContentLinkModel } from './content_link_model';
import { ContentTextEditorModel } from './content_text_editor_model';
import { ContentVideoModel } from './content_video_model';
import { Model } from './model';
import { Style } from './style';

export class Dialog extends Model<Dialog> {
  uniqueKey: string = uuidv4();

  @TypeStyle()
  style?: Style;

  @TypeContent()
  @Transform((params) => params.value || [])
  contents: ContentBase[] = [];

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];

  constructor(init?: Partial<Dialog>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  Dialog,
  (props) => new Dialog({
    style: new Style({
      backgroundColor: '#5F5F5F',
      color: 'white',
    }),
    contents: [
      generate(ContentImageModel, props),
      generate(ContentTextEditorModel, props),
      generate(ContentButtonModel, props),
      generate(ContentVideoModel, props),
      generate(ContentLinkModel, props),
      generate(ContentFooterModel, props),
      generate(ContentTrackingIdModel, props),
    ],
    actions: [],
  }),
);
