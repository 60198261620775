import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13e14fb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!
  const _component_LoadingLayer = _resolveComponent("LoadingLayer")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TheGlobalDialogs = _resolveComponent("TheGlobalDialogs")!
  const _component_TheGlobalFileInput = _resolveComponent("TheGlobalFileInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseStyle, {
      class: "min-h-screen w-full flex flex-col items-center",
      "content-style": _ctx.renderedPage.style
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseStyle, {
          class: "min-h-screen max-w-md w-full flex flex-col items-center justify-between",
          "content-style": _ctx.renderedPage.bodyStyle
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.page.headers.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.page.headerSticky && 'sticky top-0 z-20')
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedPage.headers, (content, index) => {
                      return (_openBlock(), _createBlock(_component_Content, {
                        key: index,
                        content: content
                      }, null, 8, ["content"]))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedPage.contents, (content, index) => {
                return (_openBlock(), _createBlock(_component_Content, {
                  key: index,
                  content: content
                }, null, 8, ["content"]))
              }), 128))
            ]),
            (_ctx.page.footers.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["w-full", _ctx.page.footerSticky && 'sticky bottom-0 z-20'])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedPage.footers, (content, index) => {
                    return (_openBlock(), _createBlock(_component_Content, {
                      key: index,
                      content: content
                    }, null, 8, ["content"]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["content-style"])
      ]),
      _: 1
    }, 8, ["content-style"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingLayer, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog),
    _createVNode(_component_TheGlobalDialogs),
    _createVNode(_component_TheGlobalFileInput)
  ], 64))
}