import dayjs from 'dayjs';

// ローカル環境の設定値
const CONFIG_ENV_DEV = {
  liffId: '#{LIFF_ID}',
  campaignUuid: 'b14299a4-027e-42ed-8b61-ec95a6443952',
  campaignStartAt: dayjs.tz('2021-11-16 00:00:00'), // キャンペーン開始
  campaignEndAt: dayjs.tz('2021-11-29 00:00:00'), // キャンペーン終了

  // オプション的設定
  clientBasicId: '', // LINEのクライアントベーシックID
  lineLoginState: '', // api_3.4用。line loginのstate値 任意の文字列
  uploadUrl: '', //'https://execute-api.ap-northeast-1.example.com/prod',
};

type CONFIG_ENV_TYPE = typeof CONFIG_ENV_DEV;

// ステージング環境の設定値
const CONFIG_ENV_STAGING: CONFIG_ENV_TYPE = {
  liffId: '',
  campaignUuid: '',
  campaignStartAt: dayjs.tz('2021-11-16 00:00:00'), // キャンペーン開始
  campaignEndAt: dayjs.tz('2021-11-29 00:00:00'), // キャンペーン終了

  // オプション的設定
  clientBasicId: '', // LINEのクライアントベーシックID
  lineLoginState: '', // api_3.4用。line loginのstate値 任意の文字列
  uploadUrl: '', //'https://execute-api.ap-northeast-1.example.com/prod',
};

// 本番環境の設定値
const CONFIG_ENV_PROD: CONFIG_ENV_TYPE = {
  liffId: '',
  campaignUuid: '',
  campaignStartAt: dayjs.tz('2021-11-16 00:00:00'), // キャンペーン開始
  campaignEndAt: dayjs.tz('2021-11-29 00:00:00'), // キャンペーン終了

  // オプション的設定
  clientBasicId: '', // LINEのクライアントベーシックID
  lineLoginState: '', // api_3.4用。line loginのstate値 任意の文字列
  uploadUrl: '', //'https://execute-api.ap-northeast-1.example.com/prod',
};

// どの環境でも共通の設定値
const CONFIG_ENV_COMMON = {

  defaultTitle: '#{DEFAULT_TITLE}',

  // ポーリング設定
  getEntryPollingRetryCount: 10,
  getEntryPollingIntervalMilliSecond: 1000,
  getReceiptPollingRetryCount: 60,
  getReceiptPollingIntervalMilliSecond: 1000,

  // 以下基本的に変わらない定数
  lineOauthKeyCode: 'code', // line Oauth認証のquerystring code
  lineOauthKeyError: 'error', // line Oauth認証のquerystring error
};

const CONFIG_ENV: Record<string, CONFIG_ENV_TYPE> = {
  undefined: CONFIG_ENV_DEV, // ローカル実行 yarn serve時
  staging: CONFIG_ENV_STAGING,
  prod: CONFIG_ENV_PROD,
};

// 環境変数 VUE_APP_ENV によって config を出しわける
export const CONFIG = Object.freeze({
  ...CONFIG_ENV[`${process.env.VUE_APP_ENV}`],
  ...CONFIG_ENV_COMMON,
});
