import { toRefs } from '@vueuse/core';
import noop from 'lodash/noop';
import { ref } from 'vue';

export type PopupDialogType = 'alert' | 'confirm' | 'linePayAgree' | 'linePayResult';

type StateType = {
  message: string | undefined; // ダイアログに表示するメッセージ
  open: boolean; // ダイアログオープンフラグ
  type: PopupDialogType; // ダイアログの種類
  resolve: (value: boolean) => void; // Promise完了用
};

const state = ref<StateType>({
  type: 'alert',
  message: undefined,
  open: false,
  resolve: noop,
});

export function usePopupDialog() {
  const { open, message, type } = toRefs(state);

  /**
   * 使い方
   * const dialog = usePopupDialog();
   * await dialog.alert('アラートを表示');
   * @param message
   * @returns
   */
  function alert(message?: string) {
    state.value.resolve(false); // 先に呼ばれていたPromiseを完了させる
    state.value.type = 'alert';
    state.value.message = message;
    state.value.open = true;
    return new Promise<boolean>((resolve) => state.value.resolve = resolve);
  }

  /**
   * 使い方
   * const dialog = usePopupDialog();
   * if (await dialog.confirm('Confirmを表示する')) func();
   * @param message
   * @returns
   */
  function confirm(message?: string) {
    state.value.resolve(false); // 先に呼ばれていたPromiseを完了させる
    state.value.type = 'confirm';
    state.value.message = message;
    state.value.open = true;
    return new Promise<boolean>((resolve) => state.value.resolve = resolve);
  }

  /**
   * 使い方
   * const dialog = usePopupDialog();
   * if (await dialog.linePayAgree()) func();
   * @param message
   * @returns
   */
  function linePayAgree(message?: string) {
    state.value.resolve(false); // 先に呼ばれていたPromiseを完了させる
    state.value.type = 'linePayAgree';
    state.value.message = message;
    state.value.open = true;
    return new Promise<boolean>((resolve) => state.value.resolve = resolve);
  }

  /**
   * 使い方
   * const dialog = usePopupDialog();
   * if (await dialog.linePayResult()) func();
   * @param message
   * @returns
   */
  function linePayResult(message?: string) {
    state.value.resolve(false); // 先に呼ばれていたPromiseを完了させる
    state.value.type = 'linePayResult';
    state.value.message = message;
    state.value.open = true;
    return new Promise<boolean>((resolve) => state.value.resolve = resolve);
  }

  // ダイアログのOKボタンのクリック処理として呼び出す
  function ok() {
    state.value.open = false;
    state.value.resolve(true); // alert、confirmを完了する
    state.value.resolve = noop;
  }

  // ダイアログのキャンセルボタンのクリック処理として呼び出す
  function cancel() {
    state.value.open = false;
    state.value.resolve(false); // alert、confirmを完了する
    state.value.resolve = noop;
  }

  return {
    message,
    open,
    type,
    alert,
    confirm,
    linePayAgree,
    linePayResult,
    ok,
    cancel,
  };
}
