import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e9272b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  class: "content-text-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, { class: "text-left content-base" }, {
    default: _withCtx(() => [
      (_ctx.fixedHtml)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            ref: "editor",
            class: "content-text-content",
            innerHTML: _ctx.fixedHtml
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
    ]),
    _: 3
  }))
}