import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-around w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    "content-style": _ctx.contentStyle,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_Content, { content: link }, null, 8, ["content"])
          ]))
        }), 128))
      ]),
      _createVNode(_component_BaseStyle, {
        "content-style": _ctx.textStyle,
        class: "whitespace-pre-wrap break-all"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        _: 1
      }, 8, ["content-style"])
    ]),
    _: 1
  }, 8, ["content-style"]))
}