/* eslint-disable @typescript-eslint/ban-types */

interface HandlebarsRenderMetadata {
  target: Function;
  propertyName: string;
}

// デコレータが付与されたプロパティ情報を保持する class-transformaer参考
class HandlebarsRenderStorage {
  private _handlebarsRenderMetadatas = new Map<Function, Map<string, HandlebarsRenderMetadata>>();

  addHandlebarsRenderMetadata(metadata: HandlebarsRenderMetadata): void {
    if (!this._handlebarsRenderMetadatas.has(metadata.target)) {
      this._handlebarsRenderMetadatas.set(metadata.target, new Map<string, HandlebarsRenderMetadata>());
    }
    this._handlebarsRenderMetadatas.get(metadata.target)!.set(metadata.propertyName, metadata);
  }

  hasHandlebarsRenderMetadata(target: Function, propertyName: string): boolean {
    return !!(this._handlebarsRenderMetadatas.get(target)?.has(propertyName));
  }
}

export const handlebarsRenderStorage = new HandlebarsRenderStorage();

/**
 * Handlebarsレンダリング対象に付与するプロパティデコレーター
 * 付与されたクラスプロパティはHandlebarsRenderStorageに記録され、useHandlebarsRenderによるレンダリングの際に参照する
 * @returns
 */
export function HandlebarsRender(): PropertyDecorator {
  return (target, propertyName) => {
    handlebarsRenderStorage.addHandlebarsRenderMetadata({
      target: target.constructor,
      propertyName: propertyName as string,
    });
  };
}
