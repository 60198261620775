import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    "content-style": _ctx.model.style,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-wrap w-full", [
        _ctx.model.alignItems === 'TOP' && 'items-start',
        _ctx.model.alignItems === 'CENTER' && 'items-center',
        _ctx.model.alignItems === 'BOTTOM' && 'items-end',
      ]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.items, (horizontalItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            style: _normalizeStyle(horizontalItem.width ? {
          width: horizontalItem.width
        } : {})
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(horizontalItem.contents, (content, contentIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                _createVNode(_component_Content, { content: content }, null, 8, ["content"])
              ]))
            }), 128))
          ], 4))
        }), 128))
      ], 2)
    ]),
    _: 1
  }, 8, ["content-style"]))
}