import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useApi } from './use_api';
import { useInsertDataStore } from './use_insert_data_store';
import { useLoader } from './use_loader';
import { usePopupDialog } from './use_popup_dialog';

export class ActionLoginCheckin extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_LOGIN_CHECKIN;

  use() {
    const api = useApi();
    const loader = useLoader();
    const insertDataStore = useInsertDataStore();
    const popup = usePopupDialog();
    return async () => await execute(
      api,
      loader,
      insertDataStore,
      popup,
    );
  }

  constructor(init: Partial<ActionLoginCheckin> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  api: ReturnType<typeof useApi>,
  loader: ReturnType<typeof useLoader>,
  insertDataStore: ReturnType<typeof useInsertDataStore>,
  popup: ReturnType<typeof usePopupDialog>,
) {

  const qrCode = insertDataStore.qrCode.value;
  if (qrCode) {
    try {
      await api.postCheckinLogin({
        checkinToken: qrCode,
      });

      // qrCodeをクリアします。
      insertDataStore.qrCode.value = '';
      loader.stop(); // エラー時の先にストップさせたいためfinallyでは使えない
    } catch (error) {
      loader.stop(); // finallyでストップするとローディングとダイアログが同時に出てしまうので先にストップする
      await popup.alert('不正なQRコードです。');
      return true; // アップロードに失敗したときもアクション中断
    }
  } else {
    await popup.alert('QRコード情報がありません。');
    return true; // アップロードに失敗したときもアクション中断
  }

  return false;
}

export function generateActionLoginCheckin() {
  return new ActionLoginCheckin();
}
