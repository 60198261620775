import repeat from 'lodash/repeat';

import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';
import { withClone } from './model';

export class ContentCustomizingModel extends ContentBase {
  keyName: string = '';

  constructor(init?: Partial<ContentCustomizingModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_CUSTOMIZING;
    Object.assign(this, init);
  }

  static generate(patch: Partial<ContentCustomizingModel> = {}) {
    const instance = new ContentCustomizingModel({
      keyName: 'key_name',
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  ContentCustomizingModel,
  ({ size }) => withClone(ContentCustomizingModel.generate(), {
    keyName: repeat('key_name', size),
  }),
);
