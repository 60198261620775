import { Type } from 'class-transformer';

import { ActionBase } from '@/models/action_base';
import { Medium } from '@/models/medium';
import { KbnConst } from '@/utils/kbn';

import { useCurrentMedia } from './use_media';

export class ActionPlaySound extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_PLAY_SOUND;

  @Type(() => Medium)
  sound?: Medium;

  use() {
    const currentMedia = useCurrentMedia();
    return async () => await execute(currentMedia, this.sound);
  }

  constructor(init: Partial<ActionPlaySound> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(currentMedia: ReturnType<typeof useCurrentMedia>, soundMedium?: Medium) {
  if (soundMedium) {
    const audioUrl = await currentMedia.getAssetsUrl('audios', soundMedium);
    const audio = new Audio(audioUrl);
    audio.play();
  }

  // CMS のプレビュー上では何も処理を実行しない
  return false;
}

export function generateActionPlaySound() {
  return new ActionPlaySound({
    sound: new Medium({
      name: 'sample_audio.mp3',
    }),
  });
}
