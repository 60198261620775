
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { OptionItem } from '@/models/option_item';

import BaseRadio from './BaseRadio.vue';
import BaseRadioGroup from './BaseRadioGroup.vue';

export default defineComponent({
  name: 'SurveyInputRadio',
  components: {
    BaseRadioGroup,
    BaseRadio,
  },
  props: {

    /**
     *  選択されている値のリスト
     */
    modelValue: {
      type: String,
      default: undefined,
    },

    /**
     *  選択肢のリスト
     */
    options: {
      type: Array as PropType<OptionItem[]>,
      default: () => [],
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const value = useVModel(props, 'modelValue', emit);
    return {
      value,
    };
  },
});
