import { Transform, Type } from 'class-transformer';

import { SurveyAnswerItem } from './survey_answer_item';

export class SurveyAnswer {
  patternUuid?: string;

  patternName?: string;

  surveyUuid?: string;

  @Type(() => SurveyAnswerItem)
  @Transform((params) => params.value || [])
  items?: SurveyAnswerItem[];

  constructor(init?: Partial<SurveyAnswer>) {
    Object.assign(this, init);
  }
}
