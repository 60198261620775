
import { defineComponent, PropType, toRefs } from 'vue';

import LoadingLayer from '@/components/LoadingLayer.vue';
import { useHandlebarsRender } from '@/hooks/use_handlebars_render';
import { useLoader } from '@/hooks/use_loader';
import { Page } from '@/models/page';

import BaseStyle from './BaseStyle.vue';
import Content from './Content.vue';
import Dialog from './Dialog.vue';
import TheGlobalDialogs from './TheGlobalDialogs.vue';
import TheGlobalFileInput from './TheGlobalFileInput.vue';

export default defineComponent({
  name: 'CommonPage',
  components: {
    BaseStyle,
    Content,
    LoadingLayer,
    Dialog,
    TheGlobalDialogs,
    TheGlobalFileInput,
  },
  props: {
    page: {
      type: Object as PropType<Page>,
      required: true,
    },
  },
  setup(props) {
    const { page } = toRefs(props);
    const { loading } = useLoader();
    const renderedPage = useHandlebarsRender(page);
    return {
      loading,
      renderedPage,
    };
  },
});
