import { useRouter } from 'vue-router';

import { useInjectLandingPage } from '@/hooks/use_landing_page';

export function useCurrentPage() {
  const router = useRouter();
  const landingPage = useInjectLandingPage();

  const getCurrentPage = () => {
    const page = landingPage.pages.find((page) => page.path === router.currentRoute.value.name);
    return page;
  };

  return {
    getCurrentPage,
  };
}
