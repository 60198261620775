import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "bg-white z-10 w-56" }
const _hoisted_4 = { class: "flex flex-col overflow-auto max-h-80" }
const _hoisted_5 = {
  class: "w-full break-words whitespace-pre-wrap",
  style: {"line-height":"115%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_BaseArrayCheckbox = _resolveComponent("BaseArrayCheckbox")!
  const _component_DialogDescription = _resolveComponent("DialogDescription")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedLabels)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selectedLabels), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "w-full border border-gray-500 p-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true))
    }, " 選択してください "),
    _createVNode(_component_BaseDialog, {
      open: _ctx.isOpen,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpen = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_DialogTitle, { class: "mx-1 px-1 py-2 border-b border-gray-400" }, {
            default: _withCtx(() => [
              _createTextVNode(" 選択してください ")
            ]),
            _: 1
          }),
          _createVNode(_component_DialogDescription, { class: "py-1" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                  return (_openBlock(), _createBlock(_component_BaseArrayCheckbox, {
                    key: index,
                    modelValue: _ctx.selected,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
                    item: option.value,
                    disabled: _ctx.isDisabled(option.value),
                    class: _normalizeClass([
                'px-2 py-1',
                !_ctx.isDisabled(option.value) && 'hover:bg-gray-100'
              ])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(option.label || ' '), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "item", "disabled", "class"]))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createElementVNode("button", {
            class: "bg-gray-900 text-white p-2 w-full",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = false))
          }, " 戻る ")
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}