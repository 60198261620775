import { registerGenerator } from '@/utils/generate';

export class Medium {
  name!: string;

  constructor(init: Partial<Medium>) {
    Object.assign(this, init);
  }
}

registerGenerator(
  Medium,
  () => new Medium({
    name: 'Medium',
  }),
);

export class MediumUrl {
  url!: string;

  constructor(init: Partial<MediumUrl>) {
    Object.assign(this, init);
  }
}
