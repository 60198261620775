import { inject, InjectionKey, provide } from 'vue';

import { LandingPage } from '@/models/landing_page';

export const landingPageInjectionKey: InjectionKey<LandingPage> = Symbol('LandingPage');

export function useProvideLandingPage(value: LandingPage)  {
  provide(landingPageInjectionKey, value);
  return value;
}

export function useInjectLandingPage() {
  const landingPage = inject(landingPageInjectionKey);
  if (!landingPage) {
    throw new Error('LandingPage がありません。');
  }

  return landingPage;
}
