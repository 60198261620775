
import { useVModel } from '@vueuse/core';
import { computed, defineComponent, PropType, Ref, toRefs } from 'vue';

import { OptionItem } from '@/models/option_item';
import { SurveyPatternItem } from '@/models/survey_pattern_item';

import SurveyInputBirthday from './SurveyInputBirthday.vue';
import SurveyInputCheckbox from './SurveyInputCheckbox.vue';
import SurveyInputMultiSelect from './SurveyInputMultiSelect.vue';
import SurveyInputRadio from './SurveyInputRadio.vue';
import SurveyInputSelect from './SurveyInputSelect.vue';
import SurveyInputText from './SurveyInputText.vue';
import SurveyInputTextarea from './SurveyInputTextarea.vue';

export default defineComponent({
  name: 'SurveyInput',
  components: {
    SurveyInputBirthday,
    SurveyInputText,
    SurveyInputTextarea,
    SurveyInputMultiSelect,
    SurveyInputRadio,
    SurveyInputCheckbox,
    SurveyInputSelect,
  },
  props: {
    modelValue: {
      type: null,
      default: undefined,
    },
    item: {
      type: Object as PropType<SurveyPatternItem>,
      required: true,
    },

    /**
     * 備考
     */
    remarks: {
      type: String,
      default: undefined,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = useVModel(props, 'modelValue', emit) as Ref<any>;
    return {
      value,
      options: computed(() =>
        item.value.contentJson.options.map(
          (surveyOption) => new OptionItem(surveyOption.value, surveyOption.value),
        ),
      ),
    };
  },
});
