import { inject, InjectionKey, provide } from 'vue';

import { Medium } from '@/models/medium';

import { debugLog } from '../utils/debug';
import { isCannotFindModule } from '../utils/is_cannot_find_module';

type MediumType = 'images' | 'videos' | 'audios'; // assetsフォルダ下のディレクトリ名

const injectKey: InjectionKey<ReturnType<typeof useMedia>> = Symbol('Media');

export const defaultMediumList = [
  '150x150.png',
  '150x400.png',
  '400x150.png',
  '1000x1000.png',
  'ads.png',
  'avatar.png',
  'background.jpg',
  'body_background.png',
  'coin.png',
  'eye_catch.png',
  'gift.png',
  'sign_out.png',
  'icon_3.png',
  'prize_uuid.png',
  'tweet.png',
  'unique_drink.png',
  'unknown_twitter_account.png',
  'warning.png',
  'og.png',

  // 'favicon.ico', TODO ico対応は現在loaderにできません、pngにするか？
];

export function createDefaultContext(): ReturnType<typeof useMedia> {
  return {
    getAssetsUrl: async (dir: MediumType, medium: Medium) => {
      try {
        if (medium) return require(`@/assets/${dir}/${medium.name}`);
        return undefined;

      } catch (e) {
        if (isCannotFindModule(e)) {

          // eslint-disable-next-line no-console
          console.error(e);
          return undefined;
        } else {
          throw e;
        }
      }
    },
  };
}

export function useMedia(mediaContext: {

  // 受け取れるものはURLか、データか、バイナリファイルです
  getAssetsUrl: (dir: MediumType, medium: Medium) => Promise<string>,
}) {
  const context = mediaContext;
  provide(injectKey, context);

  return context;
}

export function useCurrentMedia() {
  const context = inject(injectKey);
  if (!context) {
    debugLog('Media がprovideされていません、デフォルトを使います');
    return createDefaultContext();
  }

  return context;
}
