import { plainToClass } from 'class-transformer';
import * as convertKeys from 'convert-keys';

import { Campaign } from '@/models/campaign';
import { LandingPage } from '@/models/landing_page';
import { debugLog } from '@/utils/debug';

import { campaignSettings } from './campaign';
import campaignJson from './campaign.json';
import landingPageJson from './landing_page.json';

export function loadCampaignJson() {
  if (process.env.VUE_APP_IS_DEPLOYED_FROM_MANAGER) {
    return plainToClass(Campaign, convertKeys.toCamel<unknown>(campaignJson));
  }
  return campaignSettings;
}

export function loadLandingPageJson() {
  const landingPage = plainToClass(LandingPage, convertKeys.toCamel<unknown>(landingPageJson));
  debugLog({ landingPage });

  return landingPage;
}

export const campaign = loadCampaignJson();
