import { Transform, Type } from 'class-transformer';

import TypeContent from '@/decorators/type_content';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';
import { ContentTextEditorModel } from './content_text_editor_model';

class HorizontalItem {

  @TypeContent()
  @Transform((params) => params.value || [])
  contents: ContentBase[] = [];

  width: string = '';

  constructor(init?: Partial<HorizontalItem>) {
    Object.assign(this, init);
  }
}

export class ContentHorizontalLayoutModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_HORIZONTAL_LAYOUT;

  alignItems: 'TOP' | 'BOTTOM' | 'CENTER' = 'CENTER';

  @Type(() => HorizontalItem)
  @Transform((params) => params.value || [])
  items: HorizontalItem[] = [];

  constructor(init?: Partial<ContentHorizontalLayoutModel>) {
    super();
    Object.assign(this, init);
  }

}

registerGenerator(
  ContentHorizontalLayoutModel,
  ({ style }) => new ContentHorizontalLayoutModel({
    alignItems: 'CENTER',
    style,
    items: [
      new HorizontalItem({
        contents: [
          new ContentTextEditorModel({
            text: 'contents 1',
          }),
          new ContentTextEditorModel({
            text: 'contents 2',
          }),
        ],
        width: '50%',
      }),
      new HorizontalItem({
        contents: [
          new ContentTextEditorModel({
            text: 'contents 3',
          }),
        ],
        width: '50%',
      }),
    ],
  }),
);
