import { Transform } from 'class-transformer';

import TypeAction from '@/decorators/type_action';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';

export class ContentQrCodeReadingModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_QR_CODE_READING;

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];

  constructor(init?: Partial<ContentQrCodeReadingModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentQrCodeReadingModel,
  ({ style }) => new ContentQrCodeReadingModel({
    style,
  }),
);
