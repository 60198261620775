
import { ListboxOption } from '@headlessui/vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BaseSelectOption',
  components: {
    ListboxOption,
  },
  props: {
    value: {
      type: [Object, String, Number, Boolean] as PropType<Record<number | string | symbol, unknown> | string | number | boolean>,
      default: undefined,
    },
  },
});
