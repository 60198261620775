import liff from '@line/liff';
import dayjs from 'dayjs';
import constant from 'lodash/constant';
import noop from 'lodash/noop';

import { campaign } from '@/json/json_loader';
import { debugLog } from '@/utils/debug';

import { isLocal } from '../utils/debug';
import { storageRemoveItems } from '../utils/storage_remove_items';

type IsFailed = boolean;
const FAILED: IsFailed = true;
const SUCCESS: IsFailed = false;

export async function liffInit(): Promise<IsFailed> {

  // 普通のブラウザ限定処理。（ここだけmockを適用できないのでisLocalでは無視する）
  if (!liff.isInClient() && !isLocal()) {
    refreshLiffIdToken();
  }

  // liff を使わない場合は削除する。
  // liff.init は内部的にルーティング処理を行っているので、
  // VueRouter が起動する前に呼び出さないとお互いに干渉してしまう。
  // 必ずこのタイミングで init すること！
  await liff.init(
    {
      liffId: campaign.liffId,
      mock: isLocal(),
    },
    noop,
    () => {

      // 外部ブラウザでスコープの認証をキャンセルしたときはこちらが実行される
      alert('認証がキャンセルされました。');
    },
  );

  // デバッグ用。モックでも`liff.login()`を実行しないとエラーになる。
  if (isLocal()) {
    debugLog('debug liff mock login');
    liff.login();
  }

  const isLoggedIn = liff.isLoggedIn()

    // トークンが破棄されていても `liff.isLoggedIn()` が `true` になることがある
    // スコープに profile が含まれているときは `liff.getProfile()` できることもチェックする
    && (campaign.isOnlyOpenid || await liff.getProfile().then(constant(true)).catch(constant(false)));

  // ブラウザでも liff ログインする
  if (!isLoggedIn) {
    liff.logout();

    // この関数は何故か　同期的に動かせない
    liff.login({
      redirectUri: location.href, // 指定しないとアプリに設定したリダイレクト先に遷移してしまい、pathとクエリストリングの情報が失われる。
    });

    // ので、ここのパスに入った場合は Vue を mount しない。
    return FAILED;
  }

  return SUCCESS;
}

function refreshLiffIdToken() {
  const expDiffSec = 1 * 60; // 1分 * 60秒
  const idTokenStorageStr = localStorage.getItem(`LIFF_STORE:${campaign.liffId}:decodedIDToken`);
  const idToken = (idTokenStorageStr ? JSON.parse(idTokenStorageStr) : null) as ReturnType<typeof liff['getDecodedIDToken']>; // getDecodedIDTokenの戻り値に合わせてない場合はnullを返す
  if (idToken?.exp && (idToken.exp - expDiffSec) < dayjs().unix()) { // 初期表示時のログイン後はトークン以外のIDやtempキーがあるのでそれを消さないようにundefined時はキーを消さない
    storageRemoveItems(localStorage, `^LIFF_STORE:${campaign.liffId}:.*$`);
  }
}
