import { Transform } from 'class-transformer';

import TypeAction from '@/decorators/type_action';
import TypeContent from '@/decorators/type_content';
import TypeStyle from '@/decorators/type_style';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';
import { ContentLinkModel } from './content_link_model';
import { ContentTextEditorModel } from './content_text_editor_model';
import { Style } from './style';

export class ContentCheckboxModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_CHECKBOX;

  text: string = '';

  @TypeContent()
  @Transform((params) => params.value || [])
  checkedContents: ContentBase[] = [];

  @TypeContent()
  @Transform((params) => params.value || [])
  unCheckedContents: ContentBase[] = [];

  @TypeAction()
  @Transform((params) => params.value || [])
  checkedActions: ActionBase[] = [];

  @TypeAction()
  @Transform((params) => params.value || [])
  unCheckedActions: ActionBase[] = [];

  @TypeStyle({ })
  checkboxStyle: Style = new Style({});

  constructor(init?: Partial<ContentCheckboxModel>) {
    super();
    Object.assign(this, init);
  }

}

registerGenerator(
  ContentCheckboxModel,
  ({ style }) => new ContentCheckboxModel({
    style,
    checkedContents: [
      new ContentLinkModel({
        text: 'Checked',
      }),
      new ContentTextEditorModel({
        text: 'CheckedChecked',
      }),
    ],
    unCheckedContents: [
      new ContentLinkModel({
        text: 'UnChecked',
      }),
    ],
  }),
);
