import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPrizes = _resolveComponent("ContentPrizes")!

  return (_ctx.keyName==='prizes')
    ? (_openBlock(), _createBlock(_component_ContentPrizes, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, " このコンテンツは未実装（キー名: " + _toDisplayString(_ctx.keyName || '（未設定）') + "） ", 1))
}