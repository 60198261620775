
import { useVModel } from '@vueuse/core';
import { defineComponent, provide } from 'vue-demi';

import { BaseRadioContextInjectionKey } from '@/hooks/use_base_radio';

export default defineComponent({
  name: 'BasicRadioGroup',
  props: {

    /**
     * v-model用
     */
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const selection = useVModel(props, 'modelValue', emit);

    provide(BaseRadioContextInjectionKey, {
      selection,
    });
  },
});
