import { Transform } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';

import TypeContent from '@/decorators/type_content';
import TypeStyle from '@/decorators/type_style';
import { generate, registerGenerator } from '@/utils/generate';

import { ContentBase } from './content_base';
import { ContentButtonModel } from './content_button_model';
import { ContentImageModel } from './content_image_model';
import { ContentTextEditorModel } from './content_text_editor_model';
import { Model } from './model';
import { Style } from './style';

export class Group extends Model<Group> {
  uniqueKey: string = uuidv4();

  @TypeStyle({
    defaultStyle: {
      marginTop: '0',
      marginRight: '0',
      marginBottom: '0',
      marginLeft: '0',
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
    },
  })
  style?: Style;

  @TypeContent()
  @Transform((params) => params.value || [])
  contents: ContentBase[] = [];

  constructor(init?: Partial<Group>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  Group,
  (props) => new Group({
    style: new Style({
      backgroundColor: '#5F5F5F',
      color: 'white',
    }),
    contents: [
      generate(ContentImageModel, props),
      generate(ContentTextEditorModel, props),
      generate(ContentButtonModel, props),
    ],
  }),
);
