export class LinePayDialogPropaty {
  agreeHeader: string = '';

  agreeFooter: string = '';

  resultHeader: string = '';

  resultFooter: string = '';

  constructor(init?: Partial<LinePayDialogPropaty>) {
    Object.assign(this, init);
  }
}
