
import { useVModel } from '@vueuse/core';
import { computed, defineComponent, PropType, toRefs } from 'vue';

import BaseIcon from './BaseIcon.vue';
export default defineComponent({
  name: 'BaseCheckbox',
  components: {
    BaseIcon,
  },
  props: {

    variant: {
      type: String as PropType<'default' | 'white'>,
      default: 'white',
    },

    /**
     * v-model用
     */
    modelValue: {
      type: null,
      required: true,
    },

    /**
     * チェックしている場合、inputが発行する値
     */
    whenTrue: {
      type: null,
      default: true,
    },

    /**
     * チェックしていない場合、inputが発行する値
     */
    whenFalse: {
      type: null,
      default: false,
    },

    /**
     * 非活性化どうか
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * チェックボックスの大きさ
     */
    checkboxSize: {
      type: Number,
      default: 16,
    },

    /**
     * 位置
     */
    position: {
      type: String as PropType<'left' | 'center'>,
      default: 'left',
    },
  },
  setup(props, { emit, slots }) {
    const value = useVModel(props, 'modelValue', emit);
    const checked = computed(() => props.modelValue === props.whenTrue);

    const { disabled } = toRefs(props);

    function onToggle() {
      if (disabled.value) {
        return;
      }

      value.value = !checked.value
        ? props.whenTrue
        : props.whenFalse;
    }

    return {
      checked,
      onToggle,
      hasContent: !!slots.default,
    };
  },
});
