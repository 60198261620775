
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SurveyHeader',
  props: {

    /**
     * 必須
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
});
