import { Transform, Type } from 'class-transformer';
import range from 'lodash/range';

import { generate, registerGenerator } from '@/utils/generate';

import { Model } from './model';
import { PointHistory } from './point_history';

export class Mileage extends Model<Mileage> {
  point!: number;

  @Type(() => PointHistory)
  @Transform((params) => params.value || [])
  pointHistories?: PointHistory[];

  constructor(init?: Partial<Mileage>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  Mileage,
  (props) => new Mileage({
    point: 100,
    pointHistories: range(0, props.size).map(
      (i) => generate(PointHistory, props).with({
        entryUuid: `entryUuid000-000-${i}`,
        label: 'ラベル',
        responseCandidateUuid: `responseCandidateUuid111-111-${i}`,
        point: 10,
      }),
    ),
  }),
);
