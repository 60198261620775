export function storageRemoveItems(storage: Storage, keyPattern: string | RegExp) {
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    const match = key?.match(keyPattern);
    if (key && match) {
      storage.removeItem(key);
      i--;
    }
  }
}
