
import { ItemTypeKbn } from '@/utils/crm_kbn';

import { SurveyAnswerItemContentMultiSelect } from './survey_answer_item_content_multi_select.';
import { SurveyAnswerItemContentSelect } from './survey_answer_item_content_select';
import { SurveyAnswerItemContentText } from './survey_answer_item_content_text';

export class SurveyAnswerItem {
  uuid?: string;

  historyUuid?: string;

  typeKbn?: ItemTypeKbn;

  questionContent?: string;

  // plainToClassは使わない想定。 @Typeはマルチタイプを考えると複雑になるので定義しない
  answer?: SurveyAnswerItemContentText | SurveyAnswerItemContentSelect | SurveyAnswerItemContentMultiSelect;

  constructor(init?: Partial<SurveyAnswerItem>) {
    Object.assign(this, init);
  }
}
