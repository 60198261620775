import { Expose } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

import { HandlebarsRender } from '@/decorators/handlebars_render';
import { TransformUtsmsToDayjs  } from '@/decorators/transform_number_to_dayjs';
import TypeStyle from '@/decorators/type_style';
import { ContentAtKbn, ContentTypeKbn, KbnConst } from '@/utils/kbn';
import { getDisplayDateTimeByKbn, isWithinDisplayPeriod } from '@/utils/period';

import { Style } from './style';

export class ContentBase {
  typeKbn?: ContentTypeKbn;

  @TypeStyle()
  style?: Style;

  @HandlebarsRender()
  id?: string;

  disabled?: boolean;

  displayStartAtKbn: ContentAtKbn = KbnConst.CONTENT_AT_KBN_UNSET;

  @Expose({ name: 'displayStartUtsms' })
  @TransformUtsmsToDayjs
  displayStartAt?: Dayjs | null;

  displayEndAtKbn: ContentAtKbn = KbnConst.CONTENT_AT_KBN_UNSET;

  @Expose({ name: 'displayEndUtsms' })
  @TransformUtsmsToDayjs
  displayEndAt?: Dayjs | null;

  constructor(init?: Partial<ContentBase>) {
    Object.assign(this, init);
  }

  /**
   * 表示期間内かどうか
   */
  withinDisplayPeriod(now = dayjs()) {
    const displayStartAt = getDisplayDateTimeByKbn(this.displayStartAtKbn, this.displayStartAt);
    const displayEndAt = getDisplayDateTimeByKbn(this.displayEndAtKbn, this.displayEndAt);

    return isWithinDisplayPeriod(displayStartAt || null, displayEndAt || null, now);
  }
}
