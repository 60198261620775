// 区分
export type CrmKbn =
  '001' /* 質問タイプ区分 */ |
  '002' /* リスト選択肢テンプレート区分 */ |
  '100' /* 質問UI区分 */;

// 質問タイプ区分
export type ItemTypeKbn =
  '00101' /* リスト */ |
  '00102' /* 自由回答（1行テキスト） */ |
  '00103' /* 自由回答（複数行テキスト） */ |
  '00104' /* 生年月日 */ |
  '00105' /* 単一選択リスト */;

// リスト選択肢テンプレート区分
export type ListSelectionTemplateKbn =
  '00201' /* 性別 */ |
  '00202' /* 都道府県 */ |
  '00203' /* 職業 */;

// 質問UI区分
export type UiKbn =
  '10001' /* リスト */ |
  '10002' /* 自由回答（1行テキスト） */ |
  '10003' /* 自由回答（複数行テキスト） */ |
  '10004' /* 生年月日 */ |
  '10005' /* 単一リスト */ |
  '10006' /* チェックボックス */ |
  '10007' /* ラジオボタン */;

// as で型を付けてしまうと型解釈がうまくいかないところがあるのでつけない
export const CrmKbnConst = {

  // 質問タイプ区分
  ITEM_TYPE_KBN: '001',

  // 質問タイプ区分(リスト)
  ITEM_TYPE_KBN_LIST: '00101',

  // 質問タイプ区分(自由回答（1行テキスト）)
  ITEM_TYPE_KBN_SINGLE_LINE_TEXT: '00102',

  // 質問タイプ区分(自由回答（複数行テキスト）)
  ITEM_TYPE_KBN_MULTI_LINES_TEXT: '00103',

  // 質問タイプ区分(生年月日)
  ITEM_TYPE_KBN_BIRTHDAY: '00104',

  // 質問タイプ区分(単一選択リスト)
  ITEM_TYPE_KBN_SINGLE_LIST: '00105',

  // リスト選択肢テンプレート区分
  LIST_SELECTION_TEMPLATE_KBN: '002',

  // リスト選択肢テンプレート区分(性別)
  LIST_SELECTION_TEMPLATE_KBN_GENDER: '00201',

  // リスト選択肢テンプレート区分(都道府県)
  LIST_SELECTION_TEMPLATE_KBN_PREFECTURES: '00202',

  // リスト選択肢テンプレート区分(職業)
  LIST_SELECTION_TEMPLATE_KBN_JOB: '00203',

  // 質問UI区分
  UI_KBN: '100',

  // 質問UI区分(リスト)
  UI_KBN_LIST: '10001',

  // 質問UI区分(自由回答（1行テキスト）)
  UI_KBN_SINGLE_LINE_TEXT: '10002',

  // 質問UI区分(自由回答（複数行テキスト）)
  UI_KBN_MULTI_LINES_TEXT: '10003',

  // 質問UI区分(生年月日)
  UI_KBN_BIRTHDAY: '10004',

  // 質問UI区分(単一リスト)
  UI_KBN_SINGLE_LIST: '10005',

  // 質問UI区分(チェックボックス)
  UI_KBN_CHECKBOX: '10006',

  // 質問UI区分(ラジオボタン)
  UI_KBN_RADIO_BUTTON: '10007',
} as const;

export const CrmKbnValues = {

  // 質問タイプ区分
  '001': [
    '00101', // リスト
    '00102', // 自由回答（1行テキスト）
    '00103', // 自由回答（複数行テキスト）
    '00104', // 生年月日
    '00105', // 単一選択リスト
  ],

  // リスト選択肢テンプレート区分
  '002': [
    '00201', // 性別
    '00202', // 都道府県
    '00203', // 職業
  ],

  // 質問UI区分
  '100': [
    '10001', // リスト
    '10002', // 自由回答（1行テキスト）
    '10003', // 自由回答（複数行テキスト）
    '10004', // 生年月日
    '10005', // 単一リスト
    '10006', // チェックボックス
    '10007', // ラジオボタン
  ],
} as const;
