import { Expose, Transform } from 'class-transformer';

import { Style, StyleField } from '@/models/style';

export default function TypeStyle({ defaultStyle = {} }: { defaultStyle?: Partial<StyleField> } = {}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(target: any, propertyKey: string) {
    Expose()(target, propertyKey);

    Transform(({ value }: {value: Partial<StyleField>}) => {
      // デフォルト値を上書きします。ベースのデフォルト値は設定しない場合、元々のデフォルト値を使います。
      const styleData = new Style(value, defaultStyle ?? {});
      return styleData;
    }, { toClassOnly: true })(target, propertyKey);
  };
}
