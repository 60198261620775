
import dayjs from 'dayjs';
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';

import { useDisabledContents } from '@/hooks/use_disabled_contents';
import { ContentAccordinationModel } from '@/models/content_accordination_model';
import { ContentBase } from '@/models/content_base';
import { ContentButtonModel } from '@/models/content_button_model';
import { ContentCheckboxModel } from '@/models/content_checkbox_model';
import { ContentCustomizingModel } from '@/models/content_customizing_model';
import { ContentFooterModel } from '@/models/content_footer_model';
import { ContentGroupModel } from '@/models/content_group_model';
import { ContentHorizontalLayoutModel } from '@/models/content_horizontal_layout_model';
import { ContentImageModel } from '@/models/content_image_model';
import { ContentItemizedListModel } from '@/models/content_itemized_list_model';
import { ContentLinkModel } from '@/models/content_link_model';
import { ContentQrCodeReadingModel } from '@/models/content_qr_code_reading_model';
import { ContentQrCodeShowModel } from '@/models/content_qr_code_show_model';
import { ContentReceiptPreviewModel } from '@/models/content_receipt_preview';
import { ContentSurveyConfirmModel } from '@/models/content_survey_confirm_model';
import { ContentSurveyInputModel } from '@/models/content_survey_input_model';
import { ContentTextEditorModel } from '@/models/content_text_editor_model';
import { ContentTrackingIdModel } from '@/models/content_tracking_id_model';
import { ContentVideoModel } from '@/models/content_video_model';

import ContentAccordination from './ContentAccordination.vue';
import ContentButton from './ContentButton.vue';
import ContentCheckbox from './ContentCheckbox.vue';
import ContentCustomizing from './ContentCustomizing.vue';
import ContentFooter from './ContentFooter.vue';
import ContentGroup from './ContentGroup.vue';
import ContentHorizontalLayout from './ContentHorizontalLayout.vue';
import ContentImage from './ContentImage.vue';
import ContentItemizedList from './ContentItemizedList.vue';
import ContentLink from './ContentLink.vue';
import ContentQrCodeReading from './ContentQrCodeReading.vue';
import ContentQrCodeShow from './ContentQrCodeShow.vue';
import ContentReceiptPreview from './ContentReceiptPreview.vue';
import ContentSurveyConfirm from './ContentSurveyConfirm.vue';
import ContentSurveyInput from './ContentSurveyInput.vue';
import ContentTextEditor from './ContentTextEditor.vue';
import ContentTrackingId from './ContentTrackingId.vue';
import ContentVideo from './ContentVideo.vue';

/**
 * Content系コンポーネントへ振り分ける
 */
export default defineComponent({
  name: 'Content',
  components:
  {
    ContentTextEditor,
    ContentButton,
    ContentImage,
    ContentLink,
    ContentVideo,
    ContentFooter,
    ContentTrackingId,
    ContentGroup,
    ContentCustomizing,
    ContentHorizontalLayout,
    ContentItemizedList,
    ContentCheckbox,
    ContentAccordination,
    ContentQrCodeReading,
    ContentQrCodeShow,
    ContentSurveyInput,
    ContentSurveyConfirm,
    ContentReceiptPreview,
  },
  props: {
    content: {
      type: Object as PropType<ContentBase>, // タイプ区分に合わせた各サブクラスのインスタンスであることを期待するため、ここで型変換は行わない。
      required: true,
    },
  },
  setup: (props) => {
    const { content } = toRefs(props);

    const now = ref(dayjs());

    // disabledContents に id が含まれているかどうかを disabled に持つ
    const { disabledContents } = useDisabledContents();
    const disabled = computed(() => {
      if (!!disabledContents.value && !!content.value.id) {
        return disabledContents.value.get(content.value.id);
      }
      return undefined;
    });

    function toContentModel<T extends ContentBase>() {
      const model = content.value as T;
      model.disabled = disabled.value;
      return model;
    }

    return {
      contentTextEditor: computed<ContentTextEditorModel>(toContentModel),
      contentImage: computed<ContentImageModel>(toContentModel),
      contentButton: computed<ContentButtonModel>(toContentModel),
      contentLink: computed<ContentLinkModel>(toContentModel),
      contentVideo: computed<ContentVideoModel>(toContentModel),
      contentFooter: computed<ContentFooterModel>(toContentModel),
      contentTrackingId: computed<ContentTrackingIdModel>(toContentModel),
      contentHorizontalLayoutModel: computed<ContentHorizontalLayoutModel>(toContentModel),
      contenCheckboxModel: computed<ContentCheckboxModel>(toContentModel),
      contentItemizedList: computed<ContentItemizedListModel>(toContentModel),
      contentAccordinationModel: computed<ContentAccordinationModel>(toContentModel),
      contentQrCodeReadingModel: computed<ContentQrCodeReadingModel>(toContentModel),
      contentQrCodeShowModel: computed<ContentQrCodeShowModel>(toContentModel),
      contentSurveyInput: computed<ContentSurveyInputModel>(toContentModel),
      contentSurveyConfirm: computed<ContentSurveyConfirmModel>(toContentModel),
      contentGroup: computed<ContentGroupModel>(toContentModel),
      contentReceiptPreview: computed<ContentReceiptPreviewModel>(toContentModel),
      contentCustomizing: computed<ContentCustomizingModel>(toContentModel),
      now,
    };
  },
});
