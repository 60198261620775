import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inline-flex flex-col items-center p-4",
  style: {"font-family":"Noto Sans JP"}
}
const _hoisted_2 = { class: "whitespace-pre-wrap break-all text-center text-xs" }
const _hoisted_3 = { class: "whitespace-pre-wrap break-all text-center text-xs mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinePayGiftContent = _resolveComponent("LinePayGiftContent")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_LinePayDialogFrame = _resolveComponent("LinePayDialogFrame")!

  return (_openBlock(), _createBlock(_component_LinePayDialogFrame, { class: "w-64" }, {
    default: _withCtx(() => [
      (_ctx.isShow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerText), 1),
            _createVNode(_component_LinePayGiftContent, {
              "gift-open": false,
              amount: _ctx.amount
            }, null, 8, ["amount"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.footerText), 1),
            _createVNode(_component_BaseButton, {
              variant: "solid-line",
              class: "dialog-button",
              onClick: _ctx.ok
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 同意して受け取る ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}