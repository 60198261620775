
import { computed, defineComponent } from 'vue';

import { useSurvey } from '@/hooks/use_survey';
import { surveyPatternItemTypeRemarks } from '@/utils/survey_pattern_item_type_remarks';

import SurveyErrorText from './SurveyErrorText.vue';
import SurveyHeader from './SurveyHeader.vue';
import SurveyInput from './SurveyInput.vue';

export default defineComponent({
  name: 'ContentSurveyInput',
  components: {
    SurveyInput,
    SurveyErrorText,
    SurveyHeader,
  },
  setup() {
    const survey = useSurvey();

    return {
      items: computed(() => survey.survey.value?.items || []),
      answers: survey.answers,
      errors: survey.errors,
      surveyPatternItemTypeRemarks,
    };
  },
});
