import { ref } from 'vue';

const serialCode = ref<string>();

/**
 * シンプルなストア SerialCodeの共有
 * @returns serialCodeのrefオブジェクト
 */
export function useSerialCode() {
  return serialCode;
}
