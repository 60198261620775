import dayjs from 'dayjs';

import { useInjectCampaign } from '@/hooks/use_campaign';
import { useLoader } from '@/hooks/use_loader';
import { useRouting } from '@/hooks/use_routing';
import { ActionBase } from '@/models/action_base';
import RouterConst from '@/router/router_const';
import { KbnConst } from '@/utils/kbn';

/**
 * 期間外であった場合期間外ページに飛ばす。後続のアクションは実行しない。
 * 期間内であった場合何もしない。
 */
export class ActionCheckOffHour extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_CHECK_OFF_HOUR;

  use() {
    const campaign = useInjectCampaign();
    const routing = useRouting();
    const loader = useLoader();

    return async () => await execute(campaign, routing, loader);
  }

  constructor(init: Partial<ActionCheckOffHour> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  campaign: ReturnType<typeof useInjectCampaign>,
  routing: ReturnType<typeof useRouting>,
  loader: ReturnType<typeof useLoader>,
) {
  try {
    loader.start();

    const today = dayjs();
    if (today.isBefore(campaign.campaignStartUts)) {
      await routing.redirect(RouterConst.OffHourBefore);
      return true;
    } else if (today.isBefore(campaign.campaignEndUts)) {
      // 期間内は何もしない
    } else {
      await routing.redirect(RouterConst.OffHour);
      return true;
    }

  } finally {
    loader.stop();
  }

  return false;
}

export function generateActionCheckOffHour() {
  return new ActionCheckOffHour();
}
