import dayjs from 'dayjs';
import { ref } from 'vue';

import { debugLog } from '@/utils/debug';
import { sleep } from '@/utils/sleep';

const inputElement = ref<HTMLInputElement>();
const fileCache = ref<File>();

/**
 * ファイルアップロードの状態を管理する
 */
class FileUploadManager {
  resolver: ((value: boolean) => void) | null = null;

  executionId: string | null = null;

  start() {
    // 以前のアクションをキャンセルする
    this.cancel();

    this.executionId = dayjs().valueOf().toString();

    debugLog(`file upload started（executionId: ${this.executionId})`);

    return {
      promise: new Promise<boolean>(resolve => this.resolver = resolve),
    };
  }

  end() {
    if (!this.resolver) {
      return;
    }

    debugLog(`file upload completed（executionId: ${this.executionId}）`);

    this.resolver(false);
    this.resolver = null;
  }

  cancel() {
    if (!this.resolver) {
      return;
    }

    debugLog(`file upload cancelled（executionId: ${this.executionId}）`);

    this.resolver(true);
    this.resolver = null;
  }

  // 関数を呼び出した時点で実行中だったファイルアップロードを時間差でキャンセルする
  async delayCancel(intervalMs: number) {
    if (!this.resolver) {
      return;
    }

    const executionId = this.executionId;

    await sleep(intervalMs);

    if (!this.resolver) {
      return;
    }
    if (executionId !== this.executionId) {
      return;
    }

    debugLog(`file upload cancelled async（executionId: ${this.executionId}）`);

    this.resolver(true);
    this.resolver = null;
  }
}

const manager = new FileUploadManager();

export function useFileUpload() {
  /**
   * 写真を選択・アップロードする
   * 成功のとき false、キャンセルされたとき true を返す
   */
  const uploadPicture = () => {
    const { promise } = manager.start();
    if (inputElement.value) {
      inputElement.value.value = ''; // 同じ画像をアップロードしなおしたときにも onChange イベントが発火されるようにリセットする
      inputElement.value.removeAttribute('capture');
      inputElement.value.click();
    }
    return promise;
  };

  /**
   * 写真を撮る
   * 成功のとき false、キャンセルされたとき true を返す
   */
  const takePicture = () => {
    const { promise } = manager.start();
    if (inputElement.value) {
      inputElement.value.value = ''; // 同じ画像をアップロードしなおしたときにも onChange イベントが発火されるようにリセットする
      inputElement.value.setAttribute('capture', 'environment');
      inputElement.value.click();
    }
    return promise;
  };

  function done() {
    manager.end();
  }

  function cancel() {
    manager.cancel();
  }

  async function delayCancel(intervalMs: number) {
    return await manager.delayCancel(intervalMs);
  }

  return {
    fileCache,
    inputElement,
    uploadPicture,
    takePicture,
    done,
    cancel,
    delayCancel,
  };
}
