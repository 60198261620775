
import { defineComponent, onMounted, PropType } from 'vue';

import CommonPage from '@/components/CommonPage.vue';
import { useActions } from '@/hooks/use_actions';
import { useDisabledContents } from '@/hooks/use_disabled_contents';
import { useMileage } from '@/hooks/use_mileage';
import { useSurvey } from '@/hooks/use_survey';
import { Page } from '@/models/page';

export default defineComponent({
  name: 'JsonBuildPage',
  components: {
    CommonPage,
  },
  props: {
    page: {
      type: Object as PropType<Page>,
      required: true,
    },
  },
  setup: (props) => {
    const actions = useActions(props.page.actions);
    const { getSurvey, isSurveyContent } = useSurvey();
    const { getMileage, isMileageContent } = useMileage();
    const { shouldVerifyDisablement, getDisabledContents } = useDisabledContents();
    const hasSurvey = props.page.contents.find(content => isSurveyContent(content.typeKbn));
    const hasMileage = props.page.contents.find(content => isMileageContent(content.typeKbn));
    const hasDisablement = props.page.contents.find(content => shouldVerifyDisablement(content.id));

    onMounted(async () => {
      await actions();

      // アンケート取得は users API に依存しているので、使うときは先にログインする必要がある。
      // 必ず actions の後に実行するようにする。
      if (hasSurvey) {
        await getSurvey();
      }

      // マイレージポイント表示、マイレージポイント履歴表示のため
      if (hasMileage) {
        await getMileage();
      }

      // 非活性コンテンツを取得する
      if (hasDisablement) {
        await getDisabledContents();
      }
    });
  },
});
