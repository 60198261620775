import { computed, ref } from 'vue';

type Store = {
  receiptUuid?: string;
  receiptData?: unknown;
  survey?: unknown;
  radioSelected?: string;
  qrCode?: string;
  dataJson?: unknown;
  queryString?: unknown;
};

const store = ref<Store>({});

export function useInsertDataStore() {
  return {
    getValues() {
      return store.value;
    },

    clear() {
      store.value = {}; // 保持データ削除
    },

    receiptUuid: computed({
      get: () => store.value.receiptUuid,
      set: (value) => store.value.receiptUuid = value,
    }),

    receiptData: computed({
      get: () => store.value.receiptData,
      set: (value) => store.value.receiptData = value,
    }),

    survey: computed({
      get: () => store.value.survey,
      set: (value) => store.value.survey = value,
    }),

    radioSelected: computed({
      get: () => store.value.radioSelected,
      set: (value) => store.value.radioSelected = value,
    }),

    dataJson: computed({
      get: () => store.value.dataJson,
      set: (value) => store.value.dataJson = value,
    }),

    queryString: computed({
      get: () => store.value.queryString,
      set: (value) => store.value.queryString = value,
    }),

    qrCode: computed({
      get: () => store.value.qrCode,
      set: (value) => store.value.qrCode = value,
    }),
  };
}
