import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentTrackingIdModel extends ContentBase {
  constructor(init?: Partial<ContentTrackingIdModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_TRACKING_ID; // クラスに対して区分値固定なので、コンストラクタで代入
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentTrackingIdModel,
  ({ style }) => new ContentTrackingIdModel({
    typeKbn: KbnConst.CONTENT_TYPE_KBN_TRACKING_ID,
    style,
  }),
);
