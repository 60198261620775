import axios from 'axios';
import { App } from 'vue';
import { Router } from 'vue-router';

import { RESULT_CODE } from '@/api/result_code';
import { routerRedirect } from '@/hooks/use_routing';
import { useRum } from '@/hooks/use_rum';
import RouterConst from '@/router/router_const';
import { debugError } from '@/utils/debug';

// イベントを文字列化する
function stringifyEvent(e: any) {
  const obj: any = {};
  for (const k in e) {
    obj[k] = e[k];
  }
  return JSON.stringify(obj, (_, v) => {
    if (v instanceof Node) return 'Node';
    if (v instanceof Window) return 'Window';
    return v;
  }, ' ');
}

function makeErrorHandler(router: Router, rum: ReturnType<typeof useRum>, detail = false) {
  return (error: unknown) => {

    rum.recordError(error);

    debugError(error);

    if (detail) {
      // URL を開くときに `INVALID_ARGUMENT The Passed argument is invalid.` エラーが発生したことがあった
      // https://uv-jp.slack.com/archives/C068FP7RMAP/p1710984687725979
      // RUM でエラーの詳細を確認できなかったので文字列化して RUM に記録する
      rum.recordEvent('error_detail', { detail: stringifyEvent(error) });
    }

    if (axios.isAxiosError(error) && error.response?.data.resultCode === RESULT_CODE.OFF_HOUR) {
      // 期間外エラー
      routerRedirect(router, RouterConst.OffHour);
    } else if (axios.isAxiosError(error) && error.response?.data.resultCode === RESULT_CODE.INVALID_CALLBACK_PARAMETER) {
      // 不正なコールバックパラメータによるエラーでは何もしない
      // コールバック後のリロードで発生しうるエラーなので
      return;
    } else if (axios.isAxiosError(error) && !error.response) {
      // APIキャンセルは何もしない。
      // TODO: axios.isCancel が効くかどうかを実験する。
    } else {
      // その他のエラー
      routerRedirect(router, RouterConst.Error);
    }
  };
}

export function errorHandlerInstallWith(router: Router, rum: ReturnType<typeof useRum>) {
  return {
    install: (app: App) => {
      app.config.errorHandler = makeErrorHandler(router, rum);
      window.addEventListener('error', makeErrorHandler(router, rum));
      window.addEventListener('unhandledrejection', makeErrorHandler(router, rum, true));
    },
  };
}
