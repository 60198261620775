import { inject, provide } from 'vue';

import { campaign } from '@/json/json_loader';
import { Campaign } from '@/models/campaign';

const injectKeyCampaign = 'Campaign';

export function useProvideCampaign(value?: Campaign)  {
  provide(injectKeyCampaign, value);
}

export function useInjectCampaign() {
  return inject(injectKeyCampaign, campaign);
}
