
import { defineComponent } from 'vue';

import { useDialog } from '@/hooks/use_dialog';

import BaseDialog from './BaseDialog.vue';
import JsonBuildDialog from './JsonBuildDialog.vue';

export default defineComponent({
  name: 'TheGlobalDialogs',
  components: {
    JsonBuildDialog,
    BaseDialog,
  },

  setup() {
    const { dialogs } = useDialog();

    return {
      dialogs,
    };
  },
});
