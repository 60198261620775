import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d71c366"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative bg-white whitespace-nowrap w-full" }
const _hoisted_2 = { class: "w-full px-2 py-1 flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "flex-grow-0 min-w-0" }
const _hoisted_4 = {
  key: 0,
  class: "mx-1 border-gray-400 border-b"
}
const _hoisted_5 = { class: "w-full max-h-40 hidden-scrollbar overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Listbox, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      class: "w-full"
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ListboxButton, {
            class: _normalizeClass(["w-full border-t border-l border-r border-gray-400 hover:bg-gray-100 rounded-t-md", [!open && 'border-b rounded-b-md']])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "selected", {}, undefined, true)
                ]),
                _createVNode(_component_BaseIcon, {
                  icon: "arrow_down",
                  class: "flex-shrink-0 text-gray-800",
                  size: 24
                })
              ]),
              open
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class"]),
          _createVNode(_component_ListboxOptions, {
            class: _normalizeClass([
            'absolute z-10 bg-white w-full',
            'text-gray-800',
            open && 'border-l border-b border-r border-gray-400 rounded-b-md'
          ])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "options", {}, undefined, true)
              ])
            ]),
            _: 2
          }, 1032, ["class"])
        ])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}