export class Meta {
  name?: string;

  property?: string;

  content?: string;

  constructor(init?: Partial<Meta>) {
    Object.assign(this, init);
  }
}
