import { useApi } from '@/hooks/use_api';
import { useInjectCampaign } from '@/hooks/use_campaign';
import { useCurrentPage } from '@/hooks/use_current_page';
import { useDisabledContents } from '@/hooks/use_disabled_contents';
import { useInsertDataStore } from '@/hooks/use_insert_data_store';
import { useLoader } from '@/hooks/use_loader';
import { useMileage } from '@/hooks/use_mileage';
import { useRouting } from '@/hooks/use_routing';
import { useSerialCode } from '@/hooks/use_serial_code';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useActionDataClear } from './use_action_data_clear';
import { useSurvey } from './use_survey';

/**
 * 抽選して、返却された返信グループに応じて遷移させる。
 * シリアルがある場合は、ストアに保存する
 */
export class ActionLottery extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_LOTTERY;

  /**
   * 抽選キー名
   * （この値はプログラム内容や出し分け条件で参照できる）
   */
  insertType?: string;

  /**
   * コースID
   * （この値はプログラム内容や出し分け条件で参照でき、マイレージのマイページ機能で利用される）
   */
  courseId?: string;

  use() {
    const api = useApi();
    const campaign = useInjectCampaign();
    const loader = useLoader();
    const routing = useRouting();
    const serialCode = useSerialCode();
    const insertDataStore = useInsertDataStore();
    const survey = useSurvey();
    const { getCurrentPage } = useCurrentPage();
    const { getMileage, isMileageContent } = useMileage();
    const { getDisabledContents, shouldVerifyDisablement } = useDisabledContents();
    const  actionDataClear = useActionDataClear();

    return async () => await execute(
      this.insertType,
      this.courseId,
      api,
      campaign,
      loader,
      routing,
      serialCode,
      insertDataStore,
      survey,
      getCurrentPage,
      getMileage,
      isMileageContent,
      getDisabledContents,
      shouldVerifyDisablement,
      actionDataClear,
    );
  }

  constructor(init: Partial<ActionLottery> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  insertType: string | undefined,
  courseId: string | undefined,
  api: ReturnType<typeof useApi>,
  campaign: ReturnType<typeof useInjectCampaign>,
  loader: ReturnType<typeof useLoader>,
  routing: ReturnType<typeof useRouting>,
  serialCode: ReturnType<typeof useSerialCode>,
  insertDataStore: ReturnType<typeof useInsertDataStore>,
  survey: ReturnType<typeof useSurvey>,
  getCurrentPage: ReturnType<typeof useCurrentPage>['getCurrentPage'],
  getMileage: ReturnType<typeof useMileage>['getMileage'],
  isMileageContent: ReturnType<typeof useMileage>['isMileageContent'],
  getDisabledContents: ReturnType<typeof useDisabledContents>['getDisabledContents'],
  shouldVerifyDisablement: ReturnType<typeof useDisabledContents>['shouldVerifyDisablement'],
  actionDataClear: ReturnType<typeof useActionDataClear>,

) {
  try {

    // surveyデータがあれば追加
    const surveyData = survey.getPostData();
    if (surveyData) {
      insertDataStore.survey.value = surveyData;
    }

    loader.start();

    const insertData = {
      ...insertDataStore.getValues(),
      insertType,
      courseId,
    };
    let responsePostInsert;
    if (campaign.isOnlyOpenid) {
      responsePostInsert = await api.postInsertOpenid(insertData);

    } else {
      responsePostInsert = await api.postInsert(insertData);
    }

    await api.getLottery(responsePostInsert.entryUuid);
    const entryPollingResponse = await api.getEntryPolling(responsePostInsert.entryUuid);

    const page = getCurrentPage();
    if (page) {
      const hasMileage = page.contents.find(content => isMileageContent(content.typeKbn));
      const hasDisablement = page.contents.find(content => shouldVerifyDisablement(content.id));
      if (hasMileage) {
        getMileage();
      }
      if (hasDisablement) {
        getDisabledContents();
      }
    }

    if (entryPollingResponse && entryPollingResponse.responseCandidateUuid) {
      // シリアル登録
      serialCode.value = entryPollingResponse.serialCode;
      const uuid = entryPollingResponse.responseCandidateUuid;
      const responseCandidate = campaign.responseCandidates.find(x => x.uuid === uuid);
      if (responseCandidate) {
        // データクリア
        await actionDataClear();
        const page = responseCandidate.pageName;
        if (page) {
          await routing.redirect(page);
          return true;
        }
      }
    }

  } finally {
    loader.stop();
  }

  // どの返信グループにも落ちなかったときに次のアクションに進んでしまうのは適切か...？
  return false;
}

export function generateActionLottery() {
  return new ActionLottery();
}
