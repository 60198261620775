// 区分
export type Kbn =
  'CONTENT_TYPE_' /* コンテントタイプ区分 */ |
  'ACTION_TYPE_' /* アクションタイプ区分 */ |
  'ASSET_TYPE_' /* アセットタイプ区分 */ |
  'LP_TARGET_' /* LPターゲット区分 */ |
  'CONTENT_AT_' /* コンテント日時区分 */;

// コンテントタイプ区分
export type ContentTypeKbn =
  'CONTENT_TYPE_TEXT_EDITOR' /* テキストエディタ */ |
  'CONTENT_TYPE_LINK' /* リンク */ |
  'CONTENT_TYPE_BUTTON' /* ボタン */ |
  'CONTENT_TYPE_IMAGE' /* 画像 */ |
  'CONTENT_TYPE_VIDEO' /* 動画 */ |
  'CONTENT_TYPE_ITEMIZED_LIST' /* 箇条書きリスト */ |
  'CONTENT_TYPE_CHECKBOX' /* チェックボックス */ |
  'CONTENT_TYPE_ACCORDION' /* アコーディオン */ |
  'CONTENT_TYPE_TRACKING_ID' /* 問い合わせID */ |
  'CONTENT_TYPE_QR_CODE_SHOW' /* QRコード表示 */ |
  'CONTENT_TYPE_QR_CODE_READING' /* QRコード読み込み */ |
  'CONTENT_TYPE_FOOTER' /* フッター */ |
  'CONTENT_TYPE_HORIZONTAL_LAYOUT' /* 水平レイアウト */ |
  'CONTENT_TYPE_GROUP' /* グループ */ |
  'CONTENT_TYPE_SURVEY_INPUT' /* アンケート入力 */ |
  'CONTENT_TYPE_SURVEY_CONFIRM' /* アンケート確認 */ |
  'CONTENT_TYPE_RECEIPT_PREVIEW' /* レシートプレビュー */ |
  'CONTENT_TYPE_CUSTOMIZING' /* カスタマイズ */;

// アクションタイプ区分
export type ActionTypeKbn =
  'ACTION_TYPE_REDIRECT' /* 画面遷移 */ |
  'ACTION_TYPE_EXTERNAL_REDIRECT' /* 外部画面遷移 */ |
  'ACTION_TYPE_DIALOG_SHOW' /* ダイアログの表示 */ |
  'ACTION_TYPE_DIALOG_CLOSE' /* ダイアログの非表示 */ |
  'ACTION_TYPE_LOTTERY' /* 抽選 */ |
  'ACTION_TYPE_CHECK_OFF_HOUR' /* 期間外チェック */ |
  'ACTION_TYPE_CHECK_DIRECT_ACCESS' /* 直接アクセスチェック */ |
  'ACTION_TYPE_CHECK_FRIEND' /* 友だちチェック */ |
  'ACTION_TYPE_PLAY_SOUND' /* 音再生 */ |
  'ACTION_TYPE_STATE_SAVE' /* state保存 */ |
  'ACTION_TYPE_STATE_CLEAR' /* stateクリア */ |
  'ACTION_TYPE_STATE_HANDLE' /* stateハンドリング */ |
  'ACTION_TYPE_LOGIN_CHECKIN' /* ログインチェックイン */ |
  'ACTION_TYPE_SURVEY_CHECK' /* アンケートチェック */ |
  'ACTION_TYPE_RECEIPT_PHOTOGRAPHING' /* レシート撮影 */ |
  'ACTION_TYPE_RECEIPT_UPLOAD' /* レシートアップロード */ |
  'ACTION_TYPE_CUSTOMIZING' /* カスタマイズ */;

// アセットタイプ区分
export type AssetTypeKbn =
  'ASSET_TYPE_IMAGE' /* image */ |
  'ASSET_TYPE_VIDEO' /* video */ |
  'ASSET_TYPE_MUSIC' /* music */ |
  'ASSET_TYPE_FONT' /* font */ |
  'ASSET_TYPE_FAVICON' /* favicon */;

// LPターゲット区分
export type LpTargetKbn =
  'LP_TARGET_WEB' /* WEBのみ（旧） */ |
  'LP_TARGET_LIFF' /* LIFFのみ（旧） */ |
  'LP_TARGET_WEB_LIFF' /* LIFF＆WEB（旧） */ |
  'LP_TARGET_NORMAL_WEB_ANY' /* WEB */ |
  'LP_TARGET_NORMAL_LIFF_ANY' /* LIFF */ |
  'LP_TARGET_NORMAL_LIFF_MOBILE' /* LIFF（モバイル） */ |
  'LP_TARGET_LINE_LIFF_MOBILE' /* LIFFブラウザ限定（モバイル） */;

// コンテント日時区分
export type ContentAtKbn =
  'CONTENT_AT_UNSET' /* 設定しない */ |
  'CONTENT_AT_CUSTOM' /* カスタマイズ */;

export const KbnConst = {

  // コンテントタイプ区分
  CONTENT_TYPE_KBN: 'CONTENT_TYPE_' as Kbn,

  // コンテントタイプ区分(テキストエディタ)
  CONTENT_TYPE_KBN_TEXT_EDITOR: 'CONTENT_TYPE_TEXT_EDITOR',

  // コンテントタイプ区分(リンク)
  CONTENT_TYPE_KBN_LINK: 'CONTENT_TYPE_LINK',

  // コンテントタイプ区分(ボタン)
  CONTENT_TYPE_KBN_BUTTON: 'CONTENT_TYPE_BUTTON',

  // コンテントタイプ区分(画像)
  CONTENT_TYPE_KBN_IMAGE: 'CONTENT_TYPE_IMAGE',

  // コンテントタイプ区分(動画)
  CONTENT_TYPE_KBN_VIDEO: 'CONTENT_TYPE_VIDEO',

  // コンテントタイプ区分(箇条書きリスト)
  CONTENT_TYPE_KBN_ITEMIZED_LIST: 'CONTENT_TYPE_ITEMIZED_LIST',

  // コンテントタイプ区分(チェックボックス)
  CONTENT_TYPE_KBN_CHECKBOX: 'CONTENT_TYPE_CHECKBOX',

  // コンテントタイプ区分(アコーディオン)
  CONTENT_TYPE_KBN_ACCORDION: 'CONTENT_TYPE_ACCORDION',

  // コンテントタイプ区分(問い合わせID)
  CONTENT_TYPE_KBN_TRACKING_ID: 'CONTENT_TYPE_TRACKING_ID',

  // コンテントタイプ区分(QRコード表示)
  CONTENT_TYPE_KBN_QR_CODE_SHOW: 'CONTENT_TYPE_QR_CODE_SHOW',

  // コンテントタイプ区分(QRコード読み込み)
  CONTENT_TYPE_KBN_QR_CODE_READING: 'CONTENT_TYPE_QR_CODE_READING',

  // コンテントタイプ区分(フッター)
  CONTENT_TYPE_KBN_FOOTER: 'CONTENT_TYPE_FOOTER',

  // コンテントタイプ区分(水平レイアウト)
  CONTENT_TYPE_KBN_HORIZONTAL_LAYOUT: 'CONTENT_TYPE_HORIZONTAL_LAYOUT',

  // コンテントタイプ区分(グループ)
  CONTENT_TYPE_KBN_GROUP: 'CONTENT_TYPE_GROUP',

  // コンテントタイプ区分(アンケート入力)
  CONTENT_TYPE_KBN_SURVEY_INPUT: 'CONTENT_TYPE_SURVEY_INPUT',

  // コンテントタイプ区分(アンケート確認)
  CONTENT_TYPE_KBN_SURVEY_CONFIRM: 'CONTENT_TYPE_SURVEY_CONFIRM',

  // コンテントタイプ区分(レシートプレビュー)
  CONTENT_TYPE_KBN_RECEIPT_PREVIEW: 'CONTENT_TYPE_RECEIPT_PREVIEW',

  // コンテントタイプ区分(カスタマイズ)
  CONTENT_TYPE_KBN_CUSTOMIZING: 'CONTENT_TYPE_CUSTOMIZING',

  // アクションタイプ区分
  ACTION_TYPE_KBN: 'ACTION_TYPE_' as Kbn,

  // アクションタイプ区分(画面遷移)
  ACTION_TYPE_KBN_REDIRECT: 'ACTION_TYPE_REDIRECT',

  // アクションタイプ区分(外部画面遷移)
  ACTION_TYPE_KBN_EXTERNAL_REDIRECT: 'ACTION_TYPE_EXTERNAL_REDIRECT',

  // アクションタイプ区分(ダイアログの表示)
  ACTION_TYPE_KBN_DIALOG_SHOW: 'ACTION_TYPE_DIALOG_SHOW',

  // アクションタイプ区分(ダイアログの非表示)
  ACTION_TYPE_KBN_DIALOG_CLOSE: 'ACTION_TYPE_DIALOG_CLOSE',

  // アクションタイプ区分(抽選)
  ACTION_TYPE_KBN_LOTTERY: 'ACTION_TYPE_LOTTERY',

  // アクションタイプ区分(期間外チェック)
  ACTION_TYPE_KBN_CHECK_OFF_HOUR: 'ACTION_TYPE_CHECK_OFF_HOUR',

  // アクションタイプ区分(直接アクセスチェック)
  ACTION_TYPE_KBN_CHECK_DIRECT_ACCESS: 'ACTION_TYPE_CHECK_DIRECT_ACCESS',

  // アクションタイプ区分(友だちチェック)
  ACTION_TYPE_KBN_CHECK_FRIEND: 'ACTION_TYPE_CHECK_FRIEND',

  // アクションタイプ区分(音再生)
  ACTION_TYPE_KBN_PLAY_SOUND: 'ACTION_TYPE_PLAY_SOUND',

  // アクションタイプ区分(state保存)
  ACTION_TYPE_KBN_STATE_SAVE: 'ACTION_TYPE_STATE_SAVE',

  // アクションタイプ区分(stateクリア)
  ACTION_TYPE_KBN_STATE_CLEAR: 'ACTION_TYPE_STATE_CLEAR',

  // アクションタイプ区分(stateハンドリング)
  ACTION_TYPE_KBN_STATE_HANDLE: 'ACTION_TYPE_STATE_HANDLE',

  // アクションタイプ区分(ログインチェックイン)
  ACTION_TYPE_KBN_LOGIN_CHECKIN: 'ACTION_TYPE_LOGIN_CHECKIN',

  // アクションタイプ区分(アンケートチェック)
  ACTION_TYPE_KBN_SURVEY_CHECK: 'ACTION_TYPE_SURVEY_CHECK',

  // アクションタイプ区分(レシート撮影)
  ACTION_TYPE_KBN_RECEIPT_PHOTOGRAPHING: 'ACTION_TYPE_RECEIPT_PHOTOGRAPHING',

  // アクションタイプ区分(レシートアップロード)
  ACTION_TYPE_KBN_RECEIPT_UPLOAD: 'ACTION_TYPE_RECEIPT_UPLOAD',

  // アクションタイプ区分(カスタマイズ)
  ACTION_TYPE_KBN_CUSTOMIZING: 'ACTION_TYPE_CUSTOMIZING',

  // アセットタイプ区分
  ASSET_TYPE_KBN: 'ASSET_TYPE_' as Kbn,

  // アセットタイプ区分(image)
  ASSET_TYPE_KBN_IMAGE: 'ASSET_TYPE_IMAGE',

  // アセットタイプ区分(video)
  ASSET_TYPE_KBN_VIDEO: 'ASSET_TYPE_VIDEO',

  // アセットタイプ区分(music)
  ASSET_TYPE_KBN_MUSIC: 'ASSET_TYPE_MUSIC',

  // アセットタイプ区分(font)
  ASSET_TYPE_KBN_FONT: 'ASSET_TYPE_FONT',

  // アセットタイプ区分(favicon)
  ASSET_TYPE_KBN_FAVICON: 'ASSET_TYPE_FAVICON',

  // LPターゲット区分
  LP_TARGET_KBN: 'LP_TARGET_' as Kbn,

  // LPターゲット区分(WEBのみ（旧）)
  LP_TARGET_KBN_WEB: 'LP_TARGET_WEB',

  // LPターゲット区分(LIFFのみ（旧）)
  LP_TARGET_KBN_LIFF: 'LP_TARGET_LIFF',

  // LPターゲット区分(LIFF＆WEB（旧）)
  LP_TARGET_KBN_WEB_LIFF: 'LP_TARGET_WEB_LIFF',

  // LPターゲット区分(WEB)
  LP_TARGET_KBN_NORMAL_BROWSER_DEVICE_ANY: 'LP_TARGET_NORMAL_WEB_ANY',

  // LPターゲット区分(LIFF)
  LP_TARGET_KBN_NORMAL_BROWSER_LIFF_DEVICE_ANY: 'LP_TARGET_NORMAL_LIFF_ANY',

  // LPターゲット区分(LIFF（モバイル）)
  LP_TARGET_KBN_NORMAL_BROWSER_LIFF_DEVICE_MOBILE: 'LP_TARGET_NORMAL_LIFF_MOBILE',

  // LPターゲット区分(LIFFブラウザ限定（モバイル）)
  LP_TARGET_KBN_LINE_BROWSER_LIFF_DEVICE_MOBILE: 'LP_TARGET_LINE_LIFF_MOBILE',

  // コンテント日時区分
  CONTENT_AT_KBN: 'CONTENT_AT_' as Kbn,

  // コンテント日時区分(設定しない)
  CONTENT_AT_KBN_UNSET: 'CONTENT_AT_UNSET',

  // コンテント日時区分(カスタマイズ)
  CONTENT_AT_KBN_CUSTOM: 'CONTENT_AT_CUSTOM',
} as const;

export const KbnValues = {

  // コンテントタイプ区分
  'CONTENT_TYPE_': [
    'CONTENT_TYPE_TEXT_EDITOR', // テキストエディタ
    'CONTENT_TYPE_LINK', // リンク
    'CONTENT_TYPE_BUTTON', // ボタン
    'CONTENT_TYPE_IMAGE', // 画像
    'CONTENT_TYPE_VIDEO', // 動画
    'CONTENT_TYPE_ITEMIZED_LIST', // 箇条書きリスト
    'CONTENT_TYPE_CHECKBOX', // チェックボックス
    'CONTENT_TYPE_ACCORDION', // アコーディオン
    'CONTENT_TYPE_TRACKING_ID', // 問い合わせID
    'CONTENT_TYPE_QR_CODE_SHOW', // QRコード表示
    'CONTENT_TYPE_QR_CODE_READING', // QRコード読み込み
    'CONTENT_TYPE_FOOTER', // フッター
    'CONTENT_TYPE_HORIZONTAL_LAYOUT', // 水平レイアウト
    'CONTENT_TYPE_GROUP', // グループ
    'CONTENT_TYPE_SURVEY_INPUT', // アンケート入力
    'CONTENT_TYPE_SURVEY_CONFIRM', // アンケート確認
    'CONTENT_TYPE_RECEIPT_PREVIEW', // レシートプレビュー
    'CONTENT_TYPE_CUSTOMIZING', // カスタマイズ
  ],

  // アクションタイプ区分
  'ACTION_TYPE_': [
    'ACTION_TYPE_REDIRECT', // 画面遷移
    'ACTION_TYPE_EXTERNAL_REDIRECT', // 外部画面遷移
    'ACTION_TYPE_DIALOG_SHOW', // ダイアログの表示
    'ACTION_TYPE_DIALOG_CLOSE', // ダイアログの非表示
    'ACTION_TYPE_LOTTERY', // 抽選
    'ACTION_TYPE_CHECK_OFF_HOUR', // 期間外チェック
    'ACTION_TYPE_CHECK_DIRECT_ACCESS', // 直接アクセスチェック
    'ACTION_TYPE_CHECK_FRIEND', // 友だちチェック
    'ACTION_TYPE_PLAY_SOUND', // 音再生
    'ACTION_TYPE_STATE_SAVE', // state保存
    'ACTION_TYPE_STATE_CLEAR', // stateクリア
    'ACTION_TYPE_STATE_HANDLE', // stateハンドリング
    'ACTION_TYPE_LOGIN_CHECKIN', // ログインチェックイン
    'ACTION_TYPE_SURVEY_CHECK', // アンケートチェック
    'ACTION_TYPE_RECEIPT_PHOTOGRAPHING', // レシート撮影
    'ACTION_TYPE_RECEIPT_UPLOAD', // レシートアップロード
    'ACTION_TYPE_CUSTOMIZING', // カスタマイズ
  ],

  // アセットタイプ区分
  'ASSET_TYPE_': [
    'ASSET_TYPE_IMAGE', // image
    'ASSET_TYPE_VIDEO', // video
    'ASSET_TYPE_MUSIC', // music
    'ASSET_TYPE_FONT', // font
    'ASSET_TYPE_FAVICON', // favicon
  ],

  // LPターゲット区分
  'LP_TARGET_': [
    'LP_TARGET_WEB', // WEBのみ（旧）
    'LP_TARGET_LIFF', // LIFFのみ（旧）
    'LP_TARGET_WEB_LIFF', // LIFF＆WEB（旧）
    'LP_TARGET_NORMAL_WEB_ANY', // WEB
    'LP_TARGET_NORMAL_LIFF_ANY', // LIFF
    'LP_TARGET_NORMAL_LIFF_MOBILE', // LIFF（モバイル）
    'LP_TARGET_LINE_LIFF_MOBILE', // LIFFブラウザ限定（モバイル）
  ],

  // コンテント日時区分
  'CONTENT_AT_': [
    'CONTENT_AT_UNSET', // 設定しない
    'CONTENT_AT_CUSTOM', // カスタマイズ
  ],
} as const;
