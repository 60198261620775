import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["maxlength", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "overflow-hidden invisible whitespace-pre-wrap break-words w-full px-1 pt-1",
        style: {"min-height":"4.5rem"},
        textContent: _toDisplayString(_ctx.internalText)
      }, null, 8, _hoisted_2),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
        style: {"min-height":"4.5rem"},
        maxlength: _ctx.max || undefined,
        class: _normalizeClass([
          'absolute top-0 left-0',
          'h-full w-full overflow-hidden resize-none px-2 py-1 outline-none',
          'rounded-md border border-gray-400',
          'placeholder-gray-400'
        ]),
        placeholder: _ctx.placeholder
      }, null, 8, _hoisted_3), [
        [
          _vModelText,
          _ctx.text,
          void 0,
          { trim: true }
        ]
      ])
    ])
  ]))
}