import { Type } from 'class-transformer';

import { ActionBase } from '@/models/action_base';
import { ActionTypeKbn } from '@/utils/kbn';

type TypeActionMap = {
  value: typeof ActionBase;
  name: ActionTypeKbn;
};

// 循環参照の問題が発生するのでここでデータを保持する
const typeActionMaps: TypeActionMap[] = [];

export default function TypeAction() {
  return function(target: object, propertyKey: string) {
    Type(() => ActionBase, {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: 'typeKbn',
        subTypes: typeActionMaps,
      },
    })(target, propertyKey);
  };
}

/**
 * クラス変換用のマッピングを設定する
 */
export function setupTypeAction(from: TypeActionMap[]) {
  // 配列の全データを追加しなおす
  typeActionMaps.splice(0);
  typeActionMaps.push(...from);
}
