import { App } from 'vue';

const RouterConst = {
  Base: 'base',
  Error: 'error',
  OffHour: 'off_hour',
  OffHourBefore: 'off_hour_before',
};

export default RouterConst;

export const RouterConstInstall = {
  install: (app: App) => {
    app.config.globalProperties.$routerConst = RouterConst;
  },
};

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $routerConst: typeof RouterConst // グローバル変数の型定義
  }
}
