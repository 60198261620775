import { Transform } from 'class-transformer';

import TypeAction from '@/decorators/type_action';
import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useActions } from './use_actions';
import { useState } from './use_state';

interface State {
  stateKey: string,
  stateValue: string,
  condition: 'equal' | 'not_equal'
}

export class ActionStateHandle extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_STATE_HANDLE;

  states: State[] = [];

  @TypeAction()
  @Transform((params) => params.value || [])
  handleActions: ActionBase[] = [];

  use() {
    const stateData = useState();

    const onHandleActions = useActions(this.handleActions);

    return async () => await execute(this.states, stateData, onHandleActions);
  }

  constructor(init: Partial<ActionStateHandle> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  states: State[],
  stateData: ReturnType<typeof useState>,
  onHandleActions: ReturnType<typeof useActions>,
) {

  // 設定したStateは全部ある場合、アクションを実行します。
  if (states.every(state => {
    const dataValue = stateData.value[state.stateKey] ?? '';
    const stateValue = state.stateValue ?? '';

    if (state.condition === 'not_equal') {
      return stateValue !== dataValue;
    }

    return stateValue === dataValue;
  })) {
    await onHandleActions();
  }

  return false;
}

export function generateActionStateHandle() {
  return new ActionStateHandle({
    states: [],
  });
}
