import { setupTypeAction } from '@/decorators/type_action';
import { ActionCheckDirectAccess } from '@/hooks/use_action_check_direct_access';
import { ActionCheckFriend } from '@/hooks/use_action_check_friend';
import { ActionCheckOffHour } from '@/hooks/use_action_check_off_hour';
import { ActionCustomizing } from '@/hooks/use_action_customizing';
import { ActionDialogClose } from '@/hooks/use_action_dialog_close';
import { ActionDialogShow } from '@/hooks/use_action_dialog_show';
import { ActionExternalRedirect } from '@/hooks/use_action_external_redirect';
import { ActionLoginCheckin } from '@/hooks/use_action_login_checkin';
import { ActionLottery } from '@/hooks/use_action_lottery';
import { ActionPlaySound } from '@/hooks/use_action_play_sound';
import { ActionReceiptPhotographing } from '@/hooks/use_action_receipt_photographing';
import { ActionReceiptUpload } from '@/hooks/use_action_receipt_upload';
import { ActionRedirect } from '@/hooks/use_action_redirect';
import { ActionStateClear } from '@/hooks/use_action_state_clear';
import { ActionStateHandle } from '@/hooks/use_action_state_handle';
import { ActionStateSave } from '@/hooks/use_action_state_save';
import { ActionSurveyCheck } from '@/hooks/use_action_survey_check';
import { ActionTypeKbn, KbnConst } from '@/utils/kbn';

import { Equal, Expect } from '../types/util';

export function setupActions() {
  const actions = [
    {
      name: KbnConst.ACTION_TYPE_KBN_REDIRECT,
      value: ActionRedirect,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_EXTERNAL_REDIRECT,
      value: ActionExternalRedirect,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_DIALOG_SHOW,
      value: ActionDialogShow,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_DIALOG_CLOSE,
      value: ActionDialogClose,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_LOTTERY,
      value: ActionLottery,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_CHECK_OFF_HOUR,
      value: ActionCheckOffHour,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_CHECK_DIRECT_ACCESS,
      value: ActionCheckDirectAccess,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_CHECK_FRIEND,
      value: ActionCheckFriend,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_PLAY_SOUND,
      value: ActionPlaySound,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_STATE_SAVE,
      value: ActionStateSave,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_STATE_CLEAR,
      value: ActionStateClear,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_STATE_HANDLE,
      value: ActionStateHandle,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_LOGIN_CHECKIN,
      value: ActionLoginCheckin,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_SURVEY_CHECK,
      value: ActionSurveyCheck,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_RECEIPT_PHOTOGRAPHING,
      value: ActionReceiptPhotographing,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_RECEIPT_UPLOAD,
      value: ActionReceiptUpload,
    },
    {
      name: KbnConst.ACTION_TYPE_KBN_CUSTOMIZING,
      value: ActionCustomizing,
    },
  ];

  // アクションの列挙漏れがあったときに型エラーにする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type Check = Expect<Equal<ActionTypeKbn, (typeof actions)[number]['name']>>;

  setupTypeAction(actions);
}
