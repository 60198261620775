
import { defineAsyncComponent, defineComponent, PropType } from 'vue';

import { ContentHorizontalLayoutModel } from '@/models/content_horizontal_layout_model';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentHorizontalLayout',
  components: {
    BaseStyle,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Content: defineAsyncComponent((): any => import('./Content.vue')),
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentHorizontalLayoutModel>,
      required: true,
    },
  },
});
