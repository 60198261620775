import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyInputMultiSelect = _resolveComponent("SurveyInputMultiSelect")!
  const _component_SurveyInputText = _resolveComponent("SurveyInputText")!
  const _component_SurveyInputTextarea = _resolveComponent("SurveyInputTextarea")!
  const _component_SurveyInputBirthday = _resolveComponent("SurveyInputBirthday")!
  const _component_SurveyInputSelect = _resolveComponent("SurveyInputSelect")!
  const _component_SurveyInputCheckbox = _resolveComponent("SurveyInputCheckbox")!
  const _component_SurveyInputRadio = _resolveComponent("SurveyInputRadio")!

  return (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_LIST)
    ? (_openBlock(), _createBlock(_component_SurveyInputMultiSelect, {
        key: 0,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        options: _ctx.options,
        max: _ctx.item.contentJson.validations.maxSelectableCount,
        placeholder: _ctx.remarks
      }, null, 8, ["modelValue", "options", "max", "placeholder"]))
    : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_SINGLE_LINE_TEXT)
      ? (_openBlock(), _createBlock(_component_SurveyInputText, {
          key: 1,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          max: _ctx.item.contentJson.validations.maxLength,
          placeholder: _ctx.remarks
        }, null, 8, ["modelValue", "max", "placeholder"]))
      : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_MULTI_LINES_TEXT)
        ? (_openBlock(), _createBlock(_component_SurveyInputTextarea, {
            key: 2,
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
            max: _ctx.item.contentJson.validations.maxLength,
            placeholder: _ctx.remarks
          }, null, 8, ["modelValue", "max", "placeholder"]))
        : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_BIRTHDAY)
          ? (_openBlock(), _createBlock(_component_SurveyInputBirthday, {
              key: 3,
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
              placeholder: _ctx.remarks,
              required: _ctx.item.required
            }, null, 8, ["modelValue", "placeholder", "required"]))
          : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_SINGLE_LIST)
            ? (_openBlock(), _createBlock(_component_SurveyInputSelect, {
                key: 4,
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event)),
                options: _ctx.options,
                placeholder: _ctx.remarks,
                required: _ctx.item.required
              }, null, 8, ["modelValue", "options", "placeholder", "required"]))
            : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_CHECKBOX)
              ? (_openBlock(), _createBlock(_component_SurveyInputCheckbox, {
                  key: 5,
                  modelValue: _ctx.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value) = $event)),
                  options: _ctx.options,
                  max: _ctx.item.contentJson.validations.maxSelectableCount,
                  placeholder: _ctx.remarks
                }, null, 8, ["modelValue", "options", "max", "placeholder"]))
              : (_ctx.item.uiKbn === _ctx.$crmKbnConst.UI_KBN_RADIO_BUTTON)
                ? (_openBlock(), _createBlock(_component_SurveyInputRadio, {
                    key: 6,
                    modelValue: _ctx.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value) = $event)),
                    options: _ctx.options,
                    placeholder: _ctx.remarks
                  }, null, 8, ["modelValue", "options", "placeholder"]))
                : _createCommentVNode("", true)
}