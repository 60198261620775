/* eslint-disable @typescript-eslint/ban-types */
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export type RecursivePartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
      T[P];
};

export function withClone<T>(instance: T, patch: RecursivePartial<T> = {}): T {
  return merge(cloneDeep(instance), patch);
}

/**
 * データ系クラスを扱いやすくするメタクラス。
 */
export abstract class Model<T> {
  /**
   * 新しいデータを生成し、パッチを再帰的に当てはめる。
   *
   * ```ts
   * this != this.with()
   * ```
   *
   * @param patch 新しいデータ。`undefined`はコピーされない。
   * @returns 新しいデータ、変化済み。
   */
  with(patch: RecursivePartial<T>) {
    const prototype = Object.getPrototypeOf(this);
    const instance: T = new prototype.constructor();

    return merge(instance, this, patch);
  }

  /**
   * `with({})`の省略。
   *
   * @returns このモデルのコピー。
   */
  clone() {
    // eslint-disable-next-line object-curly-newline
    return this.with({});
  }
}
