import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useFileUpload } from './use_file_upload';
import { usePopupDialog } from './use_popup_dialog';

export class ActionReceiptPhotographing extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_RECEIPT_PHOTOGRAPHING;

  selectImageBy: 'SELECTING_FILE' | 'PHOTOGRAPHING' = 'PHOTOGRAPHING';

  maxFileMegabytes: number = 5;

  use() {
    return async () => await execute(
      this.selectImageBy,
      this.maxFileMegabytes,
    );
  }

  constructor(init: Partial<ActionReceiptPhotographing> = {}) {
    super();
    Object.assign(this, init);
  }
}

function isJpgOrPng(file: File): boolean {
  if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
    return true;
  } else {
    return false;
  }
}

async function execute(
  selectImageBy: 'SELECTING_FILE' | 'PHOTOGRAPHING',
  maxFileMegabytes: number,
) {
  const fileUpload = useFileUpload();
  const popupDialog = usePopupDialog();

  if (selectImageBy === 'SELECTING_FILE') {
    if (await fileUpload.uploadPicture()) {
      return true;
    }
  } else if (selectImageBy === 'PHOTOGRAPHING') {
    if (await fileUpload.takePicture()) {
      return true;
    }
  }

  const file = fileUpload.fileCache;
  if (!file.value) {
    return true;
  }

  const maxFileSize = maxFileMegabytes * 1024 * 1024;
  if (file.value.size > maxFileSize) {
    popupDialog.alert(`${maxFileMegabytes}MB以下のファイルをアップしてください`);
    fileUpload.fileCache.value = undefined;
    return true;
  }
  if (!isJpgOrPng(file.value)) {
    await popupDialog.alert('JPEG画像またはPNG画像をアップロードしてください');
    fileUpload.fileCache.value = undefined;
    return true;
  }
  return false;
}

export function generateActionReceiptPhotographing() {
  return new ActionReceiptPhotographing();
}
