import { Transform, Type } from 'class-transformer';
import repeat from 'lodash/repeat';

import { HandlebarsRender } from '@/decorators/handlebars_render';
import TypeAction from '@/decorators/type_action';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ActionBase } from './action_base';
import { ContentBase } from './content_base';
import { withClone } from './model';

export const ConnectActionControl = {
  linkRegex: /href="action___(.*?)___action"/g,
  linkClass: (key: string) => `class="connection-key-tag action___${key}___action"`,
  classQuerySelector: (key: string) => `.connection-key-tag.action___${key}___action`,
};

export class ConnectAction {
  key: string = '';

  @TypeAction()
  @Transform((params) => params.value || [])
  actions: ActionBase[] = [];
}

export class ContentTextEditorModel extends ContentBase {
  @HandlebarsRender()
  text?: string;

  @Type(() => ConnectAction)
  @Transform((params) => params.value || [])
  connectActions: ConnectAction[] = [];

  constructor(init?: Partial<ContentTextEditorModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_TEXT_EDITOR; // クラスに対して区分値固定なので、コンストラクタで代入
    Object.assign(this, init);
  }

  static generate(patch: Partial<ContentTextEditorModel> = {}) {
    const instance = new ContentTextEditorModel({
      text: 'テキスト',
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  ContentTextEditorModel,
  ({ size, style }) => new ContentTextEditorModel({
    text: repeat('任意の文字列\n改行\n    スペース    ', size),
    typeKbn: KbnConst.CONTENT_TYPE_KBN_TEXT_EDITOR,
    style,
  }),
);
