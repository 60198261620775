import { useLocalStorage } from '@vueuse/core';

import { LS_TRACKING_ID } from '@/utils/ls_const';

// この useLocalStorage は setup の文脈ではないが、動作は確認している。
const trackingId = useLocalStorage(LS_TRACKING_ID, '', {
  listenToStorageChanges: false, /// マルチタブ対策
});

/**
 * シンプルなストア TrackingIdの共有
 * @returns TrackingIdのrefオブジェクト
 */
export function useTrackingId() {
  return trackingId;
}
