import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-2 bg-white text-black survey-style" }
const _hoisted_2 = { class: "px-2 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyHeader = _resolveComponent("SurveyHeader")!
  const _component_SurveyInput = _resolveComponent("SurveyInput")!
  const _component_SurveyErrorText = _resolveComponent("SurveyErrorText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.uuid
      }, [
        _createVNode(_component_SurveyHeader, {
          required: item.required
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.questionContent), 1)
          ]),
          _: 2
        }, 1032, ["required"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SurveyInput, {
            modelValue: _ctx.answers[item.uuid],
            "onUpdate:modelValue": ($event: any) => ((_ctx.answers[item.uuid]) = $event),
            item: item,
            remarks: _ctx.surveyPatternItemTypeRemarks(item),
            class: "mt-2"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "item", "remarks"]),
          _createVNode(_component_SurveyErrorText, {
            class: "mt-1 ml-1",
            text: _ctx.errors[item.uuid]
          }, null, 8, ["text"])
        ])
      ]))
    }), 128))
  ]))
}