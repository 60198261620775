import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f1293d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: _ctx.variant == 'default' ? 'base-icon' : 'base-icon-white',
    style: {
      width: `${_ctx.size}px`,
      height: `${_ctx.size}px`,
      transform: `rotate(${_ctx.rotate}deg)`,
    }
  }, _ctx.$attrs, { innerHTML: _ctx.data }), null, 16, _hoisted_1))
}