
import { defineAsyncComponent, defineComponent, PropType, ref, watch } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { ContentCheckboxModel } from '@/models/content_checkbox_model';

import BaseCheckbox from './BaseCheckbox.vue';
import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentCheckbox',
  components: {
    BaseStyle,
    BaseCheckbox,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Content: defineAsyncComponent((): any => import('./Content.vue')),
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentCheckboxModel>,
      required: true,
    },
  },
  setup(props) {
    const check = ref(false);
    const executeCheckedAction = useActions(props.model.checkedActions);
    const executeUnCheckedAction = useActions(props.model.unCheckedActions);
    watch(check, async () => {
      if (check.value) {
        await executeCheckedAction();
      } else {
        await executeUnCheckedAction();
      }
    }, {
      immediate: true,
    });
    return {
      check,
    };
  },
});
