import { Transform, Type } from 'class-transformer';

import { Medium } from '@/models/medium';
import { generate, registerGenerator } from '@/utils/generate';
import { KbnConst, LpTargetKbn } from '@/utils/kbn';

import { Dialog } from './dialog';
import { Group } from './group';
import { Meta } from './meta';
import { Model } from './model';
import { Page } from './page';
import { StyleTemplate } from './style_template';

export interface CustomData {
  lpTarget?: LpTargetKbn
}

export class LandingPage extends Model<LandingPage> {
  target?: LpTargetKbn;

  favicon?: string;

  defaultTitle?: string;

  rootPageKey?: string;

  customData: CustomData = {};

  @Type(() => Meta)
  @Transform((params) => params.value || [])
  meta: Meta[] = [];

  @Type(() => Page)
  @Transform((params) => params.value || [])
  pages: Page[] = [];

  @Type(() => Dialog)
  @Transform((params) => params.value || [])
  dialogs: Dialog[] = [];

  @Type(() => StyleTemplate)
  @Transform((params) => params.value || [])
  styleTemplates: StyleTemplate[] = [];

  @Type(() => Group)
  @Transform((params) => params.value || [])
  groups: Group[] = [];

  @Type(() => Medium)
  ogImageSrc?: Medium;

  isTargetNormalBrowserDeviceAny() {
    return this.customData.lpTarget === KbnConst.LP_TARGET_KBN_NORMAL_BROWSER_DEVICE_ANY;
  }

  isTargetNormalBrowserLiffDeviceAny() {
    return this.customData.lpTarget === KbnConst.LP_TARGET_KBN_NORMAL_BROWSER_LIFF_DEVICE_ANY;
  }

  isTargetNormalBrowserLiffDeviceMobile() {
    return this.customData.lpTarget === KbnConst.LP_TARGET_KBN_NORMAL_BROWSER_LIFF_DEVICE_MOBILE;
  }

  isTargetLineBrowserLiffDeviceMobile() {
    return this.customData.lpTarget === KbnConst.LP_TARGET_KBN_LINE_BROWSER_LIFF_DEVICE_MOBILE;
  }

  isTargetLiff() {
    return  this.isTargetLineBrowserLiffDeviceMobile()
          || this.isTargetNormalBrowserLiffDeviceMobile()
          || this.isTargetNormalBrowserLiffDeviceAny();
  }

  isTargetWeb() {
    return this.isTargetNormalBrowserDeviceAny();
  }

  isTargetMobile() {
    return  this.isTargetLineBrowserLiffDeviceMobile()
    || this.isTargetNormalBrowserLiffDeviceMobile() ;
  }

  isTargetLiffBrowser() {
    return this.isTargetLineBrowserLiffDeviceMobile();
  }

  get rootPage() {
    return this.pages.find(page => page.uniqueKey === this.rootPageKey);
  }

  constructor(init?: Partial<LandingPage>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  LandingPage,
  (props) => new LandingPage({
    target: KbnConst.LP_TARGET_KBN_WEB,
    favicon: 'favicon.icon',
    defaultTitle: 'defaultTitle',
    rootPageKey: 'd0a44b32-7bb2-4277-b30b-b7c4d85666fd',
    pages: [
      generate(Page, props).with({
        uniqueKey: 'd0a44b32-7bb2-4277-b30b-b7c4d85666fd',
        path: 'page1',
      }),
      generate(Page, props).with({
        uniqueKey: 'b6a2bd93-fa90-4f39-a92d-055499ce52fd',
        path: 'page2',
      }),
    ],
  }),
);
