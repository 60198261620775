import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';
import { openExternalUrl } from '@/utils/open_external_url';

/**
 * 外部遷移する
 *
 */

type OpenType = 'current_tab' | 'new_tab';

export class ActionExternalRedirect extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_EXTERNAL_REDIRECT;

  url?: string;

  openIn: OpenType = 'current_tab';

  use() {
    return async (url?: string) => await execute(this.openIn, url || this.url);
  }

  constructor(init: Partial<ActionExternalRedirect> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  openIn: OpenType,
  url?: string,
) {
  openExternalUrl(url, openIn === 'new_tab');
  return false;
}

export function generateActionExternalRedirect(url?: string) {
  return new ActionExternalRedirect({ url });
}
