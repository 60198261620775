import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox flex select-none space-x-2 items-center", [
      _ctx.disabled || 'cursor-pointer',
      _ctx.position === 'left' && 'justify-start',
      _ctx.position === 'center' && 'justify-center',
    ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["border-2 rounded border-current", _ctx.checked && _ctx.variant == 'white' && 'bg-current'])
    }, [
      _createElementVNode("div", {
        class: "flex justify-center items-center",
        style: _normalizeStyle({
          height: `${_ctx.checkboxSize}px`,
          width: `${_ctx.checkboxSize}px`
        })
      }, [
        (_ctx.checked)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              variant: _ctx.variant,
              size: _ctx.checkboxSize / 2,
              icon: 'check'
            }, null, 8, ["variant", "size"]))
          : _createCommentVNode("", true)
      ], 4)
    ], 2),
    (_ctx.hasContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}