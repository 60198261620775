import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    model: _ctx.model,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          id: _ctx.qrReaderId,
          class: "w-full"
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}