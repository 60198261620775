
import dayjs from 'dayjs';
import range from 'lodash/range';
import { computed, defineComponent, PropType, toRefs } from 'vue';

import BaseSelectOption from '@/components/BaseSelectOption.vue';
import UnderlineSelect from '@/components/UnderlineSelect.vue';
import { Birthday } from '@/types/Birthday';

export default defineComponent({
  name: 'SurveyInputBirthday',
  components: {
    BaseSelectOption,
    UnderlineSelect,
  },
  props: {

    /**
     *  誕生日の日付
     */
    modelValue: {
      type: Object as PropType<Birthday>,
      default: () => ({}),
    },

    /**
     * 必須かどうか
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const year = computed({
      get: () => modelValue.value?.year,
      set: (value: number | undefined) => emit('update:modelValue', {
        ...modelValue.value,
        year: value,
      }),
    });

    const month = computed({
      get: () => modelValue.value?.month,
      set: (value: number | undefined) => emit('update:modelValue', {
        ...modelValue.value,
        month: value,
      }),
    });

    const day = computed({
      get: () => modelValue.value?.day,
      set: (value: number | undefined) => emit('update:modelValue', {
        ...modelValue.value,
        day: value,
      }),
    });

    const thisYear = dayjs().year();

    return {
      year,
      month,
      day,
      range,
      thisYear,
    };
  },
});
