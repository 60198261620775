import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-left break-words whitespace-pre-wrap",
  style: {"line-height":"115%"}
}
const _hoisted_2 = {
  key: 1,
  class: "text-left break-words whitespace-pre-wrap text-gray-400",
  style: {"line-height":"115%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectOption = _resolveComponent("BaseSelectOption")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createBlock(_component_BaseSelect, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
  }, {
    selected: _withCtx(() => [
      (_ctx.selected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.options.find((i) => i.value === _ctx.selected)?.label || ' '), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
    ]),
    options: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.required ? _ctx.options : [undefined, ..._ctx.options], (option, index) => {
        return (_openBlock(), _createBlock(_component_BaseSelectOption, {
          key: index,
          value: option?.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option?.label || ' '), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}