
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

import { OptionItem } from '@/models/option_item';

import BaseSelect from './BaseSelect.vue';
import BaseSelectOption from './BaseSelectOption.vue';

export default defineComponent({
  name: 'SurveyInputSelect',
  components: {
    BaseSelect,
    BaseSelectOption,
  },
  props: {

    /**
     * 選択肢
     */
    options: {
      type: Array as PropType<OptionItem[]>,
      default: () => ([]),
    },

    /**
     *  選択されている値
     */
    modelValue: {
      type: String,
      default: undefined,
    },

    /**
     * プレースホルダ
     */
    placeholder: {
      type: String,
      default: '入力してください',
    },

    /**
     * 必須かどうか
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const selected = useVModel(props, 'modelValue', emit);
    return {
      selected,
    };
  },
});
