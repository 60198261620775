import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useDialog } from './use_dialog';

/**
 * ダイアログ非表示
 */
export class ActionDialogClose extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_DIALOG_CLOSE;

  dialogKey?: string;

  use() {
    const { removeDialog } = useDialog();

    return async () => await execute(this.dialogKey, removeDialog);
  }

  constructor(init: Partial<ActionDialogClose> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  dialogKey: string | undefined,
  removeDialog: ReturnType<typeof useDialog>['removeDialog'],
) {
  if (dialogKey) {
    removeDialog(dialogKey);
  }
  return false;
}

export function generateActionDialogClose(dialogKey?: string): ActionDialogClose {
  return new ActionDialogClose({
    dialogKey,
  });
}
