import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentSurveyConfirmModel extends ContentBase {
  constructor(init?: Partial<ContentSurveyConfirmModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_SURVEY_CONFIRM; // クラスに対して区分値固定なので、コンストラクタで代入
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentSurveyConfirmModel,
  () => new ContentSurveyConfirmModel({
    typeKbn: KbnConst.CONTENT_TYPE_KBN_SURVEY_CONFIRM,
  }),
);
