import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f9425ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative bg-white whitespace-nowrap" }
const _hoisted_2 = { class: "px-2" }
const _hoisted_3 = { class: "w-full max-h-40 hidden-scrollbar overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Listbox, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ListboxButton, { class: "w-full" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([
              'w-full p-1 border-l border-t border-r',
              open ? 'border-gray-400' : 'border-transparent'
            ])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "selected", {}, undefined, true)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass([
                'h-1 border-gray-400',
                open ? 'border-b' : 'rounded-b-sm border-b border-l border-r'
              ])
                }, null, 2)
              ], 2)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_ListboxOptions, {
            class: _normalizeClass([
            'absolute z-10 bg-white w-full pb-1',
            open && 'border-l border-b border-r border-gray-400'
          ])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "options", {}, undefined, true)
              ])
            ]),
            _: 2
          }, 1032, ["class"])
        ])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}