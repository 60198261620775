import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between gap-2 bg-gray-100 p-2" }
const _hoisted_2 = { class: "break-words font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "bg-red-400 text-white text-center text-sm rounded px-2 py-1 w-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", null, [
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " 必須 "))
        : _createCommentVNode("", true)
    ])
  ]))
}