import isArray from 'lodash/isArray';

import { isBirthday, toDateString } from '@/types/Birthday';

import { selectedText } from './selected_text';

// TODO: 実際はUiKbnに依存しているので、クラス化したほうがいいかも
export function surveyText(value: unknown): string {
  if (isArray(value)) {
    return selectedText(value);
  } else if (isBirthday(value)) {
    return toDateString(value);
  } else if (typeof value === 'string') {
    return value;
  }
  return '';
}
