import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      _ctx.variant ? `base-button-variant-${_ctx.variant}` : '',
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}