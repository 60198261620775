import { inject, InjectionKey, provide, Ref, ref } from 'vue';

import { StyleTemplate } from '@/models/style_template';

const StyleTemplatesInjectKey: InjectionKey<Ref<StyleTemplate[]>> = Symbol('StyleTemplatesInjectKey');

export function useProvideStyleTemplate(value: StyleTemplate[]) {
  provide(StyleTemplatesInjectKey, ref(value));
}

export function useInjectStyleTemplate() {
  return inject(StyleTemplatesInjectKey, ref([]));
}
