
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue';
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';

import BaseIcon from '@/components/BaseIcon.vue';

export default defineComponent({
  name: 'BaseSelect',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    BaseIcon,
  },
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const selected = useVModel(props, 'modelValue', emit);

    return {
      selected,
    };
  },
});
