import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-8" }
const _hoisted_2 = ["maxlength", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      type: "text",
      maxlength: _ctx.max || undefined,
      class: _normalizeClass([
        'survey-input-textarea w-full resize-none px-2 py-1 outline-none overflow-hidden',
        'rounded-md border border-gray-400',
        'placeholder-gray-400'
      ]),
      placeholder: _ctx.placeholder
    }, null, 8, _hoisted_2), [
      [
        _vModelText,
        _ctx.text,
        void 0,
        { trim: true }
      ]
    ])
  ]))
}