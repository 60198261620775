
import { computed, defineComponent, toRefs } from 'vue-demi';

import { useBaseRadioContext } from '@/hooks/use_base_radio';

import BaseIcon from './BaseIcon.vue';

export default defineComponent({
  name: 'BaseRadio',
  components: {
    BaseIcon,
  },
  props: {

    /**
     * このラジオのグループのv-modelから発行される値
     */
    value: {
      type: null,
      required: true,
    },

    /**
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { disabled, value } = toRefs(props);
    const { selection } = useBaseRadioContext();
    const isSelected = computed(() => selection.value === value.value);

    function onSelect() {
      if (disabled.value) return;

      selection.value = value.value;
    }

    return {
      isSelected,
      onSelect,
    };
  },
});
