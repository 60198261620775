import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';

export class ContentQrCodeShowModel extends ContentBase {
  typeKbn = KbnConst.CONTENT_TYPE_KBN_QR_CODE_SHOW;

  size: string = '400';

  constructor(init?: Partial<ContentQrCodeShowModel>) {
    super();
    Object.assign(this, init);
  }
}

registerGenerator(
  ContentQrCodeShowModel,
  ({ style }) => new ContentQrCodeShowModel({
    style,
  }),
);
