// vueのapp.useでインストールするプラグインはここでまとめて追加する。(storybookにも設定を同時に反映するため)

import { App } from 'vue';

import { RouterConstInstall } from '@/router/router_const';
import { KbnInstall } from '@/utils/KbnInstall';

export const VuePlugin = {
  install: (app: App) => {
    app.use(RouterConstInstall);
    app.use(KbnInstall);
  },
};
