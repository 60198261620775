
import noop from 'lodash/noop';
import { computed, defineComponent, onMounted, PropType, ref, toRefs } from 'vue';

import { useApi } from '@/hooks/use_api';
import { campaign } from '@/json/json_loader';

import BaseButton from './BaseButton.vue';
import LinePayDialogFrame from './LinePayDialogFrame.vue';
import LinePayGiftContent from './LinePayGiftContent.vue';

export default defineComponent({
  name: 'LinePayDialogAgree',
  components: {
    LinePayDialogFrame,
    BaseButton,
    LinePayGiftContent,
  },
  props: {
    ok: {
      type: Function as PropType<() => void>,
      default: noop,
    },
    cancel: {
      type: Function as PropType<() => void>,
      default: noop,
    },
  },
  setup(porps) {
    const { cancel } = toRefs(porps);
    const api = useApi();
    const _amount = ref<number>();
    const isShow = ref(false);

    onMounted(async () => {
      try {
        const response = await api.getUsers();
        _amount.value = response.dataJson.amount;
        isShow.value = true;
      } catch (error) {
        cancel.value();
        throw error;
      }
    });

    return {
      headerText: computed(() => campaign.linePayDialogPropaty.agreeHeader),
      footerText: computed(() => campaign.linePayDialogPropaty.agreeFooter),
      amount: computed(() => _amount.value || 0),
      isShow,
    };
  },
});
