import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "dummyElement",
  class: "invisible w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createElementVNode("div", {
      class: _normalizeClass([[
      _ctx.fixedOn && `fixed ${ _ctx.fixedOn }-0 z-30`,
    ], "w-full"]),
      style: _normalizeStyle({
      width: _ctx.fixedOn ? `${ _ctx.width }px` : undefined // fixed のとき親の横幅を使えないので計算した値を利用する
    })
    }, [
      _createVNode(_component_BaseStyle, {
        class: "content-base flex flex-col items-stretch text-center",
        "content-style": _ctx.contentStyle
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseStyle, {
            class: _normalizeClass([
          'w-full select-none cursor-pointer content-button',
          _ctx.disabled ? 'pointer-events-none' : ''
        ]),
            "content-style": _ctx.renderedButtonStyle,
            onClick: _ctx.onClick
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["class", "content-style", "onClick"])
        ]),
        _: 3
      }, 8, ["content-style"])
    ], 6)
  ], 64))
}