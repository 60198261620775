/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export function isDebug() {
  return process.env.VUE_APP_ENV !== 'prod';
}

export function isLocal() {
  return process.env.VUE_APP_ENV === undefined;
}

export function debugLog(...data: any[]) {
  if (isDebug()) {
    console.log(...data);
  }
}

export function debugError(...data: any[]) {
  if (isDebug()) {
    console.error(...data);
  }
}
