import { ref } from 'vue-demi';

import { useApi } from './use_api';

const disabledContents = ref<Map<string, boolean>>();

/**
 * コンテンツ非活性フラグ
 *
 */
export function useDisabledContents() {
  const api = useApi();

  async function getDisabledContents() {
    const response = await api.getUsers();
    if (response.dataJson.disabledContents) disabledContents.value = new Map(Object.entries(response.dataJson.disabledContents));
  }

  function shouldVerifyDisablement(id?: string) {
    return !!id;
  }

  return {
    disabledContents,
    shouldVerifyDisablement,
    getDisabledContents,
  };
}
