
import noop from 'lodash/noop';
import { defineComponent, PropType } from 'vue';

import { PopupDialogType } from '@/hooks/use_popup_dialog';

import BaseButton from './BaseButton.vue';

export default defineComponent({
  name: 'MessageCard',
  components: { BaseButton },
  props: {

    /**
     * ダイアログに表示するメッセージ
     */
    message: {
      type: String,
      default: '',
    },

    /**
     * ダイアログのタイプ
     * alert: OKボタンのみ表示
     * confirm: OKボタンとキャンセルボタンを表示
     */
    type: {
      type: String as PropType<PopupDialogType>,
      default: 'alert',
    },

    /**
     * OKボタンのアクション
     */
    ok: {
      type: Function as PropType<() => void>,
      default: () => noop,
    },

    /**
     * キャンセルボタンのアクション
     */
    cancel: {
      type: Function as PropType<() => void>,
      default: () => noop,
    },
  },
});
