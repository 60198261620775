import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { classToPlain } from 'class-transformer';
import * as convertKeys from 'convert-keys';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import qs from 'qs';

import { CONFIG } from '@/config';
import { campaign } from '@/json/json_loader';
import { CampaignActivity } from '@/models/campaign_activity';
import { User } from '@/models/user';
import { sleep } from '@/utils/sleep';

import { applyCasingFix } from './fix_casing';
import { RECEIPT_RESULT_CODE, RESULT_CODE } from './result_code';

type ResultCodeResponse = {
  resultCode: string
};

type PostLoginResponse = {
  trackingId: string
  dataJson: unknown
} & ResultCodeResponse;

type GetLineLoginResponse = {
  isLogin: boolean
  loginUrl: string
} & ResultCodeResponse;

type PostLineLoginRequest = {
  code: string
  state: string
};

type PostLineConnectionResponse = {
  trackingId: string
} & ResultCodeResponse;

type DeleteLineConnectionResponse = {
  trackingId: string
} & ResultCodeResponse;

type PostLiffLoginResponse = {
  entryUuid: string
  isHitable: true
  trackingId: string
} & ResultCodeResponse;

type GetTwitterLoginResponse = {
  isLogin: boolean
  loginUrl: string
} & ResultCodeResponse;

type PostTwitterLoginRequest = {
  oauthToken: string
  oauthVerifier: string
  state: string
};

type GetGoogleLoginResponse = {
  loginUrl: string
} & ResultCodeResponse;

type PostGoogleLoginRequest = {
  code: string
  state: string
};

type PostCheckinLoginRequest = {
  checkinToken: string
};

type PostAnonymousLoginResponse = {
  trackingId: string
} & ResultCodeResponse;

type PostInsertResponse = {
  entryUuid: string
} & ResultCodeResponse;

type GetEntryResponse = {
  responseCandidateUuid: string
  timeSeedingId: string
  serialCode: string
} & ResultCodeResponse;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetUsersResponse<T extends Record<string | number, any> = Record<string | number, any>> = {
  dataJson: T
} & ResultCodeResponse;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetCommonResponse<T extends Record<string | number, any> = Record<string | number, any>> = {
  dataJson: T
} & ResultCodeResponse;

type GetReceiptResponse = {
  data?: unknown; // 暗号化データ 内容は不明
  message?: string;
} & ResultCodeResponse;

type GetLinePayRequestResponse = {
  returnCode: string
  returnMessage: string
  info: LinePayRequestInfo;
};

type LinePayRequestInfo = {
  transactionId: number,
  paymentAccessToken: string,
  paymentUrl: LinePayRequestPaymentUrl
};

type LinePayRequestPaymentUrl = {
  app: string,
  web: string
};

type GetReceiptUploadUrlResponse = {
  dataJson: {
    receiptUuid: string,
    presignedUrl: string,
  },
  message?: string;
} & ResultCodeResponse;

export class Api {
  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      paramsSerializer: (params) => {
        params = pickBy(params, Boolean);
        params = convertKeys.toSnake(params);

        return qs.stringify(params, {
          arrayFormat: 'brackets',
        });
      },
    });

    applyCasingFix(this.axios);
  }

  async request<T extends ResultCodeResponse>(config: AxiosRequestConfig, succesCodes: string[] = [RESULT_CODE.SUCCESS]): Promise<T> {
    const { data } = await this.axios.request<T>(config);

    if (!succesCodes.includes(data.resultCode)) {
      throw data.resultCode;
    }

    return data;
  }

  async externalRequest<T>(config: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.request<T>(config);

    return data;
  }

  async getLineLogin(params?: unknown) {
    return await this.request<GetLineLoginResponse>({
      method: 'GET',
      url: `/api/login/${campaign.campaignUuid}`,
      params: params,
    });
  }

  async postLineLogin(code: string, data: PostLineLoginRequest) {
    return await this.request<PostLoginResponse>({
      method: 'POST',
      url: `/api/line/login/${campaign.campaignUuid}/${code}`,
      data,
    });
  }

  async getLineLoginOpenid() {
    return await this.request<GetLineLoginResponse>({
      method: 'GET',
      url: `/api/login/openid/${campaign.campaignUuid}`,
    });
  }

  async postLineLoginOpenid(code: string) {
    return await this.request<PostLoginResponse>({
      method: 'POST',
      url: `/api/line/login/openid/${campaign.campaignUuid}/${code}`,
    });
  }

  async postLineConnection(code: string) {
    return await this.request<PostLineConnectionResponse>({
      method: 'POST',
      url: `/api/line/connection/${campaign.campaignUuid}/${code}`,
    });
  }

  async deleteLineConnection() {
    return await this.request<DeleteLineConnectionResponse>({
      method: 'DELETE',
      url: `/api/line/connection/${campaign.campaignUuid}`,
    });
  }

  async postLiffLogin(accessToken: string) {
    return await this.request<PostLiffLoginResponse>({
      method: 'POST',
      url: `/api/liff/login/${campaign.campaignUuid}/${accessToken}`,
    });
  }

  async putLiffLogin(accessToken: string) {
    return await this.request<PostLiffLoginResponse>({
      method: 'PUT',
      url: `/api/liff/login/${campaign.campaignUuid}/${accessToken}`,
    });
  }

  async postLiffLoginOpenid(idToken: string) {
    return await this.request<PostLiffLoginResponse>({
      method: 'POST',
      url: `/api/liff/login/openid/${campaign.campaignUuid}/${idToken}`,
    });
  }

  async putLiffLoginOpenid(accessToken: string) {
    return await this.request<PostLiffLoginResponse>({
      method: 'PUT',
      url: `/api/liff/login/openid/${campaign.campaignUuid}/${accessToken}`,
    });
  }

  async postLiffConnection(accessToken: string) {
    return await this.request<PostLiffLoginResponse>({
      method: 'POST',
      url: `/api/liff/connection/${campaign.campaignUuid}/${accessToken}`,
    });
  }

  async getTwitterLogin(params?: unknown) {
    return await this.request<GetTwitterLoginResponse>({
      method: 'GET',
      url: `/api/twitter/login/${campaign.campaignUuid}`,
      params: params,
    });
  }

  async postTwitterLogin(data: PostTwitterLoginRequest) {
    return await this.request<PostLoginResponse>({
      method: 'POST',
      url: `/api/twitter/login/${campaign.campaignUuid}`,
      data,
    });
  }

  async postCheckinLogin(data: PostCheckinLoginRequest) {
    return await this.request<PostLoginResponse>({
      method: 'POST',
      url: `/api/checkin_login/${campaign.campaignUuid}/${data.checkinToken}`,
      data,
    });
  }

  async postTwitterConnection(data: PostTwitterLoginRequest) {
    return await this.request<ResultCodeResponse>({
      method: 'POST',
      url: `/api/twitter/connection/${campaign.campaignUuid}`,
      data,
    });
  }

  async deleteTwitterConnection() {
    return await this.request<ResultCodeResponse>({
      method: 'DELETE',
      url: `/api/twitter/login/${campaign.campaignUuid}`,
    });
  }

  async getGoogleLogin(params?: unknown) {
    return await this.request<GetGoogleLoginResponse>({
      method: 'GET',
      url: `/api/google/login/${campaign.campaignUuid}`,
      params: params,
    });
  }

  async postGoogleLogin(data: PostGoogleLoginRequest) {
    return await this.request<PostLoginResponse>({
      method: 'POST',
      url: `/api/google/login/${campaign.campaignUuid}`,
      data,
    });
  }

  async postAnonymousLogin() {
    return await this.request<PostAnonymousLoginResponse>({
      method: 'POST',
      url: `/api/anonymous_login/${campaign.campaignUuid}`,
    });
  }

  // 何かしら data を入れないとこのAPIは400を返す
  async postInsert(data: Record<string, unknown> = {}) {
    return await this.request<PostInsertResponse>({
      method: 'POST',
      url: `/api/insert/${campaign.campaignUuid}`,
      data: classToPlain(data), // toSnakeメソッドではclassインスタンスはスネークケースに変換されないため、クラスをプレーンに変換する
    });
  }

  async postInsertOpenid(data: Record<string, unknown> = {}) {
    return await this.request<PostInsertResponse>({
      method: 'POST',
      url: `/api/insert/openid/${campaign.campaignUuid}`,
      data: classToPlain(data), // toSnakeメソッドではclassインスタンスはスネークケースに変換されないため、クラスをプレーンに変換する
    });
  }

  async getLottery(entryUuid: string) {
    return await this.request<ResultCodeResponse>({
      method: 'GET',
      url: `/api/lottery/${campaign.campaignUuid}/${entryUuid}`,
    });
  }

  async getEntry(entryUuid: string) {
    return await this.request<GetEntryResponse>({
      method: 'GET',
      url: `/api/entry/${campaign.campaignUuid}/${entryUuid}`,
    });
  }

  async getEntryPolling(entryUuid: string) {
    let response;
    for (let count = 0; count < CONFIG.getEntryPollingRetryCount; count++) {
      await sleep(CONFIG.getEntryPollingIntervalMilliSecond);
      try {
        response = await this.getEntry(entryUuid);
        if (response.responseCandidateUuid) return response; // 返信候補に落ちたら終了
      } catch {} // リトライ
    }
    return response; // 返信候補に落ちない場合も最後に取得できた結果を返す
  }

  async getUsers() {
    return await this.request<GetUsersResponse<User>>({
      method: 'GET',
      url: `/api/users/${campaign.campaignUuid}`,
    });
  }

  async putUsers<T>(data: T) {
    return await this.request<ResultCodeResponse>({
      method: 'PUT',
      url: `/api/users/${campaign.campaignUuid}`,
      data,
    });
  }

  async getCommon() {
    return await this.request<GetCommonResponse<CampaignActivity>>({
      method: 'GET',
      url: `/api/common/${campaign.campaignUuid}`,
    });
  }

  async uploadFile(url: string, file: File, headers: Record<string, string> = {}) {
    return await this.requestXML(
      'PUT',
      url,
      file,
      headers,
    );
  }

  private requestXML(method: string, url: string, file: File, headers: Record<string, string> = {}) {
    return new Promise((resolve, reject) => {
      const oReq = new XMLHttpRequest();
      oReq.open(method, url, true);
      oReq.setRequestHeader('Content-Type', file.type);
      toPairs(headers).forEach(([key, value]) => {
        oReq.setRequestHeader(key, value);
      });
      oReq.onload = (e) => {
        if (oReq.status >= 400) {
          reject(new Error('Client error: ' + oReq.status));
        } else {
          resolve(e);
        }
      };
      oReq.onerror = (e) => {
        reject(e);
      };
      oReq.onabort = (e) => {
        reject(e);
      };
      oReq.send(file);
    });
  }

  async postReceipt(receiptUuid: string) {
    return await this.request<ResultCodeResponse>({
      method: 'POST',
      url: `/api/receipt/${campaign.campaignUuid}`,
      data: {
        receiptUuid,
      },
    });
  }

  async getReceipt(receiptUuid: string) {
    return await this.request<GetReceiptResponse>({
      method: 'get',
      url: `${campaign.receiptAiEndpointUri}/campaigns/${campaign.receiptAiCampaignUuid}/receipts/${receiptUuid}`,
      timeout: 10000,
    }, [
      RECEIPT_RESULT_CODE.SUCCESS,
      RECEIPT_RESULT_CODE.RUNNING,
    ]);
  }

  async getReceiptPolling(receiptUuid: string) {
    let response: GetReceiptResponse = {
      resultCode: RECEIPT_RESULT_CODE.RUNNING,
    }; // ループ開始用ダミーデータ
    let lastError;
    for (let count = 0; count < CONFIG.getReceiptPollingRetryCount; count++) {
      await sleep(CONFIG.getReceiptPollingIntervalMilliSecond);
      try {
        response = await this.getReceipt(receiptUuid);
        if (response.resultCode === RECEIPT_RESULT_CODE.SUCCESS) return response;
      } catch (error) {
        lastError = error;
      }
    }
    if (lastError) throw lastError; // 例外で失敗した場合、最後に発生したエラーをスローする。
    throw Error('get receipt polling timeout'); // 何れでも無い場合は専用エラーを返す。
  }

  async getLinePayRequest() {
    return await this.externalRequest<GetLinePayRequestResponse>({
      withCredentials: false,
      method: 'GET',
      url: `${campaign.linePayEndpointUri}/request`,
    });
  }

  async getLinePayConfirm(idToken: string, transactionId: string, orderId: string) {
    return await this.externalRequest<ResultCodeResponse>({
      withCredentials: false,
      method: 'GET',
      url: `${campaign.linePayEndpointUri}/confirm/${idToken}/${transactionId}/${orderId}`,
    });
  }

  async getLinePayReceive(idToken: string, orderId: string) {
    return await this.externalRequest<ResultCodeResponse>({
      withCredentials: false,
      method: 'GET',
      url: `${campaign.linePayEndpointUri}/receive/${idToken}/${orderId}`,
    });
  }

  async getReceiptUploadUrl() {
    return await this.request<GetReceiptUploadUrlResponse>({
      method: 'GET',
      url: `/api/receipt/${campaign.campaignUuid}/presigned_url`,
    });
  }
}
