
import { Html5Qrcode } from 'html5-qrcode';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent, onMounted, onUnmounted, PropType } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { useInsertDataStore } from '@/hooks/use_insert_data_store';
import { useLoader } from '@/hooks/use_loader';
import { ContentQrCodeReadingModel } from '@/models/content_qr_code_reading_model';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentQrCodeReading',
  components: {
    BaseStyle,
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentQrCodeReadingModel>,
      required: true,
    },
  },
  setup: (props) => {
    const qrReaderId: string = uuidv4();
    let html5QrCode: Html5Qrcode | null = null;
    const execute = useActions(props.model.actions);
    const insertDataStore = useInsertDataStore();

    let onRunning = false;
    const loader = useLoader();

    onMounted(() => {
      onRunning = false;
      html5QrCode = new Html5Qrcode(qrReaderId);
      html5QrCode.start(
        {
          facingMode: 'user', // 前のカメラを使う
        },
        {
          fps: 10,    // frame per seconds for qr code scanning
          // qrbox: {
          //   width: 250,
          //   height: 250,
          // },  // Optional, 今回使わない
        },
        async (decodedText: string, _decodedResult: unknown) => {
          if (!onRunning) {
            try {
              loader.start();
              onRunning = true;
              insertDataStore.qrCode.value = decodedText;
              await execute();
            } finally {
              onRunning = false;
              loader.stop();
            }
          }
        },
        // eslint-disable-next-line lodash/prefer-noop
        (_errorMessage: string) => {
          // parse error, ignore it.
          // console.warn(`errorMessage = ${errorMessage}`);
        })
        .catch((error: unknown) => {
          // Start failed, handle it.
          // eslint-disable-next-line no-console
          console.warn(`Code scan error = ${error}`);
        });
    });

    onUnmounted((() => {
      if (html5QrCode) {
        html5QrCode.stop();
      }
    }));

    return {
      qrReaderId,
    };
  },
});
