
import { defineComponent } from 'vue';

import { useTrackingId } from '@/hooks/use_tracking_id';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentTrackingId',
  components: { BaseStyle },
  setup() {
    const trackingId = useTrackingId();
    return {
      trackingId,
    };
  },
});
