
import { useElementSize } from '@vueuse/core';
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';

import { useActions } from '@/hooks/use_actions';
import { useFileUpload } from '@/hooks/use_file_upload';
import { useRum } from '@/hooks/use_rum';
import { ActionBase } from '@/models/action_base';
import { Style } from '@/models/style';
import { KbnConst } from '@/utils/kbn';
import { sleep } from '@/utils/sleep';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentButton',
  components: {
    BaseStyle,
  },
  props: {

    /**
     * 規定のアクション（clickが指定されると実行しない）
     */
    actions: {
      type: Array as PropType<Array<ActionBase>>,
      required: false,
      default: () => [],
    },

    /**
     * スタイル
     */
    contentStyle: {
      type: Object as PropType<Style>,
      required: false,
      default: undefined,
    },

    /**
     * ボタンスタイル
     */
    buttonStyle: {
      type: Object as PropType<Style>,
      required: false,
      default: undefined,
    },

    /**
     * 非活性ボタンスタイル
     */
    disabledButtonStyle: {
      type: Object as PropType<Style>,
      required: false,
      default: undefined,
    },

    /**
     * クリックの直接指定（actionsより優先）
     */
    click: {
      type: Function as PropType<(() => Promise<void>) | (() => void) >,
      required: false,
      default: undefined,
    },

    /**
     * ボタンの画像
     */
    src: {
      type: String,
      required: false,
      default: undefined,
    },

    /**
     * 非活性
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * ボタンを固定するかどうか
     */
    fixedOn: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { slots }) {
    const { disabled } = toRefs(props);
    const execute = useActions(props.actions);
    const { recordButtonClickEvent } = useRum();
    const { cancel } = useFileUpload();

    const executing = ref(false);
    async function onClick() {

      if (executing.value && props.actions.find(action => action.typeKbn === KbnConst.ACTION_TYPE_KBN_RECEIPT_PHOTOGRAPHING)) {
        // HACK: アクション実行中のとき、レシート撮影アクションが含まれるなら、キャンセルを実行して少し待つことで状態が変わる可能性がある
        // レシート撮影アクションは、キャンセルされると、アクションが実行中のままになる
        cancel();
        await sleep(100);
      }

      // 二度押し防止
      if (executing.value) {
        return;
      }
      if (disabled.value) {
        return;
      }

      executing.value = true;

      try {
        recordButtonClickEvent({ buttonLabel: slots.default?.()[0].children?.toString() });
        if (props.click) {
          await props.click();
        } else {
          await execute();
        }
      } finally {
        executing.value = false;
      }
    }

    // width を測るための要素（ボタンを固定表示にしたときに使う）
    const dummyElement = ref<HTMLElement | null>(null);
    const { width } = useElementSize(dummyElement);

    const renderedButtonStyle = computed(() => {
      if (disabled.value) {
        return props.disabledButtonStyle;
      }
      return props.buttonStyle;
    });

    return {
      onClick,
      renderedButtonStyle,
      dummyElement,
      width,
    };
  },
});
