import { Exclude, Expose, Type } from 'class-transformer';
import { TextAlignProperty } from 'csstype';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { Medium } from '@/models/medium';

export interface StyleField {
  color: string;
  backgroundColor: string;
  backgroundImage?: Medium;
  fontSize: string;
  fontFamily: string;
  textAlign: TextAlignProperty;
  border: string;
  borderColor: string;
  borderRadius: string;
  borderTop: string;
  borderRight: string;
  borderBottom: string;
  borderLeft: string;
  marginTop: string;
  marginRight: string;
  marginBottom: string;
  marginLeft: string;
  paddingTop: string;
  paddingRight: string;
  paddingBottom: string;
  paddingLeft: string;
  height: string;
  width: string;
  overflow: string;
  fontWeight: string;
  lineHeight: string;
  styleTemplateName?: string;
}

export const StyleFieldObject = {
  _color: '',
  _marginTop: '',
  _marginRight: '',
  _marginBottom: '',
  _marginLeft: '',
  _paddingTop: '',
  _paddingRight: '',
  _paddingBottom: '',
  _paddingLeft: '',
  _backgroundColor: '',
  _backgroundImage: undefined,
  _fontSize: '',
  _fontFamily: '',
  _textAlign: 'center',
  _border: '',
  _borderColor: '',
  _borderRadius: '',
  _borderTop: '',
  _borderRight: '',
  _borderBottom: '',
  _borderLeft: '',
  _height: '',
  _width: '',
  _overflow: '',
  _fontWeight: '',
  _lineHeight: '',
};

@Exclude()
export class Style implements Partial<StyleField> {
  @Expose({ name: 'color' })
  _color?: string;

  @Expose({ name: 'backgroundColor' })
  _backgroundColor?: string;

  @Expose({ name: 'backgroundImage' })
  @Type(() => Medium)
  _backgroundImage?: Medium;

  @Expose({ name: 'fontSize' })
  _fontSize?: string;

  @Expose({ name: 'fontFamily' })
  _fontFamily?: string;

  @Expose({ name: 'textAlign' })
  _textAlign?: TextAlignProperty;

  @Expose({ name: 'border' })
  _border?: string;

  @Expose({ name: 'borderTop' })
  _borderTop?: string;

  @Expose({ name: 'borderColor' })
  _borderColor?: string;

  @Expose({ name: 'borderRadius' })
  _borderRadius?: string;

  @Expose({ name: 'borderRight' })
  _borderRight?: string;

  @Expose({ name: 'borderBottom' })
  _borderBottom?: string;

  @Expose({ name: 'borderLeft' })
  _borderLeft?: string;

  @Expose({ name: 'marginTop' })
  _marginTop?: string;

  @Expose({ name: 'marginRight' })
  _marginRight?: string;

  @Expose({ name: 'marginBottom' })
  _marginBottom?: string;

  @Expose({ name: 'marginLeft' })
  _marginLeft?: string;

  @Expose({ name: 'paddingTop' })
  _paddingTop?: string;

  @Expose({ name: 'paddingRight' })
  _paddingRight?: string;

  @Expose({ name: 'paddingBottom' })
  _paddingBottom?: string;

  @Expose({ name: 'paddingLeft' })
  _paddingLeft?: string;

  @Expose({ name: 'height' })
  _height?: string;

  @Expose({ name: 'width' })
  _width?: string;

  @Expose({ name: 'overflow' })
  _overflow?: string;

  @Expose({ name: 'fontWeight' })
  _fontWeight?: string;

  @Expose({ name: 'lineHeight' })
  _lineHeight?: string;

  @Expose({ name: 'styleTemplateName' })
  styleTemplateName?: string;

  // この値はBaseStyle.vueで設定されます。Jsonに出力しません
  styleTemplate?: Partial<StyleField>;

  // style Template使わないと、styleTemplateにデータがあっても使わない
  get styleTemplateObject() {
    return this.styleTemplateName ? this.styleTemplate ?? {} : {};
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get color() {
    return this._color ?? this.styleTemplateObject.color ?? this.defaultStyle.color;
  }

  set color(value: string | undefined) {
    this._color = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get backgroundColor() {
    return this._backgroundColor ?? this.styleTemplateObject.backgroundColor ?? this.defaultStyle.backgroundColor;
  }

  set backgroundColor(value: string | undefined) {
    this._backgroundColor = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get backgroundImage() {
    return this._backgroundImage ?? this.styleTemplateObject.backgroundImage ?? this.defaultStyle.backgroundImage;
  }

  set backgroundImage(value: Medium | undefined) {
    this._backgroundImage = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get fontSize() {
    return this._fontSize ?? this.styleTemplateObject.fontSize ?? this.defaultStyle.fontSize;
  }

  set fontSize(value: string | undefined) {
    this._fontSize = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get fontFamily() {
    return this._fontFamily ?? this.styleTemplateObject.fontFamily ?? this.defaultStyle.fontFamily;
  }

  set fontFamily(value: string | undefined) {
    this._fontFamily = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get textAlign() {
    return this._textAlign ?? this.styleTemplateObject.textAlign ?? this.defaultStyle.textAlign;
  }

  set textAlign(value: TextAlignProperty | undefined) {
    this._textAlign = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get border() {
    return this._border ?? this.styleTemplateObject.border ?? this.defaultStyle.border;
  }

  set border(value: string | undefined) {
    this._border = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderColor() {
    return this._borderColor ?? this.styleTemplateObject.borderColor ?? this.defaultStyle.borderColor;
  }

  set borderColor(value: string | undefined) {
    this._borderColor = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderRadius() {
    return this._borderRadius ?? this.styleTemplateObject.borderRadius ?? this.defaultStyle.borderRadius;
  }

  set borderRadius(value: string | undefined) {
    this._borderRadius = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderTop() {
    return this._borderTop ?? this.styleTemplateObject.borderTop ?? this.defaultStyle.borderTop;
  }

  set borderTop(value: string | undefined) {
    this._borderTop = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderRight() {
    return this._borderRight ?? this.styleTemplateObject.borderRight ?? this.defaultStyle.borderRight;
  }

  set borderRight(value: string | undefined) {
    this._borderRight = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderBottom() {
    return this._borderBottom ?? this.styleTemplateObject.borderBottom ?? this.defaultStyle.borderBottom;
  }

  set borderBottom(value: string | undefined) {
    this._borderBottom = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get borderLeft() {
    return this._borderLeft ?? this.styleTemplateObject.borderLeft ?? this.defaultStyle.borderLeft;
  }

  set borderLeft(value: string | undefined) {
    this._borderLeft = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get marginTop() {
    return this._marginTop ?? this.styleTemplateObject.marginTop ?? this.defaultStyle.marginTop;
  }

  set marginTop(value: string | undefined) {
    this._marginTop = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get marginRight() {
    return this._marginRight ?? this.styleTemplateObject.marginRight ?? this.defaultStyle.marginRight;
  }

  set marginRight(value: string | undefined) {
    this._marginRight = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get marginBottom() {
    return this._marginBottom ?? this.styleTemplateObject.marginBottom ?? this.defaultStyle.marginBottom;
  }

  set marginBottom(value: string | undefined) {
    this._marginBottom = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get marginLeft() {
    return this._marginLeft ?? this.styleTemplateObject.marginLeft ?? this.defaultStyle.marginLeft;
  }

  set marginLeft(value: string | undefined) {
    this._marginLeft = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get paddingTop() {
    return this._paddingTop ?? this.styleTemplateObject.paddingTop ?? this.defaultStyle.paddingTop;
  }

  set paddingTop(value: string | undefined) {
    this._paddingTop = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get paddingRight() {
    return this._paddingRight ?? this.styleTemplateObject.paddingRight ?? this.defaultStyle.paddingRight;
  }

  set paddingRight(value: string | undefined) {
    this._paddingRight = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get paddingBottom() {
    return this._paddingBottom ?? this.styleTemplateObject.paddingBottom ?? this.defaultStyle.paddingBottom;
  }

  set paddingBottom(value: string | undefined) {
    this._paddingBottom = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get paddingLeft() {
    return this._paddingLeft ?? this.styleTemplateObject.paddingLeft ?? this.defaultStyle.paddingLeft;
  }

  set paddingLeft(value: string | undefined) {
    this._paddingLeft = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get width() {
    return this._width ?? this.styleTemplateObject.width ?? this.defaultStyle.width;
  }

  set width(value: string | undefined) {
    this._width = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get height() {
    return this._height ?? this.styleTemplateObject.height ?? this.defaultStyle.height;
  }

  set height(value: string | undefined) {
    this._height = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get overflow() {
    return this._overflow ?? this.styleTemplateObject.overflow ?? this.defaultStyle.overflow;
  }

  set overflow(value: string | undefined) {
    this._overflow = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get fontWeight() {
    return this._fontWeight ?? this.styleTemplateObject.fontWeight ?? this.defaultStyle.fontWeight;
  }

  set fontWeight(value: string | undefined) {
    this._fontWeight = value;
  }

  // スタイルデータの順番、自分で設定したスタイル　→　テンプレートに設定したスタイル　→　デフォルトに設定したスタイル
  get lineHeight() {
    return this._lineHeight ?? this.styleTemplateObject.lineHeight ?? this.defaultStyle.lineHeight;
  }

  set lineHeight(value: string | undefined) {
    this._lineHeight = value;
  }

  defaultStyle: Partial<StyleField> = {

    // デフォルトは不要です。
    // // margin
    marginTop: '0.5rem',
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',

    // padding
    paddingTop: '0.5rem',
    paddingRight: '1rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1rem',
  };

  with(value: Partial<StyleField>, defaultStyle: Partial<StyleField> = {}) {
    return new Style({
      styleTemplateName: this.styleTemplateName,
      ...this.cssField,
      ...value,
    }, defaultStyle);
  }

  constructor(init: Partial<StyleField> = {}, defaultStyle: Partial<StyleField> = {}) {
    this._color = init.color;
    this._backgroundColor = init.backgroundColor;
    this._backgroundImage = init.backgroundImage;
    this._fontSize = init.fontSize;
    this._fontFamily = init.fontFamily;
    this._textAlign = init.textAlign;
    this._border = init.border;
    this._borderColor = init.borderColor;
    this._borderRadius = init.borderRadius;
    this._borderTop = init.borderTop;
    this._borderRight = init.borderRight;
    this._borderBottom = init.borderBottom;
    this._borderLeft = init.borderLeft;
    this._marginTop = init.marginTop;
    this._marginRight = init.marginRight;
    this._marginBottom = init.marginBottom;
    this._marginLeft = init.marginLeft;
    this._paddingTop = init.paddingTop;
    this._paddingRight = init.paddingRight;
    this._paddingBottom = init.paddingBottom;
    this._paddingLeft = init.paddingLeft;
    this._width = init.width;
    this._height = init.height;
    this._overflow = init.overflow;
    this._fontWeight = init.fontWeight;
    this._lineHeight = init.lineHeight;
    this.styleTemplateName = init.styleTemplateName;
    this.defaultStyle = {
      ...this.defaultStyle,
      ...defaultStyle,
    };
  }

  get cssField(): Partial<StyleField> {
    return omitBy({
      color: this.color,
      backgroundColor: this.backgroundColor,
      backgroundImage: this.backgroundImage,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      textAlign: this.textAlign,
      border: this.border,
      borderColor: this.borderColor,
      borderRadius: this.borderRadius,
      borderTop: this.borderTop,
      borderRight: this.borderRight,
      borderBottom: this.borderBottom,
      borderLeft: this.borderLeft,
      marginTop: this.marginTop,
      marginRight: this.marginRight,
      marginBottom: this.marginBottom,
      marginLeft: this.marginLeft,
      paddingTop: this.paddingTop,
      paddingRight: this.paddingRight,
      paddingBottom: this.paddingBottom,
      paddingLeft: this.paddingLeft,
      width: this.width,
      height: this.height,
      overflow: this.overflow,
      fontWeight: this.fontWeight,
      lineHeight: this.lineHeight,
    }, isUndefined);
  }
}
