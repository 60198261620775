import { Expose, Transform, Type } from 'class-transformer';
import range from 'lodash/range';
import repeat from 'lodash/repeat';

import { ItemTypeKbn, UiKbn } from '@/utils/crm_kbn';
import { registerGenerator } from '@/utils/generate';

import { CrmKbnConst } from './../utils/crm_kbn';
import { Model } from './model';
import { SurveyOptionItem } from './survey_option_item';

export class SurveyPatternItem extends Model<SurveyPatternItem> {
  @Transform((params) => params.value || '')
  uuid: string = '';

  @Transform((params) => params.value || '')
  historyUuid: string = '';

  typeKbn?: ItemTypeKbn;

  @Expose()
  @Transform(({ value, obj }) => {
    if (value) {
      return value;
    }
    switch (obj.typeKbn) {
    case CrmKbnConst.ITEM_TYPE_KBN_LIST:
      return CrmKbnConst.UI_KBN_CHECKBOX;
    case CrmKbnConst.ITEM_TYPE_KBN_SINGLE_LINE_TEXT:
      return CrmKbnConst.UI_KBN_SINGLE_LINE_TEXT;
    case CrmKbnConst.ITEM_TYPE_KBN_MULTI_LINES_TEXT:
      return CrmKbnConst.UI_KBN_MULTI_LINES_TEXT;
    case CrmKbnConst.ITEM_TYPE_KBN_BIRTHDAY:
      return CrmKbnConst.UI_KBN_BIRTHDAY;
    case CrmKbnConst.ITEM_TYPE_KBN_SINGLE_LIST:
      return CrmKbnConst.UI_KBN_RADIO_BUTTON;
    }
  })
  uiKbn?: UiKbn;

  required?: boolean;

  questionContent?: string;

  @Type(() => SurveyPatternItemContentJson)
  @Transform((params) => params.value || new SurveyPatternItemContentJson())
  contentJson = new SurveyPatternItemContentJson();

  constructor(init?: Partial<SurveyPatternItem>) {
    super();
    Object.assign(this, init);
  }
}

export class SurveyPatternItemContentJson {
  @Type(() => SurveyPatternItemValidations)
  @Transform((params) => params.value || new SurveyPatternItemValidations())
  validations = new SurveyPatternItemValidations();

  @Type(() => SurveyPatternItem)
  @Transform((params) => params.value || [])
  options: SurveyOptionItem[] = [];

  constructor(init?: Partial<SurveyPatternItemContentJson>) {
    Object.assign(this, init);
  }
}

export class SurveyPatternItemValidations {
  maxSelectableCount?: number; // 最大選択数制限。リスト系入力で利用

  maxLength?: number; // 最大文字数制限。テキスト系入力で利用

  minDate?: string; // 回答できる最小の日付。日付系入力で利用

  maxDate?: string; // 回答できる最大の日付。日付系入力で利用

  canSelectPast?: boolean; // 過去の日時を回答できるかどうか。日付系入力で利用

  canSelectToday?: boolean; // 回答日時当日を選択できるかどうか。日付系入力で利用

  canSelectFuture?: boolean; // 未来の日時を回答できるかどうか。日付系入力で利用

  constructor(init?: Partial<SurveyPatternItemValidations>) {
    Object.assign(this, init);
  }
}

registerGenerator(
  SurveyPatternItem,
  (props) =>
    new SurveyPatternItem({
      uuid: 'pattern_item1',
      historyUuid: 'pattern_item1',
      typeKbn: CrmKbnConst.ITEM_TYPE_KBN_SINGLE_LINE_TEXT,
      uiKbn: CrmKbnConst.UI_KBN_SINGLE_LINE_TEXT,
      questionContent: repeat('質問名', props.size),
      required: true,
      contentJson: new SurveyPatternItemContentJson({
        validations: new SurveyPatternItemValidations({
          maxLength: 20,
          maxSelectableCount: 3,
        }),
        options: range(0, props.size).map(
          (i) => new SurveyOptionItem(`選択肢${i}`, `select_uuid${i}`),
        ),
      }),
    }),
);
