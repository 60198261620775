import { toRefs, useLocalStorage } from '@vueuse/core';
import axios from 'axios';
import { plainToClass } from 'class-transformer';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import padStart from 'lodash/padStart';
import { ref } from 'vue';

import { useApi } from '@/hooks/use_api';
import { useLoader } from '@/hooks/use_loader';
import { SurveyAnswer } from '@/models/survey_answer';
import { SurveyAnswerItem } from '@/models/survey_answer_item';
import { SurveyAnswerItemContentMultiSelect } from '@/models/survey_answer_item_content_multi_select.';
import { SurveyAnswerItemContentText } from '@/models/survey_answer_item_content_text';
import { SurveyPattern } from '@/models/survey_pattern';
import { SurveyPatternItem } from '@/models/survey_pattern_item';
import { Birthday, isBirthday } from '@/types/Birthday';
import { CrmKbnConst } from '@/utils/crm_kbn';
import { KbnConst } from '@/utils/kbn';
import { LS_KEY_SURVEY } from '@/utils/ls_const';
import { surveyValidation } from '@/utils/survey_validation';

import { SurveyAnswerItemContentSelect } from './../models/survey_answer_item_content_select';

export type SurveyAnswersType = Record<string, unknown>; // key: 質問のUUID
export type SurveyErrorsType = Record<string, string>; // key: 質問のUUID

// ローカルストレージで保持する情報の型
interface SurveyLocalStorageState {
  userPublishedAt: string | undefined;
  answers: SurveyAnswersType;
}

// グローバルで共用するデータ
const state = useLocalStorage<SurveyLocalStorageState>(LS_KEY_SURVEY, {
  userPublishedAt: undefined,
  answers: {},
}, {
  listenToStorageChanges: false, // マルチタブ対策
});
const errors = ref<SurveyErrorsType>({});
const survey = ref<SurveyPattern>(); // validationのために保持する

export function useSurvey() {
  const api = useApi();
  const loader = useLoader();
  const { answers } = toRefs(state);

  // 入力チェック
  function invalid() {
    errors.value = surveyValidation(survey.value, answers.value);
    return !isEmpty(errors.value);
  }

  // 回答情報をクリアする
  function clear() {
    answers.value = {};
    errors.value = {};
  }

  // アンケート取得
  async function getSurvey() {
    try {
      loader.start();
      survey.value = undefined;
      const response = await api.getUsers();
      const resSurvey = plainToClass(SurveyPattern, response.dataJson.survey);

      // アンケート更新を検知して回答内容をクリアする
      if (state.value.userPublishedAt !== resSurvey.userPublishedAt) {
        state.value.userPublishedAt = resSurvey.userPublishedAt;
        clear();
      }
      survey.value = resSurvey;

    } catch (err) {
      if (axios.isAxiosError(err) && !err.response) {
        // アンケート情報を通信キャンセルのエラーで取得できなかったときに、グローバルのエラーハンドラーで拾われないようにエラーを変換
        throw new Error('failed to get survey');
      }
      throw err;

    } finally {
      loader.stop();
    }
  }

  // テスト用。APIを叩かずに、指定したアンケートを出すため。
  function setSurvey(surveyPattern: SurveyPattern) {
    survey.value = surveyPattern;
  }

  function getPostData() {
    if (!survey.value) return undefined;
    return generateSurveyPostData(survey.value, answers.value);
  }

  function isSurveyContent(kbn?: string) {
    return kbn === KbnConst.CONTENT_TYPE_KBN_SURVEY_INPUT ||
      kbn === KbnConst.CONTENT_TYPE_KBN_SURVEY_CONFIRM;
  }

  return {
    answers,
    errors,
    survey,
    invalid,
    clear,
    getPostData,
    getSurvey,
    setSurvey,
    isSurveyContent,
  };
}

function generateSurveyPostData(survey: SurveyPattern, answers: SurveyAnswersType): SurveyAnswer {
  return new SurveyAnswer({
    surveyUuid: survey.surveyUuid,
    patternUuid: survey.patternUuid,
    patternName: survey.patternName,
    items: survey.items.map((item) => (new SurveyAnswerItem({
      uuid: item.uuid,
      historyUuid: item.historyUuid,
      typeKbn: item.typeKbn,
      questionContent: item.questionContent,
      answer: getAnswer(item, answers),
    }))),
  });
}

function getAnswer(item: SurveyPatternItem, answers: SurveyAnswersType): SurveyAnswerItemContentText | SurveyAnswerItemContentSelect | SurveyAnswerItemContentMultiSelect {
  const value = answers[`${item.uuid}`];

  switch (item.uiKbn) {
  case CrmKbnConst.UI_KBN_BIRTHDAY:
    if (!value) {
      return new SurveyAnswerItemContentText({ value: null });
    }
    if (isBirthday(value)) {
      return new SurveyAnswerItemContentText({ value: birthdayToString(value) });
    } else {
      throw new Error(`answer is not birthday ${value}`);
    }
  case CrmKbnConst.UI_KBN_MULTI_LINES_TEXT:
  case CrmKbnConst.UI_KBN_SINGLE_LINE_TEXT:
    if (!value) {
      return new SurveyAnswerItemContentText({ value: null });
    }
    if (isString(value)) {
      return new SurveyAnswerItemContentText({ value: value });
    } else {
      throw new Error(`answer is not string ${value}`);
    }
  case CrmKbnConst.UI_KBN_LIST:
  case CrmKbnConst.UI_KBN_CHECKBOX:
    if (!value) {
      return new SurveyAnswerItemContentMultiSelect({
        values: [],
        uuids: [],
      });
    }
    if (isArray(value)) {
      const optionsByValue = keyBy(item.contentJson.options, 'value');
      const uuids = value.map(v => optionsByValue[v]?.uuid || null);
      return new SurveyAnswerItemContentMultiSelect({
        values: value,
        uuids: uuids,
      });
    } else {
      throw new Error(`answer is not array ${value}`);
    }
  case CrmKbnConst.UI_KBN_SINGLE_LIST:
  case CrmKbnConst.UI_KBN_RADIO_BUTTON:
    if (!value) {
      return new SurveyAnswerItemContentSelect({
        value: null,
        uuid: null,
      });
    }
    if (isString(value)) {
      const answerObject = item.contentJson.options.find((option) => option.value === value);
      return new SurveyAnswerItemContentSelect({
        value: value,
        uuid: answerObject?.uuid || null,
      });
    } else {
      throw new Error(`answer is not string ${value}`);
    }
  default:

    // case 文で漏れが有った場合は neverCheck が 型エラーになる。
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const neverCheck: never | undefined = item.uiKbn;

    throw new Error(`answer unsupported type ${item.uiKbn}`);
  }
}

function birthdayToString(value: Birthday | undefined) {
  if (!value) return undefined;
  const month = padStart(`${value.month}`, 2, '0');
  const day = padStart(`${value.day}`, 2, '0');
  return `${value.year}-${month}-${day}`;
}
