import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_LinePayDialogAgree = _resolveComponent("LinePayDialogAgree")!
  const _component_LinePayDialogResult = _resolveComponent("LinePayDialogResult")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type === 'alert' || _ctx.type === 'confirm')
      ? (_openBlock(), _createBlock(_component_BaseDialog, {
          key: 0,
          open: _ctx.open
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MessageCard, {
              type: _ctx.type,
              ok: _ctx.ok,
              cancel: _ctx.cancel,
              message: _ctx.message
            }, null, 8, ["type", "ok", "cancel", "message"])
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'linePayAgree')
      ? (_openBlock(), _createBlock(_component_BaseDialog, {
          key: 1,
          open: _ctx.open,
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LinePayDialogAgree, {
              ok: _ctx.ok,
              cancel: _ctx.cancel,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, null, 8, ["ok", "cancel"])
          ]),
          _: 1
        }, 8, ["open", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'linePayResult')
      ? (_openBlock(), _createBlock(_component_BaseDialog, {
          key: 2,
          open: _ctx.open
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LinePayDialogResult, {
              ok: _ctx.ok,
              cancel: _ctx.cancel
            }, null, 8, ["ok", "cancel"])
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true)
  ], 64))
}