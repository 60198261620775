import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseStyle = _resolveComponent("BaseStyle")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    "content-style": _ctx.contentStyle,
    class: "flex justify-center"
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: _normalizeClass([
        'flex object-contain',
      ]),
        src: _ctx.url
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["content-style"]))
}