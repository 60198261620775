export type Birthday = {
  year?: number,
  month?: number,
  day?: number,
};

export function isBirthday(value: unknown): value is Birthday {
  return typeof value === 'object'
  && value !== null
  && 'year' in value
  && 'month' in value
  && 'day' in value
  ;
}

export function toDateString(birthday: Birthday) {
  function unionDate(date: number | undefined, unit: string) {
    return date ? date + unit : '';
  }

  const { year, month, day } = birthday;

  return unionDate(year, '年') + unionDate(month, '月') + unionDate(day, '日');
}
