import dayjs from 'dayjs';

import { Campaign } from '@/models/campaign';
import { ResponseCandidate } from '@/models/response_candidate';
import { RumConfig } from '@/models/rum_config';

const staging = new Campaign({
  campaignUuid: '4873cf62-6bc7-4ed6-9fbc-24969cac7ea0', // TODO: 設定する
  campaignStartUts: dayjs.tz('2000-01-01 00:00:00', 'Asia/Tokyo'),
  campaignEndUts: dayjs.tz('2100-01-01 00:00:00', 'Asia/Tokyo'),
  liffId: '', // TODO: 設定する
  clientBasicId: '', // TODO: 設定する
  isOnlyOpenid: false, // TODO: 設定する
  googleTagManagerIds: [],
  escapeUrl: 'https://google.com', // TODO: 設定する
  responseCandidates: [

    // TODO: 設定する
    new ResponseCandidate({
      name: '返信１',
      uuid: 'test_response_candidate_1',
      pageName: 'page1',
    }),
    new ResponseCandidate({
      name: '該当なし',
      uuid: '',
      pageName: 'error',
    }),
  ],
  liffUrl: 'https://liff.line.me/1653948501-3N6RzZ6B',

  // TODO: liff.sendMessage を利用する場合には記述する
  // liffSendMessages: [
  //   {
  //     type: 'text',
  //     text: 'receiptUuid: <%= insertDataStore.receiptUuid.value %>',
  //   },
  // ],
});

const prod = new Campaign({
  campaignUuid: 'b14299a4-027e-42ed-8b61-ec95a6443952', // TODO: 設定する
  campaignStartUts: dayjs.tz('2024-05-09 12:00:00', 'Asia/Tokyo'), // TODO: 設定する
  campaignEndUts: dayjs.tz('2024-07-09 00:00:00', 'Asia/Tokyo'), // TODO: 設定する
  liffId: '', // TODO: 設定する
  clientBasicId: '', // TODO: 設定する
  isOnlyOpenid: false, // TODO: 設定する
  googleTagManagerIds: ['GTM-W3V5H9VC', 'GTM-PPLQVSH'],  // TODO: 設定する
  escapeUrl: 'https://google.com', // TODO: 設定する
  responseCandidates: [

    // TODO: 設定する
    new ResponseCandidate({
      name: '返信１',
      uuid: 'test_response_candidate_1',
      pageName: 'page1',
    }),
    new ResponseCandidate({
      name: '該当なし',
      uuid: '',
      pageName: 'error',
    }),
  ],
  liffUrl: 'https://liff.line.me/1660864981-bv7qmWJY',

  // TODO: RUM を新規作成し「設定 > JavaScript スニペット」を参照して、必要な値を以下に貼り付ける
  // 本番環境では必ず設定する
  rumConfig: new RumConfig({
    applicationId: 'a1b356ee-f968-4012-99b2-d0acb607b7dc',
    identityPoolId: 'ap-northeast-1:80e4d553-a55f-449d-92c0-4d4b6ee483f3',
  }),
});

export const campaignSettings = (() => {
  switch (process.env.VUE_APP_ENV) {
  case 'staging': return staging;
  case 'prod': return prod;
  default: return staging;
  }
})();
