import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseStyle = _resolveComponent("BaseStyle")!
  const _component_Content = _resolveComponent("Content")!

  return (_openBlock(), _createBlock(_component_BaseStyle, {
    "content-style": _ctx.model.style,
    class: "content-base"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseStyle, {
        "content-style": _ctx.model.checkboxStyle,
        class: "content-base"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseCheckbox, {
            modelValue: _ctx.check,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.check) = $event)),
            "checkbox-size": 26,
            position: 'center'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.text), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["content-style"]),
      (_ctx.check)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.checkedContents, (content, contentIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                _createVNode(_component_Content, { content: content }, null, 8, ["content"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.unCheckedContents, (content, contentIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: contentIndex }, [
                _createVNode(_component_Content, { content: content }, null, 8, ["content"])
              ]))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["content-style"]))
}