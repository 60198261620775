import { computed } from 'vue';
import { RouteLocationRaw, Router, useRouter } from 'vue-router';

import RouterConst from '@/router/router_const';

import { useInjectLandingPage } from './use_landing_page';

export function useRouting(router = useRouter()) {
  const landingPage = useInjectLandingPage();

  // リダイレクト
  async function redirect(name: string) {
    await routerRedirect(router, name);
  }

  // リダイレクト
  async function redirectByPageKey(pageKey?: string) {
    const page = landingPage.pages.find(item => item.uniqueKey === pageKey);
    await routerRedirect(router, page?.path || RouterConst.Error);
  }

  // 必ず replace でリダイレクト
  async function redirectByReplace(name: string) {
    await routerRedirect(router, name);
  }

  // リダイレクト（トップへ）
  async function redirectTop() {
    await redirectByPageKey(landingPage.rootPageKey);
  }

  // 外部アクセスかどうか
  const isExternal = computed(() => !router.currentRoute.value.params.internal);

  return {
    redirect,
    redirectByPageKey,
    redirectByReplace,
    redirectTop,
    isExternal,
  };
}

export async function routerRedirect(router: Router | undefined, name: string) {
  if (!router) {
    // eslint-disable-next-line no-console
    console.error('router が指定されていません。');
    return;
  }

  if (router.currentRoute.value.name !== name) {
    const to: RouteLocationRaw = {
      name: name,
      params: { internal: 'true' }, // internalフラグで内部の遷移か判定する
    };

    // LIFFアプリの場合戻るボタンを使わせないためにreplaceを使用
    await router.replace(to);
  }
}
