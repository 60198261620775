import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

import { useState } from './use_state';

export class ActionStateClear extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_STATE_CLEAR;

  stateKey?: string;

  use() {
    const stateData = useState();

    return async () => await execute(this.stateKey, stateData);
  }

  constructor(init: Partial<ActionStateClear> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  stateKey: string | undefined,
  stateData: ReturnType<typeof useState>,
) {
  // キー設定すると、キーだけ消します。じゃなければ、全部リセットします。
  if (stateKey) {
    stateData.value[stateKey] = '';
  } else {
    stateData.value = {};
  }
  return false;
}

export function generateActionStateClear() {
  return new ActionStateClear({
    stateKey: 'state',
  });
}
