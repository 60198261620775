import { useLocalStorage } from '@vueuse/core';

export type StateData = {
  [x: string]: string
};

const  state = useLocalStorage<StateData>('campaign_state', {}, {
  listenToStorageChanges: true, /// マルチタブ対策
});

/**
 * State データ管理
 */
export function useState() {
  return state;
}
