
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue';

import { useCurrentMedia } from '@/hooks/use_media';
import { useInjectStyleTemplate } from '@/hooks/use_style_template';
import { ContentBase } from '@/models/content_base';
import { Style, StyleField } from '@/models/style';

export default defineComponent({
  name: 'BaseStyle',
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentBase>,
      default: undefined,
    },

    contentStyle: {
      type: Object as PropType<Style>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { getAssetsUrl } = useCurrentMedia();

    const contentStyle = computed(() => {
      if (props.contentStyle) {
        return props.contentStyle;
      }
      if (props.model && props.model.style) {
        return props.model.style;
      }
      return null;
    });

    const convertAssetsUrl = async (cssField: Partial<StyleField>): Promise<Partial<Omit<StyleField, 'backgroundImage'> & { backgroundImage: string | undefined }>> => {
      return {
        ...cssField,
        backgroundImage: cssField.backgroundImage ? `url(${ await getAssetsUrl('images', cssField.backgroundImage) })` : undefined,
      };
    };
    const cssField = ref<Partial<Partial<Omit<StyleField, 'backgroundImage'> & { backgroundImage: string | undefined }>>>({});

    const styleTemplates =  useInjectStyleTemplate();

    const applyStyleTemplate = () => {
      const found = styleTemplates.value.find(item => item.uniqueKey === contentStyle.value?.styleTemplateName);
      if (contentStyle.value) {
        contentStyle.value.styleTemplate = found?.style?.cssField;
      }
    };

    watchEffect(async () => {
      applyStyleTemplate();
      cssField.value = await convertAssetsUrl(contentStyle.value?.cssField || {});
    });

    return {
      cssField,
    };
  },
});
