
import {
  DialogDescription,
  DialogTitle,
} from '@headlessui/vue';
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';

import { OptionItem } from '@/models/option_item';
import { selectedText } from '@/utils/selected_text';
import { sortOptionsValue } from '@/utils/sort_options_value';

import BaseArrayCheckbox from './BaseArrayCheckbox.vue';
import BaseDialog from './BaseDialog.vue';

export default defineComponent({
  name: 'SurveyInputMultiSelect',
  components: {
    BaseDialog,
    DialogDescription,
    DialogTitle,
    BaseArrayCheckbox,
  },
  props: {

    /**
     *  選択されている値のリスト
     */
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    /**
     *  選択肢のリスト
     */
    options: {
      type: Array as PropType<OptionItem[]>,
      default: () => [],
    },

    /**
     *  選択できる最大の個数。
     *
     *  0 を入れた場合は無制限になる。
     */
    max: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const { modelValue, max, options } = toRefs(props);

    const selected = computed({
      get: () => modelValue.value,
      set: (value: string[]) => emit('update:modelValue', sortOptionsValue(value, options.value)),
    });

    const isOpen = ref(false);

    function isDisabled(option: string) {
      return selected.value.length >= max.value
        && max.value !== 0
        && !selected.value.includes(option)
      ;
    }

    const selectedLabels = computed(() => {
      const labels = selected.value.map((s) => options.value.find((o) => o.value === s)?.label || '');
      return selectedText(labels);
    });

    return {
      selected,
      isDisabled,
      isOpen,
      selectedLabels,
    };
  },
});
