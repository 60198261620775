import liff from '@line/liff';

export function openExternalUrl(url?: string, newTab = false) {
  if (!url) {
    return;
  }
  if (newTab) {
    return window.open(url, '_blank');
  }
  if (liff.isInClient()) {
    liff.openWindow({ url: url,
      external: true });
  } else {
    window.location.href = url;
  }
}
