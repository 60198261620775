import { Transform } from 'class-transformer';
import repeat from 'lodash/repeat';

import { HandlebarsRender } from '@/decorators/handlebars_render';
import TypeContent from '@/decorators/type_content';
import TypeStyle from '@/decorators/type_style';
import { registerGenerator } from '@/utils/generate';
import { KbnConst } from '@/utils/kbn';

import { ContentBase } from './content_base';
import { ContentLinkModel } from './content_link_model';
import { withClone } from './model';
import { Style, StyleField } from './style';

const textDefaultStyle: Partial<StyleField> = {
  marginTop: '0',
};

export class ContentFooterModel extends ContentBase {
  @HandlebarsRender()
  text?: string;

  @TypeContent()
  @Transform((params) => params.value || [])
  links: ContentBase[] = [];

  @TypeStyle({ defaultStyle: textDefaultStyle })
  textStyle?: Style;

  constructor(init?: Partial<ContentFooterModel>) {
    super();
    this.typeKbn = KbnConst.CONTENT_TYPE_KBN_FOOTER; // クラスに対して区分値固定なので、コンストラクタで代入
    Object.assign(this, init);
  }

  static generate(patch: Partial<ContentFooterModel> = {}) {
    const instance = new ContentFooterModel({
      style: new Style({
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        marginRight: '0',
      }),
      text: 'All rights reserved.',
      textStyle: new Style({}, textDefaultStyle),
      links: [
        ContentLinkModel.generate({
          text: 'プライバシーポリシー',
        }),
        ContentLinkModel.generate({
          text: 'よくある質問',
        }),
      ],
    });

    return withClone(instance, patch);
  }
}

registerGenerator(
  ContentFooterModel,
  (props) => ContentFooterModel.generate({
    text: repeat('UV All rights reserved.', props.size),
    style: props.style,
  }),
);
