import { setupTypeContent } from '@/decorators/type_content';
import { ContentAccordinationModel } from '@/models/content_accordination_model';
import { ContentButtonModel } from '@/models/content_button_model';
import { ContentCheckboxModel } from '@/models/content_checkbox_model';
import { ContentCustomizingModel } from '@/models/content_customizing_model';
import { ContentFooterModel } from '@/models/content_footer_model';
import { ContentGroupModel } from '@/models/content_group_model';
import { ContentHorizontalLayoutModel } from '@/models/content_horizontal_layout_model';
import { ContentImageModel } from '@/models/content_image_model';
import { ContentItemizedListModel } from '@/models/content_itemized_list_model';
import { ContentLinkModel } from '@/models/content_link_model';
import { ContentQrCodeReadingModel } from '@/models/content_qr_code_reading_model';
import { ContentQrCodeShowModel } from '@/models/content_qr_code_show_model';
import { ContentReceiptPreviewModel } from '@/models/content_receipt_preview';
import { ContentSurveyConfirmModel } from '@/models/content_survey_confirm_model';
import { ContentSurveyInputModel } from '@/models/content_survey_input_model';
import { ContentTextEditorModel } from '@/models/content_text_editor_model';
import { ContentTrackingIdModel } from '@/models/content_tracking_id_model';
import { ContentVideoModel } from '@/models/content_video_model';
import { Equal, Expect } from '@/types/util';
import { ContentTypeKbn, KbnConst } from '@/utils/kbn';

export function setupContents() {
  const contents = [
    {
      name: KbnConst.CONTENT_TYPE_KBN_TEXT_EDITOR,
      value: ContentTextEditorModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_LINK,
      value: ContentLinkModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_BUTTON,
      value: ContentButtonModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_IMAGE,
      value: ContentImageModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_VIDEO,
      value: ContentVideoModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_ITEMIZED_LIST,
      value: ContentItemizedListModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_CHECKBOX,
      value: ContentCheckboxModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_ACCORDION,
      value: ContentAccordinationModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_TRACKING_ID,
      value: ContentTrackingIdModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_QR_CODE_SHOW,
      value: ContentQrCodeShowModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_QR_CODE_READING,
      value: ContentQrCodeReadingModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_FOOTER,
      value: ContentFooterModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_HORIZONTAL_LAYOUT,
      value: ContentHorizontalLayoutModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_GROUP,
      value: ContentGroupModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_SURVEY_INPUT,
      value: ContentSurveyInputModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_SURVEY_CONFIRM,
      value: ContentSurveyConfirmModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_RECEIPT_PREVIEW,
      value: ContentReceiptPreviewModel,
    },
    {
      name: KbnConst.CONTENT_TYPE_KBN_CUSTOMIZING,
      value: ContentCustomizingModel,
    },
  ];

  // コンテンツの列挙漏れがあったときに型エラーにする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type Check = Expect<Equal<ContentTypeKbn, (typeof contents)[number]['name']>>;

  setupTypeContent(contents);
}
