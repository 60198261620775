
import { defineAsyncComponent, defineComponent, PropType } from 'vue';

import { useContentGroup } from '@/hooks/use_content_groups';
import { ContentGroupModel } from '@/models/content_group_model';

import BaseStyle from './BaseStyle.vue';

export default defineComponent({
  name: 'ContentGroup',
  components: {
    BaseStyle,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Content: defineAsyncComponent((): any => import('./Content.vue')),
  },
  props: {

    /**
     * Model
     */
    model: {
      type: Object as PropType<ContentGroupModel>,
      required: true,
    },
  },
  setup: (props) => {
    const group = useContentGroup(props.model.groupKey);

    return {
      group,
    };
  },
});
