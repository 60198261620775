import dayjs, { Dayjs } from 'dayjs';

import { Kbn, KbnConst } from './kbn';

/**
 * 表示期間内かどうか
 */
export function isWithinDisplayPeriod(displayStartAt: Dayjs | null, displayEndAt: Dayjs | null, now = dayjs()) {

  const isStartOk = !displayStartAt // null や undefined のときは OK
        || now.isSame(displayStartAt) || now.isAfter(displayStartAt) ; // 始端を含む
  const isEndOk = !displayEndAt // null や undefined のときは OK
        || now.isBefore(displayEndAt); // 終端は含まない

  return isStartOk && isEndOk;
}

export function getDisplayDateTimeByKbn(kbn: Kbn | string, defaultAt?: Dayjs | null) {
  switch (kbn) {
  case KbnConst.CONTENT_AT_KBN_UNSET:
    return null;
  }

  return defaultAt;
}
