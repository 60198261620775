
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LinePayGiftContent',
  props: {
    giftOpen: {
      type: Boolean,
      default: true,
    },

    amount: {
      type: Number,
      default: 0,
    },
  },
});
